class Assessment {
    constructor(
        public content: string,
        public comments: string[],
        public overallComment: string | null,
        public score: number | string | null
    ) {
    }
}

export {Assessment};
