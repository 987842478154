import {API, Storage} from "aws-amplify";
import {useParams, useHistory} from "react-router-dom";
import React, {useEffect, useState, useRef} from "react"
import config from "../../config";
import "./view_reading_task.scss";
import {Remarkable} from "remarkable";
import {parseQuestionsInText} from "../../services/question_service";
import {AssignmentStudentSelector} from "../../components/assign_student_selector";
import {markdownToHtml} from "../../services/utils";
import LoadingBar from "react-top-loading-bar";
import RichMarkdownEditor from "rich-markdown-editor";
import {FollowingTasks} from "../../components/tasks/following_tasks/following_tasks";

export const ViewReadingTestPage = () => {
    const loadingBarRef = useRef(null);
    const {taskType, taskId} = useParams();
    const [task, setTask] = useState(null);
    const [title, setTitle] = useState('');
    const [s3FileName, setS3FileName] = useState(null);
    const [content1, setContent1] = useState('');
    const [rawContent1, setRawContent1] = useState('');
    const [questions1, setQuestions1] = useState('');
    const [answers1, setAnswers1] = useState('');
    const [content2, setContent2] = useState('');
    const [rawContent2, setRawContent2] = useState('');
    const [questions2, setQuestions2] = useState('');
    const [answers2, setAnswers2] = useState('');
    const [content3, setContent3] = useState('');
    const [rawContent3, setRawContent3] = useState('');
    const [questions3, setQuestions3] = useState('');
    const [answers3, setAnswers3] = useState('');
    const [content4, setContent4] = useState('');
    const [rawContent4, setRawContent4] = useState('');
    const [questions4, setQuestions4] = useState('');
    const [answers4, setAnswers4] = useState('');
    const [currentPassage, setCurrentPassage] = useState(1);
    const [assignedStudents, setAssignedStudents] = useState(null);
    const markdown = new Remarkable('default', {html: true});
    const history = useHistory();
    const [numberOfPassages, setNumberOfPassages] = useState(1);
    const [students, setStudents] = useState([]);
    const [followingTaskIds, setfollowingTaskIds] = useState('')
    const [parentTaskId, setParentTaskId] = useState('')

    const convertMarkdownToHtml = text => {
        text = text.replace(/\\/g, ''); // rich-markdown-editor leaves a \ for some new empty lines
        return markdown.render(text);
    };

    const goBack = (e) => {
        e.preventDefault();

        if (currentPassage > 1) {
            setCurrentPassage(currentPassage - 1);
        }
    };

    const goForward = (e) => {
        e.preventDefault();

        if (currentPassage < numberOfPassages) {
            setCurrentPassage(currentPassage + 1);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            loadingBarRef.current.continuousStart();
            const serverResponse = await API.get("app", `/tasks/${taskId}`, {});
            const s3Path = serverResponse.content;

            setfollowingTaskIds(serverResponse.following_task_ids ? serverResponse.following_task_ids : '')
            setParentTaskId(serverResponse.parent_task_id ? serverResponse.parent_task_id : '')

            const s3Response = await Storage.get(`${config.s3.READING_TEST_PATH}/${s3Path}`, {
                level: 'public',
                download: true,
                cacheControl: 'no-cache',
                contentType: 'application/json'
            });
            const rawTaskContent = await s3Response.Body.text();
            const taskContent = JSON.parse(rawTaskContent);

            setTitle(serverResponse.title);
            setS3FileName(serverResponse.content);


            setRawContent1(taskContent.passage1.content.replace('\\\n', ''));
            setContent1(markdownToHtml(taskContent.passage1.content));
            setQuestions1(parseQuestionsInText(convertMarkdownToHtml(taskContent.passage1.questions)));
            setAnswers1(taskContent.passage1.answers);

            setRawContent2(taskContent.passage2.content.replace('\\\n', ''));
            setContent2(markdownToHtml(taskContent.passage2.content));
            setQuestions2(parseQuestionsInText(convertMarkdownToHtml(taskContent.passage2.questions)));
            setAnswers2(taskContent.passage2.answers);

            setRawContent3(taskContent.passage3.content.replace('\\\n', ''));
            setContent3(markdownToHtml(taskContent.passage3.content));
            setQuestions3(parseQuestionsInText(convertMarkdownToHtml(taskContent.passage3.questions)));
            setAnswers3(taskContent.passage3.answers);

            if (taskContent.passage4) {
                setRawContent4(taskContent.passage4.content.replace('\\\n', ''));
                setContent4(markdownToHtml(taskContent.passage4.content));
                setQuestions4(parseQuestionsInText(convertMarkdownToHtml(taskContent.passage4.questions)));
                setAnswers4(taskContent.passage4.answers);
            }

            setAssignedStudents(serverResponse.students);

            setTask(serverResponse);
            setNumberOfPassages(
                (taskContent.passage1.content || taskContent.passage1.questions ? 1 : 0)
                + (taskContent.passage2.content || taskContent.passage2.questions ? 1 : 0)
                + (taskContent.passage3.content || taskContent.passage3.questions ? 1 : 0)
                + (taskContent.passage4.content || taskContent.passage4.questions ? 1 : 0)
            )

            await API.get("app", "/students", {}).then(studentResults => {
                setStudents(studentResults.filter(item => serverResponse.students.indexOf(item.email) === -1));
            });
            loadingBarRef.current.complete();
        };

        fetchData();
    }, []);

    const removeStudentFromStudentList = (email) => {
        const index = students.findIndex(student => student.email === email)

        if (index !== -1) {
            let newStudents = [...students]
            newStudents.splice(index, 1)
            setStudents(newStudents)
        }
    };

    const edit = e => {
        history.push(`/tasks/${taskType}/${taskId}/edit`);
    };

    return (
        <div className={"container-fluid full-height reader-test-wrapper"}>
            <LoadingBar color={"#0d6efd"} ref={loadingBarRef}/>
            <form autoComplete={"off"} className={"full-height"}>
                {currentPassage === 1 && (
                    <div id={`passage-1`} className={"row full-height"}>
                        <div className={`${rawContent1 ? 'col-md-6' : 'd-none'} side full-height`} dangerouslySetInnerHTML={{__html: content1}}/>
                        <div className={`${rawContent1 ? 'col-md-6 side full-height' : 'col-md-12 side full-height'}`} dangerouslySetInnerHTML={{__html: questions1}}/>
                    </div>
                )}

                {currentPassage === 2 && (
                    <div id={`passage-2`} className={"row full-height"}>
                        <div className={`${rawContent2 ? 'col-md-6' : 'd-none'} side full-height`} dangerouslySetInnerHTML={{__html: content2}}/>
                        <div className={`${rawContent2 ? 'col-md-6 side full-height' : 'col-md-12 side full-height12'}`} dangerouslySetInnerHTML={{__html: questions2}}/>
                    </div>
                )}

                {currentPassage === 3 && (
                    <div id={`passage-3`} className={"row full-height"}>
                        <div className={`${rawContent3 ? 'col-md-6' : 'd-none'} side full-height`} dangerouslySetInnerHTML={{__html: content3}}/>
                        <div className={`${rawContent3 ? 'col-md-6 side full-height' : 'col-md-12 side full-height'}`} dangerouslySetInnerHTML={{__html: questions3}}/>
                    </div>
                )}

                {currentPassage === 4 && (
                    <div id={`passage-4`} className={"row full-height"}>
                        <div className={`${rawContent4 ? 'col-md-6' : 'd-none'} side full-height`} dangerouslySetInnerHTML={{__html: content4}}/>
                        <div className={`${rawContent4 ? 'col-md-6 side full-height' : 'col-md-12 side full-height'}`} dangerouslySetInnerHTML={{__html: questions4}}/>
                    </div>
                )}

                {followingTaskIds && (
                    <div className="modal fade" id="viewFollowingTasks" tabIndex="-1" aria-labelledby="viewFollowingTasks" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Following tasks</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal"
                                            aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <FollowingTasks taskIds={followingTaskIds.split(',')}></FollowingTasks>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </form>

            <div className={"fixed-bottom bottom-nav"}>
                <button className={`btn btn-secondary ${currentPassage === 1 ? 'd-none' : ''}`} onClick={goBack}>Previous</button>
                <button className={`btn btn-secondary ${currentPassage < numberOfPassages ? '' : 'd-none'}`} onClick={goForward}>Next</button>
                <button className={"btn btn-primary"} onClick={edit}>Edit</button>
                {followingTaskIds && (
                    <button className={"btn btn-warning"} data-bs-toggle="modal" data-bs-target="#viewFollowingTasks">View following tasks</button>
                )}
                {parentTaskId && (
                    <a className={"btn btn-warning"} href={`/redirect-task/${parentTaskId}`} target={"_blank"}>View parent task</a>
                )}
                <div className={"col-sm-8 float-end"}>
                    {assignedStudents !== null && (
                        <AssignmentStudentSelector students={students} task={task} callback={removeStudentFromStudentList}/>
                    )}
                </div>
            </div>
        </div>
    );
};
