const dev = {
    s3: {
        REGION: "ap-southeast-2",
        BUCKET: "aenglish-dev",
        RESOURCE_PATH: "resources",
        RESOURCE_IMAGES_PATH: "resources/images",
        READING_TEST_PATH: "reading-tests", // deprecated
        LESSON_PATH: "lessons",
        LESSON_IMAGES_PATH: "lessons/images",
        LESSON_FILES_PATH: "lessons/files",
        RESPONSE_FILES_PATH: "responses",
        PUBLIC_URL: "https://aenglish-dev.s3-ap-southeast-2.amazonaws.com/public"
    },
    apiGateway: {
        REGION: "ap-southeast-2",
        URL: "http://localhost:5000"
    },
    cognito: {
        REGION: "ap-southeast-2",
        USER_POOL_ID: "ap-southeast-2_pY4nxyB1x",
        APP_CLIENT_ID: "24378r0aqa4r9qqpekfocm0no2",
        IDENTITY_POOL_ID: "ap-southeast-2:a9acc197-fd93-4314-b4df-d152a9b27240",
    }
};

const production = {
    s3: {
        REGION: "ap-southeast-1",
        BUCKET: "aenglish-app-production",
        RESOURCE_PATH: "resources",
        RESOURCE_IMAGES_PATH: "resources/images",
        READING_TEST_PATH: "reading-tests", // deprecated
        LESSON_PATH: "lessons",
        LESSON_IMAGES_PATH: "lessons/images",
        LESSON_FILES_PATH: "lessons/files",
        RESPONSE_FILES_PATH: "responses",
        PUBLIC_URL: "https://aenglish-app-production.s3-ap-southeast-1.amazonaws.com/public"
    },
    apiGateway: {
        REGION: "ap-southeast-1",
        URL: "https://a1u7zr2swb.execute-api.ap-southeast-1.amazonaws.com/production"
    },
    cognito: {
        REGION: "ap-southeast-1",
        USER_POOL_ID: "ap-southeast-1_PPMWYa00P",
        APP_CLIENT_ID: "5sfn1f582qavf64u4esc5v9epl",
        IDENTITY_POOL_ID: "ap-southeast-1:0efc5d90-b64e-46c4-b3c8-0d9f595b3f9b",
    }
};

const stage = process.env.REACT_APP_STAGE;

export default stage === 'production' ? production : dev;
