import React, {useState, useEffect} from "react";
import Editor from "rich-markdown-editor";
import {API} from "@aws-amplify/api";
import {useParams, useHistory} from "react-router-dom";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const NewTaskPage = () => {
    const [title, setTitle] = useState('');
    const [type, setType] = useState('WRITING_TASK_2');
    const [category, setCategory] = useState('');
    const [content, setContent] = useState('');
    const [savedContent, setSavedContent] = useState(null);
    const [book, setBook] = useState('');
    const [year, setYear] = useState('');
    const [instruction, setInstruction] = useState('');
    const [savedInstruction, setSavedInstruction] = useState(null);
    const [vocabulary, setVocabulary] = useState('');
    const [savedVocabulary, setSavedVocabulary] = useState(null);
    const [ideas, setIdeas] = useState('');
    const [savedIdeas, setSavedIdeas] = useState(null);
    const [sample, setSample] = useState('');
    const [savedSample, setSavedSample] = useState(null);
    const {taskId} = useParams();
    const history = useHistory();
    const [followingTaskIds, setFollowingTaskIds] = useState('')
    const [savedNote, setSavedNote] = useState(null)
    const [note, setNote] = useState('')
    const categories = [
        {
            id: '',
            name: ''
        },
        {
            id: "OPINION",
            name: "Opinion"
        },
        {
            id: "DISCUSSION",
            name: "Discussion"
        },
        {
            id: "PROBLEM_SOLUTION",
            name: "Problem - Solution"
        },
        {
            id: "CAUSE_EFFECT",
            name: "Cause - Effect"
        },
        {
            id: "ADVANTAGE_DISADVANTAGE",
            name: "Advantage - Disadvantage"
        },
        {
            id: "OTHERS",
            name: "Others"
        }
    ];

    const saveNote = async (e) => {
        e.preventDefault();

        try {
            let data = {
                id: taskId ? taskId : null,
                task_type: type,
                content: content,
                title: title,
                meta_data: JSON.stringify({
                    instruction: instruction,
                    vocabulary: vocabulary,
                    ideas: ideas,
                    sample: sample,
                    note: note
                }),
                category: category,
                book: book,
                year: year
            }
            if (followingTaskIds) {
                data['following_task_ids'] = followingTaskIds
            }
            const response = await API.put("app", "/tasks", {
                body: data
            });

            toast("Task was saved successfully");

            history.push(`/tasks/${response.task_id}`);
        } catch (e) {
            toast(e.toString());
        }
    };

    useEffect(() => {
        if (taskId) {
            API.get("app", "/tasks/" + taskId, {}).then((result => {
                let metaData = result.meta_data ? JSON.parse(result.meta_data) : {};
                setTitle(result.title);
                setType(result.task_type);
                setCategory(result.category);
                setContent(result.content);
                setSavedContent(result.content);
                setBook(result.book);
                setYear(result.year);

                if (metaData.instruction) {
                    setSavedInstruction(metaData.instruction);
                    setInstruction(metaData.instruction);
                } else {
                    setSavedInstruction('');
                }
                if (metaData.vocabulary) {
                    setSavedVocabulary(metaData.vocabulary);
                    setVocabulary(metaData.vocabulary);
                } else {
                    setSavedVocabulary('');
                }
                if (metaData.ideas) {
                    setSavedIdeas(metaData.ideas);
                    setIdeas(metaData.ideas);
                } else {
                    setSavedIdeas('');
                }
                if (metaData.sample) {
                    setSavedSample(metaData.sample);
                    setSample(metaData.sample);
                } else {
                    setSavedSample('');
                }
                if (metaData.note) {
                    setSavedNote(metaData.note)
                } else {
                    setSavedNote('')
                }
                setFollowingTaskIds(result.following_task_ids ? result.following_task_ids : '')
            }));
        } else {
            setSavedContent('');
            setSavedInstruction('');
            setSavedVocabulary('');
            setSavedIdeas('');
            setSavedSample('');
        }
    }, [taskId]);

    return (
        <div className={'container-fluid'}>
            <h1 className={"color"}>Add a new writing task 2</h1>
            <form className={"row"} onSubmit={saveNote}>
                <div className={"col-md-6 mb-3"}>
                    <label htmlFor={"task-type"} className={"form-label"}>Category</label>
                    <select id={"category"} className={"form-select"} value={category} onChange={e => setCategory(e.target.value)} required={true}>
                        {categories.map(cat => (
                            <option key={cat.id} value={cat.id}>{cat.name}</option>
                        ))}
                    </select>
                </div>
                <div className={"col-md-6 mb-3"}>
                    <label htmlFor={"title"} className={"form-label"}>Title</label>
                    <input id={"title"} type={"text"} className={"form-control"} value={title} onChange={e => setTitle(e.target.value)} required={true}/>
                </div>
                <div className={"col-md-6 mb-3"}>
                    <label htmlFor={"book"} className={"form-label"}>Book</label>
                    <input id={"book"} type={"text"} className={"form-control"} value={book} onChange={e => setBook(e.target.value)}/>
                </div>
                <div className={"col-md-6 mb-3"}>
                    <label htmlFor={"year"} className={"form-label"}>Year</label>
                    <input id={"year"} type={"text"} className={"form-control"} value={year} onChange={e => setYear(e.target.value)}/>
                </div>
                <div className={"mb-3"}>
                    <label>Content</label>
                    {savedContent !== null && (
                        <Editor className={"form-control"} placeholder="" id={"content"} defaultValue={savedContent} onChange={value => setContent(value())}/>
                    )}
                </div>
                <div className={"mb-3"}>
                    <label>Instruction</label>
                    {savedInstruction !== null && (
                        <Editor className={"form-control"} placeholder="" id={"instruction"} defaultValue={savedInstruction} onChange={value => setInstruction(value())}/>
                    )}
                </div>
                <div className={"col-md-6 mb-3"}>
                    <label>Vocabulary</label>
                    {savedVocabulary !== null && (
                        <Editor className={"form-control"} placeholder="" id={"vocabulary"} defaultValue={savedVocabulary} onChange={value => setVocabulary(value())}/>
                    )}
                </div>
                <div className={"col-md-6 mb-3"}>
                    <label>Ideas</label>
                    {savedIdeas !== null && (
                        <Editor className={"form-control"} placeholder="" id={"ideas"} defaultValue={savedIdeas} onChange={value => setIdeas(value())}/>
                    )}
                </div>
                <div className={"mb-3"}>
                    <label>Sample</label>
                    {savedSample !== null && (
                        <Editor className={"form-control"} placeholder="" id={"sample"} defaultValue={savedSample} onChange={value => setSample(value())}/>
                    )}
                </div>

                {savedNote !== null && (
                    <div className={"col-md-12 mb-3"}>
                        <label htmlFor={"note"} className={"form-label"}>Note</label>
                        <Editor className={"form-control"} placeholder="Note" defaultValue={savedNote} onChange={value => setNote(value())} />
                    </div>
                )}

                <div className={"col-md-6 mb-3"}>
                    <label htmlFor={"following-task-id"} className={"form-label"}>Following task's IDs</label>
                    <input type={"text"} className={"form-control"} value={followingTaskIds} onChange={e => setFollowingTaskIds(e.target.value)}/>
                </div>

                <div className={"buttons"}>
                    <input type={"button"} className={"btn btn-secondary"} onClick={() => {history.goBack()}} value={"Cancel"}/>
                    <input type={"button"} className={"btn btn-info"} onClick={() => {window.open("/resources/new", "_blank")}} value={"Add a resource"}/>
                    <input type={"submit"} className={"btn btn-primary"} value={"Save"}/>
                </div>
            </form>
        </div>
    );
};
