import React, {useState, useEffect, useRef} from "react"
import {useParams, useHistory, Link, Prompt} from "react-router-dom"
import {API} from "aws-amplify"
import {Instruction} from "../../components/instruction/instruction.component";
import {toast} from "react-toastify";
import {ViewComments} from "../../components/comments/view_comments.component";
import "./assignment.scss";
import {useAppContext} from "../../libs/context";
import RichMarkdownEditor from "rich-markdown-editor";
import LoadingBar from "react-top-loading-bar";
import {ReactComponent as ViewIcon} from "../../assets/eye-small.svg";
import {SpeakerTestResponsePlayer} from "../../components/speaker_test_response_player";
import config from "../../config";
import {parseReadingTestFromRawData, useFetchRawReadingTest} from "../../services/reading_test_service";
import GreenAudioPlayer from "green-audio-player/src/js/main";
import {Timer} from "../../components/timer/timer.component";
import {useBeforeunload} from "react-beforeunload";

export const AssignmentPage = () => {
    const loadingBarRef = useRef(null);
    let {id, responseIndex} = useParams();
    const [title, setTitle] = useState('');
    const [status, setStatus] = useState('');
    const [content, setContent] = useState('');
    const [instruction, setInstruction] = useState('');
    const [ideas, setIdeas] = useState('');
    const [sample, setSample] = useState('');
    const [vocabulary, setVocabulary] = useState('');
    const [taskResponse, setTaskResponse] = useState('');
    const [taskResponses, setTaskResponses] = useState([]);
    const [comments, setComments] = useState({});
    const [score, setScore] = useState(null);
    const [overallComment, setOverallComment] = useState('');
    const [isRedoing, setIsRedoing] = useState(false);
    const history = useHistory();
    const {userRole} = useAppContext();
    const [taskAssessment, setTaskAssessment] = useState(null);
    const [taskAssessments, setTaskAssessments] = useState([]);
    const [taskType, setTaskType] = useState(null)
    const [test, setTest] = useState(null)
    const [prerequisiteAssignment, setPrerequisiteAssignment] = useState(null)
    const [timerStarted, setTimerStarted] = useState(false)
    const [assignmentStarted, setAssignmentStarted] = useState(false)
    const [responseSubmitted, setResponseSubmitted] = useState(false)

    const buildKeyForLocalStorage = (key) => {
        const prefix = `task:${id}:`;

        return `${prefix}.${key}`;
    };

    useEffect(() => {
        if (test) {
            GreenAudioPlayer.init({
                selector: '.explanation-player',
                stopOthersOnPlay: true
            })
        }
    }, [test])

    const useStateWithLocalStorage = (stateName, defaultValue) => {
        const key = buildKeyForLocalStorage(stateName);
        let savedValue = localStorage.getItem(key);
        savedValue = savedValue ? savedValue : defaultValue;
        const [value, setValue] = useState(savedValue);

        useEffect(() => {
            localStorage.setItem(key, value);
        }, [value]);

        return [value, setValue];
    };
    const [pendingResponse, setPendingResponse] = useStateWithLocalStorage('pendingResponse', '');

    useEffect(async () => {
        loadingBarRef.current.continuousStart();

        await API.get("app", `/assignments/${id}`, {}).then(result => {
            const metaData = result.meta_data ? JSON.parse(result.meta_data) : {};

            setStatus(result.status);
            setTitle(result.title);
            setContent(result.content);
            setInstruction(metaData.instruction ? metaData.instruction : '');
            setIdeas(metaData.ideas ? metaData.ideas : '');
            setVocabulary(metaData.vocabulary ? metaData.vocabulary : '');
            setSample(metaData.sample ? metaData.sample : '');
            setTaskAssessments(result.task_assessments ? result.task_assessments : []);
            setTaskType(result.task_type)

            responseIndex = responseIndex ? parseInt(responseIndex) : 0;
            if (result.task_assessments && result.task_assessments[responseIndex]) {
                const taskAssessment = result.task_assessments[responseIndex];
                setTaskResponse(taskAssessment.content);
                setComments(JSON.parse(taskAssessment.comments));
                setOverallComment(taskAssessment.overall_comment);
                setScore(taskAssessment.score);
                setTaskAssessment(taskAssessment);
                setTaskResponse(taskAssessment.content);
            } else {
                setComments({});
                setOverallComment('');
                setScore(null);
                setTaskAssessment(null);

                if (result.task_responses && result.task_responses.length) {
                    setTaskResponse(result.task_responses[responseIndex].content);
                } else if (result.task_response) {
                    setTaskResponse(result.task_response.content);
                }
            }

            setTaskResponses(result.task_responses ? result.task_responses : []);

            if (result.prerequisite_assignment) {
                setPrerequisiteAssignment(result.prerequisite_assignment)
            }

            if (taskType === 'SPEAKING_TEST') {
                loadTest(result.task_id)
            }
        });
        loadingBarRef.current.complete();
    }, [id, responseIndex]);

    const loadTest = async taskId => {
        const serverResponse = await API.get("app", `/tasks/${taskId}`, {});
        const parsedTest = await parseReadingTestFromRawData(serverResponse);
        setTest(parsedTest)
    }

    const start = (e) => {
        e.preventDefault();

        if (window.confirm("Are you sure you want to start working on this task?")) {
            API.post("app", `/assignments/${id}`, {}).then(newStatus => {
                setStatus(newStatus)
                setAssignmentStarted(true)
                setTimerStarted(true)
            }).catch(e => toast(e.toString()))
        }
    };

    const save = async () => {
        await API.put("app", `/assignments/${id}/respond`, {
            body: {
                content: pendingResponse,
                meta_data: ''
            }
        }).then(newStatus => {
            setStatus(newStatus);
            setResponseSubmitted(true)
            toast('You have submitted your response successfully');
            localStorage.removeItem(buildKeyForLocalStorage('pendingResponse'));
            if (isRedoing) {
                window.location.href = `/assignments/${id}/response/${taskResponses.length}`;
            } else {
                setTaskResponse(pendingResponse);
            }
        }).catch(e => toast.error(e.getMessage()))
    }

    const submitClicked = async e => {
        e.preventDefault();

        if (window.confirm("Are you sure you want to submit your response?")) {
            save()
        }
    };

    const confirmRedo = async (e) => {
        e.preventDefault();

        if (window.confirm('Are you sure you want to redo this test?')) {
            setIsRedoing(true);
        }
    }

    const timerCallback = (minutes, seconds) => {
        if (minutes === 0 && seconds === 0) {
            toast(`This is the end of the writing task. Your response will be automatically submitted in 5 seconds.`);
            window.setTimeout(() => {
                save()
            }, 5000);
        }
    }

    useBeforeunload((event) => {
        if (assignmentStarted && !responseSubmitted) {
            event.preventDefault()
        }
    })

    return (
        <div className={'container-fluid'}>
            <LoadingBar color={"#0d6efd"} ref={loadingBarRef}/>
            <Prompt message={"Are you sure you want to leave this page without submitting your response?"} when={assignmentStarted && !responseSubmitted} />
            {status === 'Pending' && prerequisiteAssignment && (
                <div className="alert alert-warning" role="alert">
                    <strong>Note:</strong> This assignment cannot be started until the assignment <strong>{prerequisiteAssignment.title}</strong> is finished
                </div>
            )}
            <form className={"row"} onSubmit={submitClicked}>
                {(status !== 'Reviewed' || isRedoing) &&  (
                    <div className={"row mb-3"}>
                        <h1 className={"color"}>{title}</h1>
                        {test.estimatedTime && (
                            <p>Estimated time: {test.estimatedTime} minutes</p>
                        )}
                        {taskType !== 'SPEAKING_TEST' && (
                            <RichMarkdownEditor readOnly={true} defaultValue={content} value={content}/>
                        )}
                    </div>
                )}

                {status === "Pending" && (
                    <div className={"buttons"}>
                        <input type={"button"} className={"btn btn-secondary"} onClick={() => {history.push('/assignments')}} value={"Back"}/>
                        <input type={"button"} className={`btn btn-primary ${prerequisiteAssignment ? 'disabled' : ''}`} value={"Start"} onClick={start}/>
                    </div>
                )}

                {(status === 'InProgress' || isRedoing) &&
                    <div>
                        <div className={"col-sm-11"}>
                            <textarea className={"form-control mb-3"} rows={20} style={{marginRight: '20px'}} value={pendingResponse} onChange={e => setPendingResponse(e.target.value)}/>
                        </div>
                        <div className={"buttons"}>
                            <input type={"button"} className={"btn btn-secondary"} onClick={() => {history.goBack()}} value={"Back"}/>
                            <input type={"submit"} className={"btn btn-primary"} value={"Submit"} />
                            {timerStarted && (
                                <Timer durationInMinutes={40} durationInSeconds={0} started={true} callback={timerCallback}></Timer>
                            )}
                        </div>
                    </div>
                }

                {(status === 'NeedsReview') && (
                    <>
                        <div className={"row mb-3"}>
                            <hr/>
                            <h1>Task response</h1>
                            {taskType !== 'SPEAKING_TEST' && (
                                <div style={{"whiteSpace": "pre-line"}}>
                                    {taskResponse}
                                </div>
                            )}
                            {taskType === 'SPEAKING_TEST' && test && taskResponse && (
                                <SpeakerTestResponsePlayer files={test.files} responseFile={`${config.s3.PUBLIC_URL}/${taskResponse}`}/>
                            )}
                        </div>
                        <div className={"buttons"}>
                            <input type={"button"} className={"btn btn-secondary"} onClick={() => {history.goBack()}} value={"Back"}/>
                            {taskType === 'SPEAKING_TEST' && test && test.hasExplanationAudioFile && (
                                <input type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#explanationPopup" value={"Show explanation"}/>
                            )}
                            {userRole === 'admin' && (
                                <Link to={`/assignments/${id}/assessment`} className={"btn btn-primary"}>Start reviewing</Link>
                            )}
                        </div>
                    </>
                )}

                {status === 'Reviewed' && !isRedoing && (
                    <>
                        <div className={"row"}>
                            <div className={"col-sm-7 mb-3"}>
                                <h1 className={"color"}>{title}</h1>
                                {taskType && taskType !== 'SPEAKING_TEST' && (
                                    <RichMarkdownEditor readOnly={true} value={content}/>
                                )}
                            </div>
                            <div className={"col-sm-5"}>
                                {/*<h1 className={"text-center"}>Your score</h1>*/}
                                {/*<h2 className={"score"}>{score}</h2>*/}
                                <p style={{"whiteSpace": "pre-line"}}><cite>{overallComment}</cite></p>
                            </div>
                        </div>
                        <hr/>
                        <h1>Task response</h1>
                        {taskType === 'SPEAKING_TEST' && test && (
                            <div style={{marginBottom: '10px'}}>
                                <SpeakerTestResponsePlayer files={test.files} responseFile={`${config.s3.PUBLIC_URL}/${taskResponse}`}/>
                            </div>
                        )}
                        {taskType && taskType !== 'SPEAKING_TEST' && (
                            <ViewComments response={taskResponse} comments={comments}/>
                        )}
                        <div className={"buttons"}>
                            <input type={"button"} className={"btn btn-secondary"} onClick={() => {history.goBack()}} value={"Back"}/>
                            {userRole === 'student' && !isRedoing && taskType !== 'SPEAKING_TEST' && (
                                <button className={"btn btn-primary"} onClick={confirmRedo}>Redo</button>
                            )}
                            {userRole === 'admin' && !taskAssessment && (
                                <Link to={`/assessment/${id}/response/${responseIndex}`} className={"btn btn-primary"}>Start reviewing</Link>
                            )}
                            {taskType === 'SPEAKING_TEST' && test && test.hasExplanationAudioFile && (
                                <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#explanationPopup">Show explanation</button>
                            )}
                        </div>
                    </>
                )}

                {test && test.hasExplanationAudioFile && (
                    <div className="modal fade" id="explanationPopup" tabIndex="-1" aria-labelledby="explanationPopup" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Explanation</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal"
                                            aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <RichMarkdownEditor readOnly={true} defaultValue={test.explanation1} value={test.explanation1}/>
                                    {test.explanationAudioFile1 && (
                                        <div className={"explanation-player"}>
                                            <audio>
                                                <source src={test.explanationAudioFile1} type={"audio/mpeg"}/>
                                            </audio>
                                        </div>
                                    )}
                                    {test.explanationAudioFile2 && (
                                        <div className={"explanation-player"}>
                                            <audio>
                                                <source src={test.explanationAudioFile2} type={"audio/mpeg"}/>
                                            </audio>
                                        </div>
                                    )}
                                    {test.explanationAudioFile3 && (
                                        <div className={"explanation-player"}>
                                            <audio>
                                                <source src={test.explanationAudioFile3} type={"audio/mpeg"}/>
                                            </audio>
                                        </div>
                                    )}
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </form>

            {taskType && taskType !== 'SPEAKING_TEST' && (
                <Instruction instruction={instruction} ideas={ideas} vocabulary={vocabulary} sample={sample} showSample={userRole === 'admin' || (userRole === 'student' && status !== 'Pending' && status !== 'InProgress')}/>
            )}

            {taskResponses.length > 0 && (
                <div className={"row"} style={{paddingTop: '20px'}}>
                    <h2 className={"color"}>Your attempts</h2>

                    <table className={"table"}>
                        <thead>
                        <tr>
                            <th scope={"col"}>Submitted date</th>
                            <th>Status</th>
                            <th scope={"col"}></th>
                        </tr>
                        </thead>
                        <tbody>
                        {taskResponses.map((response, index) => {
                            return (
                                <tr key={index}>
                                    <td>{response.created_at ? (new Date(Date.parse(response.created_at + 'Z'))).toLocaleString() : ''}</td>
                                    <td>{taskAssessments[index] ? 'Reviewed' : 'Awaiting review'}</td>
                                    <td><Link title={"View your response"} to={`/assignments/${id}/response/${index}`}><ViewIcon/></Link></td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};
