import React, {useState, useEffect} from "react";
import {API} from "@aws-amplify/api";
import {toast} from "react-toastify";

export const AssignmentStudentSelector = (props) => {
    const students = props.students
    const task = props.task;
    const callback = props.callback;
    const [assignedStudentId, setAssignedStudentId] = useState('');
    const [prerequisiteAssignments, setPrerequisiteAssignments] = useState([])
    const [selectedPrerequisiteAssignmentId, setSelectedPrerequisiteAssignmentId] = useState('')

    const loadPrerequisiteAssignments = studentId => {
        API.get('app', '/assignments', {
            queryStringParameters: {
                email: studentId,
                status: 'Pending'
            }
        }).then((response) => {
            setPrerequisiteAssignments(response)
        })
    }

    const assign = () => {
        if (window.confirm("Are you sure you want to assign this task to this student?")) {
            try {
                API.post("app", "/assignments", {
                    body: {
                        email: assignedStudentId,
                        task_id: task.task_id,
                        title: task.title,
                        task_type: task.task_type,
                        content: task.content,
                        meta_data: task.meta_data,
                        prerequisite_assignment_id: selectedPrerequisiteAssignmentId ? selectedPrerequisiteAssignmentId : null
                    }
                }).then(() => {
                    toast(`Task was assigned successfully to ${assignedStudentId}`);
                    setPrerequisiteAssignments([])
                    setAssignedStudentId('')
                    setSelectedPrerequisiteAssignmentId('')
                    callback(assignedStudentId);
                });
            } catch (e) {
                toast(e.toString());
            }
        }
    };

    const onAssignedStudentChanged = e => {
        e.preventDefault()
        setAssignedStudentId(e.target.value)

        if (e.target.value) {
            loadPrerequisiteAssignments(e.target.value)
        } else {
            setPrerequisiteAssignments([])
        }
    }

    return (
        <div className={"row"}>
            <div className={"col-sm-4"} style={{paddingRight: 0}}>
                <select className={"form-select student-selector"} value={assignedStudentId} onChange={onAssignedStudentChanged}>
                    <option value={""}>Select a student to assign</option>
                    {students.map(student => (
                        <option key={student.email} value={student.email}>{student.first_name} {student.last_name}</option>
                    ))}
                </select>
            </div>
            <div className={"col-sm-6"} style={{paddingRight: 0}}>
                <select className={"form-select student-selector"} value={selectedPrerequisiteAssignmentId} onChange={e => setSelectedPrerequisiteAssignmentId(e.target.value)}>
                    <option value={""}>Select a prerequisite assignment</option>
                    {prerequisiteAssignments.map(assignment => (
                        <option key={assignment.assignment_id} value={assignment.assignment_id}>{assignment.title}</option>
                    ))}
                </select>
            </div>
            <div className={"col-sm-2"}>
                <button disabled={!assignedStudentId} className={"btn btn-dark col-auto"} onClick={assign}>Assign</button>
            </div>
        </div>
    );
};
