import {useParams} from "react-router-dom";
import {useEffect} from "react";
import {API} from "aws-amplify";
import {getTaskTypeForUrl} from "../../services/utils";

export const TaskRedirect = () => {
    const {taskId} = useParams()
    console.log({taskId})
    useEffect(async () => {
        const serverResponse = await API.get("app", `/tasks/${taskId}`, {});
        const taskType = serverResponse.task_type
        const taskTypeForUrl = getTaskTypeForUrl(taskType)
        console.log({taskTypeForUrl})
        if (taskTypeForUrl) {
            window.location.href = `/tasks/${taskTypeForUrl}/${taskId}`
        } else {
            window.location.href = `/tasks/${taskId}`
        }
    }, [taskId])

    return (
        <>
        Please wait. Redirecting...
        </>
    )
}
