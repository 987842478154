import React from 'react';
import {Link} from "react-router-dom";
import {ReactComponent as HomeLogo} from "../../assets/home.svg";
import {ReactComponent as BookIcon} from "../../assets/book.svg";
import {ReactComponent as ChecklistIcon} from "../../assets/checklist.svg";
import {ReactComponent as PeopleIcon} from "../../assets/people.svg";
import {ReactComponent as ProgressLogo} from "../../assets/progress.svg";
import {ReactComponent as CreateLogo} from "../../assets/create.svg";
import {useAppContext} from "../../libs/context";

export const Sidebar = () => {
    const {userRole} = useAppContext();

    return (
        <nav id="sidebar">
            {userRole === 'admin' && (
                <ul className="list-unstyled text-center">
                    <li>
                        <Link to='/tasks'>
                            <BookIcon/>
                            <span>Tasks</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/assignments">
                            <ChecklistIcon/>
                            <span>Assignments</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/students">
                            <PeopleIcon/>
                            <span>Students</span>
                        </Link>
                    </li>
                    <li style={{"display": "none"}}>
                        <Link to="/progress">
                            <ProgressLogo/>
                            <span>Progress</span>
                        </Link>
                    </li>
                    <li className="last">
                        <Link to="/tasks/select">
                            <CreateLogo/>
                            <span>New Task</span>
                        </Link>
                    </li>
                </ul>
            )}

            {userRole === 'student' && (
                <ul className="list-unstyled text-center">
                    {/*<li>*/}
                    {/*    <Link to='/'>*/}
                    {/*        <HomeLogo/>*/}
                    {/*        <span>Dashboard</span>*/}
                    {/*    </Link>*/}
                    {/*</li>*/}
                    <li>
                        <Link to="/assignments">
                            <ChecklistIcon/>
                            <span>Assignments</span>
                        </Link>
                    </li>
                </ul>
            )}
        </nav>
    );
};
