import GreenAudioPlayer from "green-audio-player/src/js/main";
import React, {useEffect, useRef, useState} from "react";
import ReactPlayer from "react-player";

export const SpeakerTestResponsePlayer = props => {
    let files = props.files
    let responseFile = props.responseFile
    const [currentPlayingFileIndex, setCurrentPlayingIndex] = useState(0)
    const [fileUrl, setFileUrl] = useState(null)
    const player = useRef(null)
    const [playing, setPlaying] = useState(false)

    useEffect(() => {
        GreenAudioPlayer.init({
            selector: '.player',
            stopOthersOnPlay: false
        })

        setFileUrl(files[0])
    }, [])

    const onEnded = async () => {
        switchFileForSpeakingTest();
    }

    const switchFile = () => {
        const newIndex = findNextFileIndexToPlay();

        if (newIndex) {
            setCurrentPlayingIndex(newIndex);
            setFileUrl(props.files[newIndex]);
        }
    }

    const switchFileForSpeakingTest = () => {
        const isPart1 = () => currentPlayingFileIndex <= 12
        const isPart2 = () => currentPlayingFileIndex === 13
        const isPart3 = () => currentPlayingFileIndex >= 14
        const part1Timeout = 25000
        const part2Timeout = 120000
        const part3Timeout = 40000

        if (isPart1()) {
            window.setTimeout(switchFile, part1Timeout)
        }
        if (isPart2()) {
            window.setTimeout(switchFile, part2Timeout)
        }
        if (isPart3()) {
            window.setTimeout(switchFile, part3Timeout)
        }
    }

    const findNextFileIndexToPlay = () => {
        for (let i = currentPlayingFileIndex + 1; i < props.files.length; i++) {
            if (props.files[i]) {
                return i
            }
        }

        return null
    }

    const onPlayQuestionClicked = (e) => {
        e.preventDefault()
        setPlaying(!playing)
    }

    return (
        <div className={"row"}>
            <div className="d-none">
                <button onClick={onPlayQuestionClicked}>Play questions</button>
            </div>
            <div className={"d-none"}>
                <ReactPlayer onEnded={onEnded} ref={player} url={fileUrl} playing={playing}/>
            </div>
            <div className={"player"}>
                <audio>
                    <source src={responseFile} type={"audio/mpeg"}/>
                </audio>
            </div>
            {/*<audio controls src={responseFile} controlsList="nodownload" onPlay={() => {setPlaying(!playing)}} />*/}
        </div>
    )
}
