class Task {
    constructor(
        public id: string,
        public title: string,
        public taskType: string,
        public readableTaskType: string,
        public content: string,
        public metaData: string,
        public category: string,
        public readableCategory: string,
        public book: string,
        public year: string,
        public assignedStudents: string[],
        public followingTaskIds: string,
        public parentTaskId: string
    ) {}
}

export {Task}
