import {useEffect, useState} from "react"
import "./comments.scss";
import {ReactComponent as CommentSvg} from "./comment-button.svg";
import {API} from "aws-amplify";
import {useHistory} from "react-router-dom";

export const Comments = (props) => {
    const [comments, setComments] = useState({});
    const [range, setRange] = useState(null);
    const [activeKey, setActiveKey] = useState(null);
    const [score, setScore] = useState(null);
    const history = useHistory();

    const onMouseUp = (e) => {
        const commentButton = document.getElementById('comment-button');

        if (!e.target.classList.contains('response-content')) {
            commentButton.style.visibility = 'hidden';
            return;
        }

        let selection = document.getSelection();

        if (selection.toString().length) {
            let currentRange = document.getSelection().getRangeAt(0);
            setRange();
            let top = e.nativeEvent.layerY - 20;
            commentButton.style.top = `${top}px`;
            commentButton.style.visibility = "visible";
            setRange(currentRange);
        } else {
            commentButton.style.visibility = "hidden";
        }
    };

    useEffect(() => {
        let element = document.getElementById(`comment-input-${activeKey}`);
        if (element) {
            element.focus();
        }
    }, [activeKey]);

    const addComment = (e) => {
        e.preventDefault();
        
        let key = String(Date.now());
        let clonedComments = {...comments};

        clonedComments[key] = {
            content: ''
        };
        setComments(clonedComments);
        setActiveKey(key);
        let span = document.createElement('span');
        span.className = 'has-comment';
        span.id = `comment-${key}`;
        span.setAttribute('data-key', key);
        span.onmouseover = mouseOverText;
        span.onmouseout = mouseOutText;
        range.surroundContents(span);
        document.getElementById('comment-button').style.visibility = 'hidden';
    };

    const removeKey = (key) => {
        let newComments = {...comments};
        delete newComments[key];
        setComments(newComments);
    };

    const remove = (e) => {
        e.preventDefault();
        let key = e.target.dataset.key;
        removeKey(key);

        let textNode = document.getElementById(`comment-${key}`);
        textNode.parentNode.replaceChild(document.createTextNode(textNode.textContent), textNode);
    };

    const saveComment = (e) => {
        e.preventDefault();
        let key = e.target.dataset.key;
        let commentContent = document.getElementById(`comment-input-${key}`).value;
        let newComments = {...comments};
        newComments[key]['content'] = commentContent;
        setComments(newComments);
        setActiveKey(null);
    };

    const mouseOverFeedback = (e) => {
        let key = e.target.dataset.key;
        document.getElementById(`comment-${key}`).classList.add('hover');
    };

    const mouseOutFeedback = (e) => {
        let key = e.target.dataset.key;
        document.getElementById(`comment-${key}`).classList.remove('hover');
    };

    const mouseOverText = (e) => {
        let key = e.target.dataset.key;
        document.getElementById(`feedback-view-mode-${key}`).classList.add('hover');
    };

    const mouseOutText = (e) => {
        let key = e.target.dataset.key;
        document.getElementById(`feedback-view-mode-${key}`).classList.remove('hover');
    };

    const save = (e) => {
        e.preventDefault();

        if (!score) {
            alert("You must specify a score");
            return;
        }

        if (window.confirm('Are you sure you want to finish your assessment?')) {
            API.put("app", `/assignments/${props.assignmentId}/assessment`, {
                body: {
                    content: document.getElementById('response-content').innerHTML,
                    comments: JSON.stringify(comments),
                    overall_comment: document.getElementById('overall-comment').value,
                    score: document.getElementById('score').value
                }
            }).then(() => props.afterAssessment());
        }
    };

    const editComment = (e) => {
        e.preventDefault();

        setActiveKey(e.target.dataset.key);
    };

    return (
        <form onSubmit={save}>
            <div className={"row mb-3"}>
                <hr/>
                <h1>Task response</h1>
                <div className={"col-sm-7 response"} style={{"whiteSpace": "pre-line"}} onMouseUp={onMouseUp}>
                    <div className={"response-content"} id={"response-content"}>
                        {props.response}
                    </div>
                    <button id={"comment-button"} className={"comment-button"} title={"Add a comment"} onClick={addComment}><CommentSvg/></button>
                </div>
                <div className={"col-sm-5 feedback-container"}>
                    {Object.keys(comments).map((key) => {
                        const comment = comments[key];
                        const top = document.getElementById(`comment-${key}`).offsetTop;
                        if (activeKey === key) {
                            return (
                                <div className={"feedback"} key={key} style={{'top': `${top}px`}}>
                                    <textarea className={"form-control mb-2"} id={`comment-input-${key}`} defaultValue={comment.content}/>
                                    <button className={"btn btn-primary"} data-key={`${key}`} onClick={saveComment}>
                                        Add
                                    </button>
                                    <button className={"btn btn-danger float-end"} type={"button"} data-key={`${key}`}
                                            onClick={remove}>
                                        Delete
                                    </button>
                                </div>
                            )
                        } else {
                            return (
                                <div className={"feedback-view-mode"} id={`feedback-view-mode-${key}`} key={key} style={{'top': `${top}px`}} data-key={key} onMouseOver={mouseOverFeedback}
                                    onMouseOut={mouseOutFeedback} onClick={editComment}>
                                    {comment.content}
                                </div>
                            );
                        }
                    })}
                </div>
            </div>
            <hr/>
            <div className={"col-sm-7 mb-3"}>
                <label className={"form-label"} htmlFor={"overall-comment"}>Comment</label>
                <textarea className={"form-control"} id={"overall-comment"} rows={4}/>
            </div>
            <div className={"col-sm-7 mb-3"}>
                <label className={"form-label"} htmlFor={"score"}>Score</label>
                <input className={"form-control"} type={"text"} id={"score"} onChange={e => setScore(e.target.value)}/>
            </div>
            <div className={"buttons"}>
                <input type={"button"} className={"btn btn-secondary"} onClick={() => {history.goBack()}} value={"Cancel"}/>
                <input type={"submit"} className={"btn btn-primary"} value={"Save"}/>
            </div>
        </form>
    );
};
