import './App.css';
import {useState, useEffect} from 'react';
import {Auth} from 'aws-amplify';
import {AppContext} from "./libs/context";
import {HomePage} from "./pages/homepage/homepage.component";
import {Switch, Route, useHistory} from 'react-router-dom'
import {TasksPage} from "./pages/tasks/tasks.component";
import {SubmissionsPage} from "./pages/submissions/submissions.component";
import {ProgressPage} from "./pages/progress/progress.component";
import {NewTaskPage} from "./pages/new_task/new_task_page";
import {Sidebar} from "./components/sidebar/sidebar.component";
import {Header} from "./components/header/header.component";
import React from 'react';
import {LoginPage} from "./pages/login/login_page.component";
import {ViewTaskPage} from "./pages/view_task/view_task_page";
import {ToastContainer} from "react-toastify";
import {NewStudentPage} from "./pages/new_student_page.component";
import {ViewStudentPage} from "./pages/view_student_page.component";
import {AssignmentPage} from "./pages/assignment/assignment_page.component";
import {AssessmentPage} from "./pages/assignment/assessment_page.component";
import {StudentsPage} from "./pages/students_page.component";
import {AssignmentsPage} from "./pages/assignments_page.component";
import {NewResourcePage} from "./pages/resource/new_resource_page.component";
import {ViewResourcePage} from "./pages/resource/view_resource_page.component";
import {NewReadingTestPage} from "./pages/new_task/new_reading_test_page";
import {ViewReadingTestPage} from "./pages/view_task/view_reading_task";
import {NewTaskSelectorPage} from "./pages/new_task/new_task_selector_page";
import {AssignmentReadingPage} from "./pages/assignment/assignment_reading_page";
import {TaskRedirect} from "./pages/view_task/task_redirect";

function App() {
    const [isAuthenticating, setIsAuthenticating] = useState(true);
    const [isAuthenticated, userHasAuthenticated] = useState(false);
    const [userRole, setUserRole] = useState(null);
    const [userEmail, setUserEmail] = useState(null);
    const history = useHistory();

    useEffect(() => {
        onLoad();
    }, [isAuthenticated]);

    async function onLoad() {
        try {
            await Auth.currentSession();
            userHasAuthenticated(true);

            const user = await Auth.currentAuthenticatedUser();
            setUserRole(user.signInUserSession.accessToken.payload["cognito:groups"][0]);
            setUserEmail(user.attributes.email);
        } catch (e) {
            history.push('/login');
        }

        setIsAuthenticating(false);
    }

    return (
        <div style={{'width': '100%'}}>
            <AppContext.Provider value={{isAuthenticated, userHasAuthenticated, userRole, userEmail}}>
                {isAuthenticated ? (
                    <>
                        <Sidebar/>
                        <div id="content" className="container-fluid">
                            <Header/>
                            <Switch>
                                <Route exact={true} path='/' component={HomePage}/>
                                <Route exact={true} path='/tasks' component={TasksPage}/>
                                <Route exact={true} path='/assignments' component={AssignmentsPage}/>
                                <Route exact={true} path='/progress' component={ProgressPage}/>
                                <Route exact={true} path='/tasks/select' component={NewTaskSelectorPage}/>
                                <Route exact={true} path='/tasks/new' component={NewTaskPage}/>
                                <Route exact={true} path='/tasks/:taskId' component={ViewTaskPage}/>
                                <Route exact={true} path='/tasks/:taskId/edit' component={NewTaskPage}/>
                                <Route exact={true} path={'/tasks/new/:taskType'} component={NewReadingTestPage}/>
                                <Route exact={true} path={'/tasks/:taskType/:taskId/edit'} component={NewReadingTestPage}/>
                                <Route exact={true} path={'/tasks/:taskType/:taskId'} component={ViewReadingTestPage}/>
                                <Route exact={true} path='/students' component={StudentsPage}/>
                                <Route exact={true} path='/students/new' component={NewStudentPage}/>
                                <Route exact={true} path='/students/:id' component={ViewStudentPage}/>
                                <Route exact={true} path='/students/:id/edit' component={NewStudentPage}/>
                                <Route exact={true} path={"/assignments/:id"} component={AssignmentPage}/>
                                <Route exact={true} path={"/assignments/:id/response/:responseIndex"} component={AssignmentPage}/>
                                <Route exact={true} path={"/assignments/:taskType/:id/:taskId/:responseIndex/:action"} component={AssignmentReadingPage}/>
                                <Route exact={true} path={"/assignments/:taskType/:id/:taskId/:responseIndex"} component={AssignmentReadingPage}/>
                                <Route exact={true} path={"/assignments/:taskType/:id/:taskId"} component={AssignmentReadingPage}/>
                                <Route exact={true} path={"/assignments/:id/assessment"} component={AssessmentPage}/>
                                <Route exact={true} path={"/assessment/:id/response/:responseIndex"} component={AssessmentPage}/>
                                <Route exact={true} path='/resources/new' component={NewResourcePage}/>
                                <Route exact={true} path='/resources/:id' component={ViewResourcePage}/>
                                <Route exact={true} path='/resources/:id/edit' component={NewResourcePage}/>
                                <Route exact={true} path={'/redirect-task/:taskId'} component={TaskRedirect}/>
                            </Switch>
                            <div id="footer" className="text-center d-none">©2021 aEnglish.io</div>
                        </div>
                    </>
                    ) : (
                    <Route exact={true} path='/login' component={LoginPage}/>
                )}
            </AppContext.Provider>

            <ToastContainer hideProgressBar position="bottom-right"/>
        </div>
    );
}

export default App;
