import {useState, useEffect} from "react";
import {useParams, useHistory} from "react-router-dom";
import {Storage} from "aws-amplify";
import RichMarkdownEditor from "rich-markdown-editor";
import {useAppContext} from "../../libs/context";
import {toast} from "react-toastify";
import config from "../../config";
import {Instruction} from "../../components/instruction/instruction.component";

export const ViewResourcePage = () => {
    const {id} = useParams();
    const [title, setTitle] = useState('');
    const [source, setSource] = useState('');
    const [sourceUrl, setSourceUrl] = useState('');
    const [content, setContent] = useState('');
    const {userRole} = useAppContext();
    const history = useHistory();

    useEffect(() => {
        Storage.get(`${config.s3.RESOURCE_PATH}/${id}.json`, {
            level: 'public',
            download: true,
            cacheControl: 'no-cache',
            contentType: 'application/json'
        }).then((response) => {
            response.Body.text().then(body => {
                const json = JSON.parse(body);

                setTitle(json.title);
                setSource(json.source);
                setSourceUrl(json.sourceUrl);
                setContent(json.content);
            });
        });
    }, [id]);

    const copyUrl = () => {
        const el = document.createElement('textarea');
        const url = window.location.href;
        el.value = `[${title}](${url} "${title}")`;
        el.setAttribute('readonly', '');
        el.position = 'absolute';
        el.style.left = '-9999px';
        document.body.append(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);

        toast('Url copied');
    };

    const lookupOnDoubleClick = () => {
        let selection = document.getSelection();
        const highlightedText = selection.toString().toLowerCase();

        if (highlightedText.length) {
            window.open(
                `https://www.oxfordlearnersdictionaries.com/definition/english/${highlightedText}?q=${highlightedText}`,
                'Oxford',
                "resizable,scrollbars,status,width=600,height=400,left=500,top=200"
            );
        }
    };

    const instruction =
            `<div>Tip: Double click a word to bring up the Oxford dictionary</div><br><br>
            <img src="/img/resource-double-click.png" width="100%" alt="Resource double clicked"/>`;

    return (
        <div className={"container-fluid"}>
            <h1 className={"color"}>{title}</h1>

            <div className={"mb-3"} onDoubleClick={lookupOnDoubleClick}>
                <RichMarkdownEditor readOnly={true} value={content}/>
            </div>
            <div className={"row mb-3"}>
                <i>Source: <a href={sourceUrl} rel={"noreferrer"} target={"_blank"}>{source}</a></i>
            </div>

            {userRole === 'admin' && (
                <div className={"buttons"}>
                    <input type={"button"} className={"btn btn-info"} value={"Edit"} onClick={() => history.push(`/resources/${id}/edit`)}/>
                    <input type={"button"} className={"btn btn-primary"} value={"Copy Url"} onClick={copyUrl}/>
                </div>
            )}

            <Instruction hideTabs={true} isHtml={true} instruction={instruction}/>
        </div>
    );
};
