import {useParams, useHistory} from "react-router-dom"
import React, {useState, useEffect} from "react"
import {API} from "aws-amplify";
import {toast} from "react-toastify";

export const ViewStudentPage = () => {
    const {id} = useParams();
    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [targetBand, setTargetBand] = useState("");
    const history = useHistory();

    useEffect(() => {
        try {
            API.get("app", `/students/${id}`, {}).then(result => {
                setEmail(result.email);
                setFirstName(result.first_name);
                setLastName(result.last_name);
                setTargetBand(result.target_band);
            });
        } catch (e) {
            toast(e.toString());
        }
    }, [id]);

    const edit = () => {
        history.push(`/students/${id}/edit`);
    };

    return (
        <div className={'container-fluid'}>
            <h1 className={"color"}>{firstName} {lastName}</h1>

            <div className={"row mb-3"}>
                <label className={"col-sm-2"}><strong>Email</strong></label>
                <div className={"col-sm-10"}>{email}</div>
            </div>

            <div className={"row mb-3"}>
                <label className={"col-sm-2"}><strong>First name</strong></label>
                <div className={"col-sm-10"}>{firstName}</div>
            </div>

            <div className={"row mb-3"}>
                <label className={"col-sm-2"}><strong>Last name</strong></label>
                <div className={"col-sm-10"}>{lastName}</div>
            </div>

            <div className={"row mb-3"}>
                <label className={"col-sm-2"}><strong>Target band</strong></label>
                <div className={"col-sm-10"}>{targetBand}</div>
            </div>

            <div className={"buttons"}>
                <input type={"button"} className={"btn btn-secondary"} onClick={() => {history.goBack()}} value={"Back"}/>
                <input onClick={edit} type={"submit"} className={"btn btn-primary"} value={"Edit"}/>
            </div>
        </div>
    );
};
