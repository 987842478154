import {useAppContext} from "../libs/context";
import {API} from "aws-amplify"
import React, {useState, useEffect, useRef} from "react"
import {Link} from "react-router-dom";
import {ReactComponent as ViewIcon} from "../assets/eye-small.svg";
import {ReactComponent as DeleteIcon} from "../assets/trash.svg";
import LoadingBar from "react-top-loading-bar";
import {toast} from "react-toastify";
import {StudentStatusSelector} from "../components/student_status_selector.component";
import {getAssignments} from "../services/assignment_service";
import "./assignments_page.scss"

export const AssignmentsPage = () => {
    const loadingBarRef = useRef(null);
    const [assignments, setAssignments] = useState([]);
    const [groupedAssignments, setGroupedAssignments] = useState({})
    const {userEmail, userRole} = useAppContext();
    const [selectedStudent, setSelectedStudent] = useState('')
    const [selectedStatus, setSelectedStatus] = useState('')
    const [selectedType, setSelectedType] = useState('')

    const getLinkForAssignment = assignment => {
        if (assignment.task_type === 'READING_TEST') {
            return `/assignments/reading-test/${assignment.assignment_id}/${assignment.task_id}`;
        }

        if (assignment.task_type === 'LISTENING_TEST') {
            return `/assignments/listening-test/${assignment.assignment_id}/${assignment.task_id}`;
        }

        if (assignment.task_type === 'SPEAKING_TEST' && ['Pending', 'InProgress'].indexOf(assignment.status) !== -1) {
            return `/assignments/speaking-test/${assignment.assignment_id}/${assignment.task_id}`;
        }

        if (assignment.task_type === 'SPEAKING_TEST' && ['NeedsReview', 'Reviewed'].indexOf(assignment.status) !== -1) {
            return `assignments/${assignment.assignment_id}`;
        }

        if (assignment.task_type === 'WRITING_TASK_2') {
            return `assignments/${assignment.assignment_id}`;
        }

        if (assignment.task_type === 'GUIDED_WRITING') {
            return `/assignments/guided-writing/${assignment.assignment_id}/${assignment.task_id}`;
        }

        if (assignment.task_type === 'SKILL_BOOST') {
            return `/assignments/skill-boost/${assignment.assignment_id}/${assignment.task_id}`;
        }

        if (assignment.task_type === 'LISTENING_PRACTICE') {
            return `/assignments/listening-practice/${assignment.assignment_id}/${assignment.task_id}`;
        }

        if (assignment.task_type === 'READING_PRACTICE') {
            return `/assignments/reading-practice/${assignment.assignment_id}/${assignment.task_id}`;
        }
    };

    const unassignTask = async (assignment, e) => {
        e.preventDefault()

        if (window.confirm(`Are you sure you want to delete the assignment "${assignment.title}" assigned to "${assignment.student_id}"?`)) {
            loadingBarRef.current.continuousStart();

            try {
                await API.del("app", "/assignments/" + assignment.assignment_id, {});
                toast('Assignment has been deleted successfully')
                await filterAssignments(selectedStudent, selectedStatus, selectedType)
                loadingBarRef.current.complete()
            } catch (e) {
                toast.error(e.toString())
                loadingBarRef.current.complete();
            }
        }
    }

    useEffect(async () => {
        if (userEmail && userRole === 'student') {
            setSelectedStudent(userEmail)
            filterAssignments(userEmail, '', '')
        }
    }, [userEmail, userRole]);

    const onFilter = (email, status, taskType) => {
        setSelectedStudent(email)
        setSelectedStatus(status)
        setSelectedType(taskType)

        filterAssignments(email, status, taskType)
    }

    const filterAssignments = (email, status, taskType) => {
        loadingBarRef.current.continuousStart()
        getAssignments(email, status, taskType).then(response => {
            groupAssignmentsByWeek(response)
            loadingBarRef.current.complete();
        }, e => {
            toast.error(e.toString())
        })
    }

    const groupAssignmentsByWeek = assignments => {
        let groupedAssignments = {}

        assignments.forEach((assignment) => {
            const week = assignment.week

            if (!groupedAssignments[week]) {
                groupedAssignments[week] = {
                    week: assignment.week,
                    weekLabel: assignment.is_current_week ? 'This week' : (assignment.is_last_week ? 'Last week' : `${assignment.start_of_week} - ${assignment.end_of_week}`),
                    assignments: []
                }
            }

            groupedAssignments[week]['assignments'].push(assignment)
        })
        console.log({groupedAssignments})

        setGroupedAssignments(groupedAssignments)
    }

    return (
        <div className={"container-fluid"}>
            <LoadingBar color={"#0d6efd"} ref={loadingBarRef}/>
            <h1 className={"color"}>Assignments</h1>
            {userRole === 'admin' && (
                <StudentStatusSelector onFilter={onFilter}></StudentStatusSelector>
            )}

            {!selectedStudent && !selectedStatus && !selectedType && (
                <div className={"search-result-text"}>Please specify at least 1 search condition</div>
            )}

            {(selectedStudent || selectedStatus || selectedType) && Object.keys(groupedAssignments).length === 0 && (
                <div className={"search-result-text"}>There are no assignments matching your search criteria</div>
            )}

            {Object.keys(groupedAssignments).reverse().map(week => {
                return (
                    <div key={`week-${week}`} className={"assignments-table-wrapper"}>
                        <h3 className={`${groupedAssignments[week].weekLabel === 'This week' ? 'bg-primary' : 'bg-secondary'}`}>{groupedAssignments[week].weekLabel}</h3>

                        <div className={"assignments-table"}>
                            <table className={"table"}>
                                <thead>
                                <tr>
                                    <th scope={"col"} width={"40%"}>Title</th>
                                    <th scope={"col"} width={"20%"}>Task type</th>
                                    {userRole === 'admin' && (<th scope={"col"}>Student</th>)}
                                    <th scope={"col"} width={"10%"}>Status</th>
                                    <th scope={"col"} width={"10%"}>Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                {groupedAssignments[week].assignments.map(assignment => {
                                    return (
                                        <tr key={assignment.assignment_id}>
                                            <td><Link to={getLinkForAssignment(assignment)}>{assignment.title}</Link></td>
                                            <td>{assignment.task_type}</td>
                                            {userRole === 'admin' && <td>{assignment.student_id}</td>}
                                            <td>{assignment.readable_status}</td>
                                            <td className={"action-buttons"}>
                                                <span><Link title={"View assignment"} to={getLinkForAssignment(assignment)}><ViewIcon/></Link></span>
                                                {userRole === 'admin' && (
                                                    <span><a href={'#'} onClick={e => unassignTask(assignment, e)} title={"Unassign"}><DeleteIcon/></a></span>
                                                )}
                                            </td>
                                        </tr>
                                    );
                                })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )
            })}
        </div>
    );
};
