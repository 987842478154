import {useEffect, useState} from "react";
import {useInterval} from "../../services/utils";
import "./timer.scss"

export const Timer = props => {
    let durationInMinutes = parseInt(props.durationInMinutes)
    let durationInSeconds = parseInt(props.durationInSeconds)
    const callback = props.callback
    let started = props.started
    const [minutes, setMinutes] = useState(durationInMinutes)
    const [seconds, setSeconds] = useState(durationInSeconds)
    const [delay, setDelay] = useState(null)

    useEffect(() => {
        if (started) {
            setDelay(1000)
        } else {
            setDelay(null)
        }
    }, [started])

    const stopTimer = () => {
        setDelay(null)
    }

    useInterval(() => {

        if (minutes === 0 && seconds === 0) {
            stopTimer()

            return
        }

        let newMinutes, newSeconds

        if (seconds === 0) {
            if (minutes > 0) {
                newMinutes = minutes - 1
                newSeconds = 59
            } else {
                newMinutes = minutes
                newSeconds = seconds - 1
            }
        } else {
            newMinutes = minutes
            newSeconds = seconds - 1
        }

        setMinutes(newMinutes)
        setSeconds(newSeconds)

        if (callback) {
            callback(newMinutes, newSeconds)
        }
    }, delay)
    return (
        <span className={`timer ${minutes < 5 ? "timer-alert" : ""}`}>
            {minutes >= 10 ? minutes : `0${minutes}`}:{seconds >= 10 ? seconds : `0${seconds}`}
        </span>
    )
}
