import React, {useState} from "react";
import {useHistory} from "react-router-dom";

export const NewTaskSelectorPage = () => {
    const [type, setType] = useState('WRITING_TASK_2');
    const history = useHistory();

    const taskTypes = [
        {
            id: "WRITING_TASK_2",
            name: "Writing task 2"
        },
        {
            id: 'READING_TEST',
            name: 'Reading Test'
        },
        {
            id: 'LISTENING_TEST',
            name: 'Listening test'
        },
        {
            id: 'SPEAKING_TEST',
            name: 'Speaking test'
        },
        {
            id: 'GUIDED_WRITING',
            name: 'Guided writing'
        },
        {
            id: 'SKILL_BOOST',
            name: 'Skill boost'
        },
        {
            id: 'LISTENING_PRACTICE',
            name: 'Listening practice'
        },
        {
            id: 'READING_PRACTICE',
            name: 'Reading practice'
        }
    ];

    const routeMap = {
        'WRITING_TASK_2': '/tasks/new',
        'READING_TEST': '/tasks/new/reading-test',
        'LISTENING_TEST': '/tasks/new/listening-test',
        'SPEAKING_TEST': '/tasks/new/speaking-test',
        'GUIDED_WRITING': '/tasks/new/guided-writing',
        'SKILL_BOOST': '/tasks/new/skill-boost',
        'LISTENING_PRACTICE': '/tasks/new/listening-practice',
        'READING_PRACTICE': '/tasks/new/reading-practice',
    };

    const add = e => {
        e.preventDefault();

        history.push(routeMap[type]);
    };

    return (
        <div className={"container-fluid"}>
            <h1 className={"color"}>Add a new task</h1>

            <div className={"col-md-6 mb-3"}>
                <label htmlFor={"task-type"} className={"form-label"}>Task type</label>
                <select id={"task-type"} className={"form-select"} value={type} onChange={e => setType(e.target.value)}>
                    {taskTypes.map(task => (
                        <option key={task.id} value={task.id}>{task.name}</option>
                    ))}
                </select>
            </div>

            <div className={"buttons"}>
                <input type={"button"} className={"btn btn-primary"} value={"Create"} onClick={add}/>
            </div>
        </div>
    );
};
