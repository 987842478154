import {useState, useEffect} from "react"
import {API} from "@aws-amplify/api";
import {Storage} from "@aws-amplify/storage";
import config from "../config";
import {markdownToHtml} from "./utils";
import {parseQuestionsInText} from "./question_service";
import {Remarkable} from "remarkable";

const useFetchReadingTest = taskId => {
    const [test, setTest] = useState(null);

    const markdown = new Remarkable('default', {html: true});
    const convertMarkdownToHtml = text => {
        text = text.replace(/\\/g, ''); // rich-markdown-editor leaves a \ for some new empty lines
        return markdown.render(text);
    };

    useEffect(() => {
        const fetchData = async () => {
            const serverResponse = await API.get("app", `/tasks/${taskId}`, {});
            const s3Path = serverResponse.content;
            const s3Response = await Storage.get(`${config.s3.READING_TEST_PATH}/${s3Path}`, {
                level: 'public',
                download: true,
                cacheControl: 'no-cache',
                contentType: 'application/json'
            });
            const rawTaskContent = await s3Response.Body.text();
            const taskContent = JSON.parse(rawTaskContent);

            let title = serverResponse.title;
            let content = serverResponse.content;


            let content1 = markdownToHtml(taskContent.passage1.content);
            let questions1 = parseQuestionsInText(convertMarkdownToHtml(taskContent.passage1.questions));
            let answers1 = taskContent.passage1.answers;

            let content2 = markdownToHtml(taskContent.passage2.content);
            let questions2 = parseQuestionsInText(convertMarkdownToHtml(taskContent.passage2.questions));
            let answers2 = taskContent.passage2.answers;

            let content3 = markdownToHtml(taskContent.passage3.content);
            let questions3 = parseQuestionsInText(convertMarkdownToHtml(taskContent.passage3.questions));
            let answers3 = taskContent.passage3.answers;

            let content4 = taskContent.passage4 ? markdownToHtml(taskContent.passage4.content) : null;
            let questions4 = taskContent.passage4 ? parseQuestionsInText(convertMarkdownToHtml(taskContent.passage4.questions)) : null;
            let answers4 = taskContent.passage4 ? taskContent.passage4.answers : null;

            setTest({
                title,
                content,
                content1,
                questions1,
                answers1,
                content2,
                questions2,
                answers2,
                content3,
                questions3,
                answers3,
                content4,
                questions4,
                answers4
            });
        };

        fetchData();
    }, [taskId]);

    return test;
};

const useFetchRawReadingTest = taskId => {
    const [test, setTest] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const serverResponse = await API.get("app", `/tasks/${taskId}`, {});
            const parsedTest = await parseReadingTestFromRawData(serverResponse)

            setTest(parsedTest)
        };

        fetchData();
    }, [taskId]);

    return test;
};

const parseReadingTestFromRawData = async serverResponse => {
    const s3Path = serverResponse.content;
    const s3Response = await Storage.get(`${config.s3.READING_TEST_PATH}/${s3Path}`, {
        level: 'public',
        download: true,
        cacheControl: 'no-cache',
        contentType: 'application/json'
    });
    const rawTaskContent = await s3Response.Body.text();
    const taskContent = JSON.parse(rawTaskContent);

    let title = serverResponse.title;
    let content = serverResponse.content;

    let content1 = taskContent.passage1.content;
    let questions1 = taskContent.passage1.questions;
    let answers1 = taskContent.passage1.answers;

    let content2 = taskContent.passage2.content;
    let questions2 = taskContent.passage2.questions;
    let answers2 = taskContent.passage2.answers;

    let content3 = taskContent.passage3.content;
    let questions3 = taskContent.passage3.questions;
    let answers3 = taskContent.passage3.answers;

    let content4 = taskContent.passage4 ? taskContent.passage4.content : {};
    let questions4 = taskContent.passage4 ? taskContent.passage4.questions : {};
    let answers4 = taskContent.passage4 ? taskContent.passage4.answers : {};

    let explanation1Json = {}
    let explanation2Json = {}
    let explanation3Json = {}
    let explanation4Json = {}
    try {
        explanation1Json = JSON.parse(taskContent.passage1.explanation)
    } catch (e) {}
    try {
        explanation2Json = JSON.parse(taskContent.passage2.explanation)
    } catch (e) {}
    try {
        explanation3Json = JSON.parse(taskContent.passage3.explanation)
    } catch (e) {}
    try {
        explanation4Json = JSON.parse(taskContent.passage4.explanation)
    } catch (e) {}

    const explanationsJson = {...explanation1Json, ...explanation2Json, ...explanation3Json, ...explanation4Json}

    const numberOfPassages = (content1 || questions1 ? 1 : 0) +
        (content2 || questions2 ? 1 : 0) +
        (content3 || questions3 ? 1 : 0) +
        (content4 || questions4 ? 1 : 0)

    let test = {
        title,
        content,
        content1,
        questions1,
        answers1,
        content2,
        questions2,
        answers2,
        content3,
        questions3,
        answers3,
        content4,
        questions4,
        answers4,
        numberOfQuestions: Object.keys({...answers1, ...answers2, ...answers3, ...answers4}).length,
        numberOfPassages,
        answers: {...answers1, ...answers2, ...answers3, ...answers4},
        explanation1: taskContent.passage1.explanation,
        explanation2: taskContent.passage2.explanation,
        explanation3: taskContent.passage3.explanation,
        explanation4: taskContent.passage4 ? taskContent.passage4.explanation : null,
        otherInfo1: taskContent.passage1.otherInfo ? taskContent.passage1.otherInfo : '',
        otherInfo2: taskContent.passage2.otherInfo ? taskContent.passage2.otherInfo : '',
        otherInfo3: taskContent.passage3.otherInfo ? taskContent.passage3.otherInfo : '',
        otherInfo4: taskContent.passage4.otherInfo ? taskContent.passage4.otherInfo : '',
        questionList: taskContent.questionList ? taskContent.questionList : {},
        explanations: explanationsJson,
        guidance1: taskContent.passage1.guidance ? taskContent.passage1.guidance : '',
        guidance2: taskContent.passage2.guidance ? taskContent.passage2.guidance : '',
        guidance3: taskContent.passage3.guidance ? taskContent.passage3.guidance : '',
        guidance4: taskContent.passage4.guidance ? taskContent.passage4.guidance : '',
        hints1: taskContent.passage1.hints ? taskContent.passage1.hints : '',
        hints2: taskContent.passage2.hints ? taskContent.passage2.hints : '',
        hints3: taskContent.passage3.hints ? taskContent.passage3.hints : '',
        hints4: taskContent.passage4.hints ? taskContent.passage4.hints : ''
    }

    let files = [];
    for (let i = 1; i <= 21; i++) {
        test[`file${i}`] = taskContent[`file${i}`] ? taskContent[`file${i}`] : null
        files.push(test[`file${i}`]);
    }

    test['files'] = files

    for (let i = 1; i <= 3; i++) {
        test[`explanationAudioFile${i}`] = taskContent[`explanationAudioFile${i}`] ? taskContent[`explanationAudioFile${i}`] : null
    }
    if (taskContent[`explanationAudioFile1`] || taskContent[`explanationAudioFile2`] || taskContent[`explanationAudioFile3`]) {
        test['hasExplanationAudioFile'] = true;
    } else {
        test['hasExplanationAudioFile'] = false;
    }

    if (taskContent.timer) {
        test['timer'] = taskContent.timer
    }

    test['estimatedTime'] = serverResponse.estimated_time ? serverResponse.estimated_time : null

    return test
}

export {
    useFetchReadingTest,
    useFetchRawReadingTest,
    parseReadingTestFromRawData
}
