import {useFetchStudents} from "../services/student_service";
import React, {useState} from "react";
import {getStatuses} from "../services/assignment_service";

export const StudentStatusSelector = (props) => {
    const onFilter = props.onFilter
    const statuses = getStatuses()
    const students = useFetchStudents()
    const [selectedStudent, setSelectedStudent] = useState('')
    const [selectedStatus, setSelectedStatus] = useState('')
    const [selectedTaskType, setSelectedTaskType] = useState('')
    const taskTypes = [
        {
            id: "WRITING_TASK_2",
            name: "Writing task 2"
        },
        {
            id: 'READING_TEST',
            name: 'Reading Test'
        },
        {
            id: 'LISTENING_TEST',
            name: 'Listening test'
        },
        {
            id: 'SPEAKING_TEST',
            name: 'Speaking test'
        },
        {
            id: 'GUIDED_WRITING',
            name: 'Guided writing'
        },
        {
            id: 'SKILL_BOOST',
            name: 'Skill boost'
        },
        {
            id: 'LISTENING_PRACTICE',
            name: 'Listening practice'
        },
        {
            id: 'READING_PRACTICE',
            name: 'Reading practice'
        }
    ]
    const onSelectedStudentChanged = e => {
        e.preventDefault()

        setSelectedStudent(e.target.value)
    }
    const onSelectedStatusChanged = e => {
        e.preventDefault()

        setSelectedStatus(e.target.value)
    }
    const onSelectedTaskTypeChanged = e => {
        e.preventDefault()

        setSelectedTaskType(e.target.value)
    }
    const onFilterClicked = e => {
        e.preventDefault()

        if (onFilter) {
            onFilter(selectedStudent, selectedStatus, selectedTaskType)
        }
    }

    return (
        <div className={"row"}>
            <div className={"col-sm-3"}>
                <select className={"form-select"} onChange={onSelectedStudentChanged}>
                    <option value={""}>Select a student</option>
                    {students.map(student => (
                        <option key={student.email} value={student.email}>{student.first_name} {student.last_name}</option>
                    ))}
                </select>
            </div>

            <div className={"col-sm-3"}>
                <select className={"form-select"} onChange={onSelectedStatusChanged}>
                    <option value={""}>Select a status</option>
                    {statuses.map(status => (
                        <option key={status.value} value={status.value}>{status.label}</option>
                    ))}
                </select>
            </div>

            <div className={"col-sm-3"}>
                <select className={"form-select"} onChange={onSelectedTaskTypeChanged}>
                    <option value={""}>Select a task type</option>
                    {taskTypes.map(type => (
                        <option key={type.id} value={type.id}>{type.name}</option>
                    ))}
                </select>
            </div>

            <div className={"col-sm-3"}>
                <button className={"btn btn-primary"} onClick={onFilterClicked}>Filter</button>
            </div>
        </div>
    )
}