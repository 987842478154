import Editor from "rich-markdown-editor";
import React, {useState, useEffect, useRef} from "react";
import {useHistory, useParams} from "react-router-dom";
import {getListOfQuestions, parseQuestions} from "../../services/question_service";
import {Storage} from "aws-amplify";
import {toast} from "react-toastify";
import config from "../../config";
import {API} from "@aws-amplify/api";
import {uploadFileToS3, getTaskTypeText} from "../../services/utils";
import LoadingBar from "react-top-loading-bar";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/mode-json";

export const NewReadingTestPage = () => {
    const loadingBarRef = useRef(null);
    const file1 = useRef(null);
    const file2 = useRef(null);
    const file3 = useRef(null);
    const file4 = useRef(null);
    const file5 = useRef(null);
    const file6 = useRef(null);
    const file7 = useRef(null);
    const file8 = useRef(null);
    const file9 = useRef(null);
    const file10 = useRef(null);
    const file11 = useRef(null);
    const file12 = useRef(null);
    const file13 = useRef(null);
    const file14 = useRef(null);
    const file15 = useRef(null);
    const file16 = useRef(null);
    const file17 = useRef(null);
    const file18 = useRef(null);
    const file19 = useRef(null);
    const file20 = useRef(null);
    const file21 = useRef(null);
    const explanationAudioFile1 = useRef(null)
    const explanationAudioFile2 = useRef(null)
    const explanationAudioFile3 = useRef(null)
    const history = useHistory();
    const [book, setBook] = useState('');
    const [year, setYear] = useState('');
    const [title, setTitle] = useState('');
    const [content1, setContent1] = useState('');
    const [questions1, setQuestions1] = useState('');
    const [answers1, setAnswers1] = useState('');
    const [explanation1, setExplanation1] = useState('');
    const [otherInfo1, setOtherInfo1] = useState('');
    const [guidance1, setGuidance1] = useState('')
    const [savedGuidance1, setSavedGuidance1] = useState(null)
    const [hints1, setHints1] = useState('')
    const [savedHints1, setSavedHints1] = useState(null)
    const [savedContent1, setSavedContent1] = useState(null);
    const [savedQuestions1, setSavedQuestions1] = useState(null);
    const [savedAnswers1, setSavedAnswers1] = useState({});
    const [content2, setContent2] = useState('');
    const [questions2, setQuestions2] = useState('');
    const [answers2, setAnswers2] = useState('');
    const [explanation2, setExplanation2] = useState('');
    const [otherInfo2, setOtherInfo2] = useState('');
    const [guidance2, setGuidance2] = useState('')
    const [savedGuidance2, setSavedGuidance2] = useState(null)
    const [hints2, setHints2] = useState('')
    const [savedHints2, setSavedHints2] = useState(null)
    const [savedContent2, setSavedContent2] = useState(null);
    const [savedQuestions2, setSavedQuestions2] = useState(null);
    const [savedAnswers2, setSavedAnswers2] = useState({});
    const [content3, setContent3] = useState('');
    const [questions3, setQuestions3] = useState('');
    const [answers3, setAnswers3] = useState('');
    const [explanation3, setExplanation3] = useState('');
    const [otherInfo3, setOtherInfo3] = useState('');
    const [guidance3, setGuidance3] = useState('')
    const [savedGuidance3, setSavedGuidance3] = useState(null)
    const [hints3, setHints3] = useState('')
    const [savedHints3, setSavedHints3] = useState(null)
    const [savedContent3, setSavedContent3] = useState(null);
    const [savedQuestions3, setSavedQuestions3] = useState(null);
    const [savedAnswers3, setSavedAnswers3] = useState({});
    const [content4, setContent4] = useState('');
    const [questions4, setQuestions4] = useState('');
    const [answers4, setAnswers4] = useState('');
    const [explanation4, setExplanation4] = useState('');
    const [otherInfo4, setOtherInfo4] = useState('');
    const [guidance4, setGuidance4] = useState('')
    const [savedGuidance4, setSavedGuidance4] = useState(null)
    const [hints4, setHints4] = useState('')
    const [savedHints4, setSavedHints4] = useState(null)
    const [savedContent4, setSavedContent4] = useState(null);
    const [savedQuestions4, setSavedQuestions4] = useState(null);
    const [savedAnswers4, setSavedAnswers4] = useState({});
    const [s3FileName, setS3FileName] = useState(null);
    const [file1Name, setFile1Name] = useState(null);
    const [file2Name, setFile2Name] = useState(null);
    const [file3Name, setFile3Name] = useState(null);
    const [file4Name, setFile4Name] = useState(null);
    const [file5Name, setFile5Name] = useState(null);
    const [file6Name, setFile6Name] = useState(null);
    const [file7Name, setFile7Name] = useState(null);
    const [file8Name, setFile8Name] = useState(null);
    const [file9Name, setFile9Name] = useState(null);
    const [file10Name, setFile10Name] = useState(null);
    const [file11Name, setFile11Name] = useState(null);
    const [file12Name, setFile12Name] = useState(null);
    const [file13Name, setFile13Name] = useState(null);
    const [file14Name, setFile14Name] = useState(null);
    const [file15Name, setFile15Name] = useState(null);
    const [file16Name, setFile16Name] = useState(null);
    const [file17Name, setFile17Name] = useState(null);
    const [file18Name, setFile18Name] = useState(null);
    const [file19Name, setFile19Name] = useState(null);
    const [file20Name, setFile20Name] = useState(null);
    const [file21Name, setFile21Name] = useState(null);
    const [explanationAudioFile1Name, setExplanationAudioFile1Name] = useState(null);
    const [explanationAudioFile2Name, setExplanationAudioFile2Name] = useState(null);
    const [explanationAudioFile3Name, setExplanationAudioFile3Name] = useState(null);

    const [isFile1Uploading, setIsFile1Uploading] = useState(false);
    const [isFile2Uploading, setIsFile2Uploading] = useState(false);
    const [isFile3Uploading, setIsFile3Uploading] = useState(false);
    const [isFile4Uploading, setIsFile4Uploading] = useState(false);
    const [isFile5Uploading, setIsFile5Uploading] = useState(false);
    const [isFile6Uploading, setIsFile6Uploading] = useState(false);
    const [isFile7Uploading, setIsFile7Uploading] = useState(false);
    const [isFile8Uploading, setIsFile8Uploading] = useState(false);
    const [isFile9Uploading, setIsFile9Uploading] = useState(false);
    const [isFile10Uploading, setIsFile10Uploading] = useState(false);
    const [isFile11Uploading, setIsFile11Uploading] = useState(false);
    const [isFile12Uploading, setIsFile12Uploading] = useState(false);
    const [isFile13Uploading, setIsFile13Uploading] = useState(false);
    const [isFile14Uploading, setIsFile14Uploading] = useState(false);
    const [isFile15Uploading, setIsFile15Uploading] = useState(false);
    const [isFile16Uploading, setIsFile16Uploading] = useState(false);
    const [isFile17Uploading, setIsFile17Uploading] = useState(false);
    const [isFile18Uploading, setIsFile18Uploading] = useState(false);
    const [isFile19Uploading, setIsFile19Uploading] = useState(false);
    const [isFile20Uploading, setIsFile20Uploading] = useState(false);
    const [isFile21Uploading, setIsFile21Uploading] = useState(false);
    const [isExplanationAudioFile1Uploading, setIsExplanationAudioFile1Uploading] = useState(false);
    const [isExplanationAudioFile2Uploading, setIsExplanationAudioFile2Uploading] = useState(false);
    const [isExplanationAudioFile3Uploading, setIsExplanationAudioFile3Uploading] = useState(false);
    const [followingTaskIds, setFollowingTaskIds] = useState('')
    const [estimatedTime, setEstimatedTime] = useState('')
    const [timeForTimer, setTimeForTimer] = useState('')
    const [savedNote, setSavedNote] = useState(null)
    const [note, setNote] = useState('')

    const {taskId, taskType} = useParams();
    const taskTypeMap = {
        'reading-test': 'READING_TEST',
        'listening-test': 'LISTENING_TEST',
        'speaking-test': 'SPEAKING_TEST',
        'guided-writing': 'GUIDED_WRITING',
        'skill-boost': 'SKILL_BOOST',
        'listening-practice': 'LISTENING_PRACTICE',
        'reading-practice': 'READING_PRACTICE',
    }

    useEffect(() => {
        if (taskId) {
            const fetchData = async () => {
                loadingBarRef.current.continuousStart();
                const serverResponse = await API.get("app", `/tasks/${taskId}`, {});
                const s3Path = serverResponse.content;
                const s3Response = await Storage.get(`${config.s3.READING_TEST_PATH}/${s3Path}`, {
                    level: 'public',
                    download: true,
                    cacheControl: 'no-cache',
                    contentType: 'application/json'
                });
                const rawTaskContent = await s3Response.Body.text();
                const taskContent = JSON.parse(rawTaskContent);

                setBook(serverResponse.book);
                setYear(serverResponse.year);
                setTitle(serverResponse.title);
                setS3FileName(serverResponse.content);

                setContent1(taskContent.passage1.content);
                setSavedContent1(taskContent.passage1.content);
                setQuestions1(taskContent.passage1.questions);
                setSavedQuestions1(taskContent.passage1.questions);
                setSavedAnswers1(taskContent.passage1.answers);
                setExplanation1(taskContent.passage1.explanation ? taskContent.passage1.explanation : '');
                setOtherInfo1(taskContent.passage1.otherInfo ? taskContent.passage1.otherInfo : '');
                setGuidance1(taskContent.passage1.guidance ? taskContent.passage1.guidance : '')
                setSavedGuidance1(taskContent.passage1.guidance ? taskContent.passage1.guidance : '')
                setHints1(taskContent.passage1.hints ? taskContent.passage1.hints : '')
                setSavedHints1(taskContent.passage1.hints ? taskContent.passage1.hints : '')

                setContent2(taskContent.passage2.content);
                setSavedContent2(taskContent.passage2.content);
                setQuestions2(taskContent.passage2.questions);
                setSavedQuestions2(taskContent.passage2.questions);
                setSavedAnswers2(taskContent.passage2.answers);
                setExplanation2(taskContent.passage2.explanation ? taskContent.passage2.explanation : '');
                setOtherInfo2(taskContent.passage2.otherInfo ? taskContent.passage2.otherInfo : '');
                setGuidance2(taskContent.passage2.guidance ? taskContent.passage2.guidance : '')
                setSavedGuidance2(taskContent.passage2.guidance ? taskContent.passage2.guidance : '')
                setHints2(taskContent.passage2.hints ? taskContent.passage2.hints : '')
                setSavedHints2(taskContent.passage2.hints ? taskContent.passage2.hints : '')

                setContent3(taskContent.passage3.content);
                setSavedContent3(taskContent.passage3.content);
                setQuestions3(taskContent.passage3.questions);
                setSavedQuestions3(taskContent.passage3.questions);
                setSavedAnswers3(taskContent.passage3.answers);
                setExplanation3(taskContent.passage3.explanation ? taskContent.passage3.explanation : '');
                setOtherInfo3(taskContent.passage3.otherInfo ? taskContent.passage3.otherInfo : '');
                setGuidance3(taskContent.passage3.guidance ? taskContent.passage3.guidance : '')
                setSavedGuidance3(taskContent.passage3.guidance ? taskContent.passage3.guidance : '')
                setHints3(taskContent.passage3.hints ? taskContent.passage3.hints : '')
                setSavedHints3(taskContent.passage3.hints ? taskContent.passage3.hints : '')

                if (taskContent.passage4) {
                    setContent4(taskContent.passage4.content);
                    setSavedContent4(taskContent.passage4.content);
                    setQuestions4(taskContent.passage4.questions);
                    setSavedQuestions4(taskContent.passage4.questions);
                    setSavedAnswers4(taskContent.passage4.answers);
                    setExplanation4(taskContent.passage4.explanation ? taskContent.passage4.explanation : '');
                    setOtherInfo4(taskContent.passage4.otherInfo ? taskContent.passage4.otherInfo : '');
                    setGuidance4(taskContent.passage4.guidance ? taskContent.passage4.guidance : '')
                    setSavedGuidance4(taskContent.passage4.guidance ? taskContent.passage4.guidance : '')
                    setHints4(taskContent.passage4.hints ? taskContent.passage4.hints : '')
                    setSavedHints4(taskContent.passage4.hints ? taskContent.passage4.hints : '')
                }

                if (taskContent.file1) {
                    setFile1Name(taskContent.file1);
                }
                if (taskContent.file2) {
                    setFile2Name(taskContent.file2);
                }
                if (taskContent.file3) {
                    setFile3Name(taskContent.file3);
                }
                if (taskContent.file4) {
                    setFile4Name(taskContent.file4);
                }
                if (taskContent.file5) {
                    setFile5Name(taskContent.file5);
                }
                if (taskContent.file6) {
                    setFile6Name(taskContent.file6);
                }
                if (taskContent.file7) {
                    setFile7Name(taskContent.file7);
                }
                if (taskContent.file8) {
                    setFile8Name(taskContent.file8);
                }
                if (taskContent.file9) {
                    setFile9Name(taskContent.file9);
                }
                if (taskContent.file10) {
                    setFile10Name(taskContent.file10);
                }
                if (taskContent.file11) {
                    setFile11Name(taskContent.file11);
                }
                if (taskContent.file12) {
                    setFile12Name(taskContent.file12);
                }
                if (taskContent.file13) {
                    setFile13Name(taskContent.file13);
                }
                if (taskContent.file14) {
                    setFile14Name(taskContent.file14);
                }
                if (taskContent.file15) {
                    setFile15Name(taskContent.file15);
                }
                if (taskContent.file16) {
                    setFile16Name(taskContent.file16);
                }
                if (taskContent.file17) {
                    setFile17Name(taskContent.file17);
                }
                if (taskContent.file18) {
                    setFile18Name(taskContent.file18);
                }
                if (taskContent.file19) {
                    setFile19Name(taskContent.file19);
                }
                if (taskContent.file20) {
                    setFile20Name(taskContent.file20);
                }
                if (taskContent.file21) {
                    setFile21Name(taskContent.file21);
                }
                if (taskContent.explanationAudioFile1) {
                    setExplanationAudioFile1Name(taskContent.explanationAudioFile1);
                }
                if (taskContent.explanationAudioFile2) {
                    setExplanationAudioFile2Name(taskContent.explanationAudioFile2);
                }
                if (taskContent.explanationAudioFile3) {
                    setExplanationAudioFile3Name(taskContent.explanationAudioFile3);
                }
                setTimeForTimer(taskContent.timer ? taskContent.timer : '')
                setFollowingTaskIds(taskContent.following_task_ids ? taskContent.following_task_ids : '')
                setSavedNote(taskContent.note ? taskContent.note : '')
                setEstimatedTime(serverResponse.estimated_time ? serverResponse.estimated_time : '')

                loadingBarRef.current.complete();
            };

            fetchData();
        } else {
            setSavedContent1('');
            setSavedContent2('');
            setSavedContent3('');
            setSavedContent4('');
            setSavedQuestions1('');
            setSavedQuestions2('');
            setSavedQuestions3('');
            setSavedQuestions4('');
        }
    }, []);

    const prefillWithSavedAnswers = (answers) => {
        Object.keys(answers).forEach(questionNumber => {
            const element = document.querySelector(`[name='${questionNumber}']`);
            const value = answers[questionNumber];

            if (element.type !== 'radio') {
                element.value = value;
            } else {
                document.getElementById(`question-option-${questionNumber}-${value}`).checked = true;
            }
        });
    };

    const showAnswers1 = (e) => {
        e.preventDefault();

        setAnswers1(parseQuestions(questions1));

        setTimeout(() => {
            prefillWithSavedAnswers(savedAnswers1);
            setSavedAnswers1({});
        }, 1000);

    };

    const showAnswers2 = (e) => {
        e.preventDefault();

        setAnswers2(parseQuestions(questions2));
        setTimeout(() => {
            prefillWithSavedAnswers(savedAnswers2);
            setSavedAnswers2({});
        }, 1000);
    };

    const showAnswers3 = (e) => {
        e.preventDefault();

        setAnswers3(parseQuestions(questions3));
        setTimeout(() => {
            prefillWithSavedAnswers(savedAnswers3);
            setSavedAnswers3({});
        });
    };

    const showAnswers4 = (e) => {
        e.preventDefault();

        setAnswers4(parseQuestions(questions4));
        setTimeout(() => {
            prefillWithSavedAnswers(savedAnswers4);
            setSavedAnswers4({});
        });
    };

    const getAnswersForPassage = (passageNumber) => {
        const wrapper = `#answers_${passageNumber}`;
        let values = {};

        const inputs = document.querySelectorAll(`${wrapper} input[type=text]`);
        const radios = document.querySelectorAll(`${wrapper} input[type=radio]:checked`);
        const selects = document.querySelectorAll(`${wrapper} select`);

        [inputs, selects, radios].forEach(elements => {
            elements.forEach(item => {
                values[item.name] = item.value;
            });
        });

        return values;
    };

    const save = async (e) => {
        e.preventDefault();
        loadingBarRef.current.continuousStart();
        const answersForPassage1 = getAnswersForPassage(1);
        const answersForPassage2 = getAnswersForPassage(2);
        const answersForPassage3 = getAnswersForPassage(3);
        const answersForPassage4 = getAnswersForPassage(4);
        const questionListForPassage1 = getListOfQuestions(questions1)
        const questionListForPassage2 = getListOfQuestions(questions2)
        const questionListForPassage3 = getListOfQuestions(questions3)
        const questionListForPassage4 = getListOfQuestions(questions4)
        const questionList = {...questionListForPassage1, ...questionListForPassage2, ...questionListForPassage3, ...questionListForPassage4}

        if (file1.current && file1.current.files.length) {
            await setFile1Name(await uploadAudioFile(file1.current.files[0]));
        }

        if (file2.current && file2.current.files.length) {
            let a = await uploadAudioFile(file2.current.files[0]);
            await setFile2Name(a);
        }

        if (file3.current && file3.current.files.length) {
            await setFile3Name(await uploadAudioFile(file3.current.files[0]));
        }

        if (file4.current && file4.current.files.length) {
            await setFile4Name(await uploadAudioFile(file4.current.files[0]));
        }

        let data = {
            passage1: {
                content: content1,
                questions: questions1,
                answers: Object.keys(answersForPassage1).length > 0 ? answersForPassage1 : savedAnswers1,
                explanation: explanation1,
                otherInfo: otherInfo1,
                guidance: guidance1,
                hints: hints1
            },
            passage2: {
                content: content2,
                questions: questions2,
                answers: Object.keys(answersForPassage2).length > 0 ? answersForPassage2 : savedAnswers2,
                explanation: explanation2,
                otherInfo: otherInfo2,
                guidance: guidance2,
                hints: hints2
            },
            passage3: {
                content: content3,
                questions: questions3,
                answers: Object.keys(answersForPassage3).length > 0 ? answersForPassage3 : savedAnswers3,
                explanation: explanation3,
                otherInfo: otherInfo3,
                guidance: guidance3,
                hints: hints3
            },
            passage4: {
                content: content4,
                questions: questions4,
                answers: Object.keys(answersForPassage4).length > 0 ? answersForPassage4 : savedAnswers4,
                explanation: explanation4,
                otherInfo: otherInfo4,
                guidance: guidance4,
                hints: hints4
            },
            file1: file1Name,
            file2: file2Name,
            file3: file3Name,
            file4: file4Name,
            file5: file5Name,
            file6: file6Name,
            file7: file7Name,
            file8: file8Name,
            file9: file9Name,
            file10: file10Name,
            file11: file11Name,
            file12: file12Name,
            file13: file13Name,
            file14: file14Name,
            file15: file15Name,
            file16: file16Name,
            file17: file17Name,
            file18: file18Name,
            file19: file19Name,
            file20: file20Name,
            file21: file21Name,
            explanationAudioFile1: explanationAudioFile1Name,
            explanationAudioFile2: explanationAudioFile2Name,
            explanationAudioFile3: explanationAudioFile3Name,
        };

        if (timeForTimer) {
            data['timer'] = parseInt(timeForTimer)
        }

        if (note) {
            data['note'] = note
        }

        if (Object.keys(questionList).length) {
            data.questionList = questionList
        }

        let fileName = s3FileName;
        if (!fileName) {
            fileName = `${Date.now()}.json`;
        }

        const filePath = `${config.s3.READING_TEST_PATH}/${fileName}`;

        try {
            await Storage.put(
                filePath,
                JSON.stringify(data),
                {
                    level: 'public',
                    contentType: 'application/json'
                }
            );
        } catch (e) {
            toast(`Failed uploading to S3: ${e.toString()}`);
        }

        try {
            let body = {
                id: taskId ? taskId : null,
                task_type: taskTypeMap[taskType],
                content: fileName,
                title,
                book,
                year,
                following_task_ids: followingTaskIds ? followingTaskIds : null,
                estimated_time: estimatedTime ? estimatedTime : null
            }
            const response = await API.put("app", "/tasks", {
                body
            });

            if (!taskId) {
                toast('Created successfully');
            } else {
                toast('Updated successfully');
            }
            loadingBarRef.current.complete();

            history.push(`/tasks/${taskType}/${response.task_id}`);
        } catch (e) {
            toast(e.toString());
            loadingBarRef.current.complete();
        }
    };

    const uploadFile = async (file) => {
        const path = config.s3.LESSON_IMAGES_PATH;

        return uploadFileToS3(file, path);
    };

    const uploadAudioFile = async (file) => {
        const path = config.s3.LESSON_FILES_PATH;

        return uploadFileToS3(file, path);
    };

    const uploadFile1 = async () => {
        setIsFile1Uploading(true);
        try {
            setFile1Name(await uploadAudioFile(file1.current.files[0]));
        } catch (e) {
            toast(`Failed uploading file 1 to S3: ${e.toString()}. Please try again.`);
        } finally {
            setIsFile1Uploading(false);
        }
    }

    const uploadFile2 = async () => {
        setIsFile2Uploading(true);
        try {
            setFile2Name(await uploadAudioFile(file2.current.files[0]));
        } catch (e) {
            toast(`Failed uploading file 2 to S3: ${e.toString()}. Please try again.`);
        } finally {
            setIsFile2Uploading(false);
        }
    }

    const uploadFile3 = async () => {
        setIsFile3Uploading(true);
        try {
            setFile3Name(await uploadAudioFile(file3.current.files[0]));
        } catch (e) {
            toast(`Failed uploading file 3 to S3: ${e.toString()}. Please try again.`);
        } finally {
            setIsFile3Uploading(false);
        }
    }

    const uploadFile4 = async () => {
        setIsFile4Uploading(true);
        try {
            setFile4Name(await uploadAudioFile(file4.current.files[0]));
        } catch (e) {
            toast(`Failed uploading file 4 to S3: ${e.toString()}. Please try again.`);
        } finally {
            setIsFile4Uploading(false);
        }
    }

    const uploadFile5 = async () => {
        setIsFile5Uploading(true);
        try {
            setFile5Name(await uploadAudioFile(file5.current.files[0]));
        } catch (e) {
            toast(`Failed uploading file 5 to S3: ${e.toString()}. Please try again.`);
        } finally {
            setIsFile5Uploading(false);
        }
    }

    const uploadFile6 = async () => {
        setIsFile6Uploading(true);
        try {
            setFile6Name(await uploadAudioFile(file6.current.files[0]));
        } catch (e) {
            toast(`Failed uploading file 6 to S3: ${e.toString()}. Please try again.`);
        } finally {
            setIsFile6Uploading(false);
        }
    }

    const uploadFile7 = async () => {
        setIsFile7Uploading(true);
        try {
            setFile7Name(await uploadAudioFile(file7.current.files[0]));
        } catch (e) {
            toast(`Failed uploading file 7 to S3: ${e.toString()}. Please try again.`);
        } finally {
            setIsFile7Uploading(false);
        }
    }

    const uploadFile8 = async () => {
        setIsFile8Uploading(true);
        try {
            setFile8Name(await uploadAudioFile(file8.current.files[0]));
        } catch (e) {
            toast(`Failed uploading file 8 to S3: ${e.toString()}. Please try again.`);
        } finally {
            setIsFile8Uploading(false);
        }
    }

    const uploadFile9 = async () => {
        setIsFile9Uploading(true);
        try {
            setFile9Name(await uploadAudioFile(file9.current.files[0]));
        } catch (e) {
            toast(`Failed uploading file 9 to S3: ${e.toString()}. Please try again.`);
        } finally {
            setIsFile9Uploading(false);
        }
    }

    const uploadFile10 = async () => {
        setIsFile10Uploading(true);
        try {
            setFile10Name(await uploadAudioFile(file10.current.files[0]));
        } catch (e) {
            toast(`Failed uploading file 10 to S3: ${e.toString()}. Please try again.`);
        } finally {
            setIsFile10Uploading(false);
        }
    }

    const uploadFile11 = async () => {
        setIsFile11Uploading(true);
        try {
            setFile11Name(await uploadAudioFile(file11.current.files[0]));
        } catch (e) {
            toast(`Failed uploading file 11 to S3: ${e.toString()}. Please try again.`);
        } finally {
            setIsFile11Uploading(false);
        }
    }

    const uploadFile12 = async () => {
        setIsFile12Uploading(true);
        try {
            setFile12Name(await uploadAudioFile(file12.current.files[0]));
        } catch (e) {
            toast(`Failed uploading file 12 to S3: ${e.toString()}. Please try again.`);
        } finally {
            setIsFile12Uploading(false);
        }
    }

    const uploadFile13 = async () => {
        setIsFile13Uploading(true);
        try {
            setFile13Name(await uploadAudioFile(file13.current.files[0]));
        } catch (e) {
            toast(`Failed uploading file 13 to S3: ${e.toString()}. Please try again.`);
        } finally {
            setIsFile13Uploading(false);
        }
    }

    const uploadFile14 = async () => {
        setIsFile14Uploading(true);
        try {
            setFile14Name(await uploadAudioFile(file14.current.files[0]));
        } catch (e) {
            toast(`Failed uploading file 14 to S3: ${e.toString()}. Please try again.`);
        } finally {
            setIsFile14Uploading(false);
        }
    }

    const uploadFile15 = async () => {
        setIsFile15Uploading(true);
        try {
            setFile15Name(await uploadAudioFile(file15.current.files[0]));
        } catch (e) {
            toast(`Failed uploading file 15 to S3: ${e.toString()}. Please try again.`);
        } finally {
            setIsFile15Uploading(false);
        }
    }

    const uploadFile16 = async () => {
        setIsFile16Uploading(true);
        try {
            setFile16Name(await uploadAudioFile(file16.current.files[0]));
        } catch (e) {
            toast(`Failed uploading file 16 to S3: ${e.toString()}. Please try again.`);
        } finally {
            setIsFile16Uploading(false);
        }
    }

    const uploadFile17 = async () => {
        setIsFile17Uploading(true);
        try {
            setFile17Name(await uploadAudioFile(file17.current.files[0]));
        } catch (e) {
            toast(`Failed uploading file 17 to S3: ${e.toString()}. Please try again.`);
        } finally {
            setIsFile17Uploading(false);
        }
    }

    const uploadFile18 = async () => {
        setIsFile18Uploading(true);
        try {
            setFile18Name(await uploadAudioFile(file18.current.files[0]));
        } catch (e) {
            toast(`Failed uploading file 18 to S3: ${e.toString()}. Please try again.`);
        } finally {
            setIsFile18Uploading(false);
        }
    }

    const uploadFile19 = async () => {
        setIsFile19Uploading(true);
        try {
            setFile19Name(await uploadAudioFile(file19.current.files[0]));
        } catch (e) {
            toast(`Failed uploading file 19 to S3: ${e.toString()}. Please try again.`);
        } finally {
            setIsFile19Uploading(false);
        }
    }

    const uploadFile20 = async () => {
        setIsFile20Uploading(true);
        try {
            setFile20Name(await uploadAudioFile(file20.current.files[0]));
        } catch (e) {
            toast(`Failed uploading file 20 to S3: ${e.toString()}. Please try again.`);
        } finally {
            setIsFile20Uploading(false);
        }
    }

    const uploadFile21 = async () => {
        setIsFile21Uploading(true);
        try {
            setFile21Name(await uploadAudioFile(file21.current.files[0]));
        } catch (e) {
            toast(`Failed uploading file 21 to S3: ${e.toString()}. Please try again.`);
        } finally {
            setIsFile21Uploading(false);
        }
    }

    const uploadExplanationAudioFile1 = async () => {
        setIsExplanationAudioFile1Uploading(true);
        try {
            setExplanationAudioFile1Name(await uploadAudioFile(explanationAudioFile1.current.files[0]));
        } catch (e) {
            toast(`Failed uploading file 1 to S3: ${e.toString()}. Please try again.`);
        } finally {
            setIsExplanationAudioFile1Uploading(false);
        }
    }

    const uploadExplanationAudioFile2 = async () => {
        setIsExplanationAudioFile2Uploading(true);
        try {
            setExplanationAudioFile2Name(await uploadAudioFile(explanationAudioFile2.current.files[0]));
        } catch (e) {
            toast(`Failed uploading file 2 to S3: ${e.toString()}. Please try again.`);
        } finally {
            setIsExplanationAudioFile2Uploading(false);
        }
    }

    const uploadExplanationAudioFile3 = async () => {
        setIsExplanationAudioFile3Uploading(true);
        try {
            setExplanationAudioFile3Name(await uploadAudioFile(explanationAudioFile3.current.files[0]));
        } catch (e) {
            toast(`Failed uploading file 3 to S3: ${e.toString()}. Please try again.`);
        } finally {
            setIsExplanationAudioFile3Uploading(false);
        }
    }

    const removeFile = async (e, filePath, callback) => {
        e.preventDefault();

        if (window.confirm('Are you sure you want to remove this file?')) {
            await Storage.remove('lessons/files/1638603096530-Listening_test1_section1.mp3');
            callback()
        }
    }

    return (
        <div className={'container-fluid'}>
            <LoadingBar color={"#0d6efd"} ref={loadingBarRef}/>
            {taskId && (
                <h1 className={"color"}>Update {getTaskTypeText(taskType)}</h1>
            )}
            {!taskId && (
                <h1 className={"color"}>Add a new {getTaskTypeText(taskType)}</h1>
            )}
            <form className={"row"} onSubmit={save} autoComplete={"off"}>
                <div className={"col-md-6 mb-3"}>
                    <label htmlFor={"book"} className={"form-label"}>Book</label>
                    <input id={"book"} type={"text"} className={"form-control"} value={book} onChange={e => setBook(e.target.value)}/>
                </div>
                <div className={"col-md-6 mb-3"}>
                    <label htmlFor={"year"} className={"form-label"}>Year</label>
                    <input id={"year"} type={"text"} className={"form-control"} value={year} onChange={e => setYear(e.target.value)}/>
                </div>
                <div className={"col-md-6 mb-3"}>
                    <label htmlFor={"title"} className={"form-label"}>Title</label>
                    <input required={true} id={"title"} type={"text"} className={"form-control"} value={title} onChange={e => setTitle(e.target.value)}/>
                </div>
                <div className={"col-md-6 mb-3"}>
                    <label htmlFor={"following-task-id"} className={"form-label"}>Timer (in minutes)</label>
                    <input type={"number"} className={"form-control"} value={timeForTimer} onChange={e => setTimeForTimer(e.target.value)}/>
                </div>

                <div className="accordion mb-3" id="accordion-passage-wrapper">
                    <label className={"form-label"}>Content</label>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne" aria-controls="collapseOne">
                                Passage 1
                            </button>
                        </h2>

                        <div id="collapseOne" className="accordion-collapse collapse"
                             aria-labelledby="headingOne" data-bs-parent="#accordion-passage-wrapper">
                            <div className="accordion-body">
                                <ul className="nav nav-tabs mb-3" role={"tablist"}>
                                    <li className="nav-item" role={"presentation"}>
                                        <a href={"#"} className="nav-link active" aria-current="page" data-bs-toggle={"tab"} data-bs-target="#content_1" type={"link"}>Content</a>
                                    </li>
                                    <li className="nav-item" role={"presentation"}>
                                        <a href={"#"} className="nav-link" data-bs-toggle={"tab"} data-bs-target="#questions_1" type={"link"}>Questions</a>
                                    </li>
                                    <li className="nav-item" role={"presentation"}>
                                        <a href={"#"} className="nav-link" data-bs-toggle={"tab"} data-bs-target="#answers_1" type={"link"} onClick={showAnswers1}>Answers</a>
                                    </li>
                                    <li className="nav-item" role={"presentation"}>
                                        <a href={"#"} className="nav-link" data-bs-toggle={"tab"} data-bs-target="#explanation_1" type={"link"}>Explanation</a>
                                    </li>
                                    <li className="nav-item" role={"presentation"}>
                                        <a href={"#"} className="nav-link" data-bs-toggle={"tab"} data-bs-target="#other_info_1" type={"link"}>Script</a>
                                    </li>
                                    <li className="nav-item" role={"presentation"}>
                                        <a href={"#"} className="nav-link" data-bs-toggle={"tab"} data-bs-target="#guidance1" type={"link"}>Guidance</a>
                                    </li>
                                    <li className="nav-item" role={"presentation"}>
                                        <a href={"#"} className="nav-link" data-bs-toggle={"tab"} data-bs-target="#hints1" type={"link"}>Hints</a>
                                    </li>
                                </ul>

                                <div className={"tab-content"}>
                                    <div id={"content_1"} className={"tab-pane fade show active"} role={"tabpanel"}>
                                        {savedContent1 !== null && (
                                            <Editor className={"form-control"} placeholder="Content goes here" defaultValue={savedContent1} onChange={value => setContent1(value())}
                                                    uploadImage={uploadFile} />
                                        )}
                                    </div>
                                    <div id={"questions_1"} className={"tab-pane fade show"} role={"tabpanel"}>
                                        {savedQuestions1 !== null && (
                                            <Editor className={"form-control"} placeholder="Questions goes here" defaultValue={savedQuestions1} onChange={value => setQuestions1(value())}
                                                    uploadImage={uploadFile} />
                                        )}
                                    </div>
                                    <div id={"answers_1"} className={"tab-pane fade show"} role={"tabpanel"} dangerouslySetInnerHTML={{__html: answers1}} />
                                    <div id={"explanation_1"} className={"tab-pane fade show"} role={"tabpanel"}>
                                        <AceEditor mode={"json"} theme={"github"} name={"explanation1"} setOptions={{useWorker: false}} editorProps={{ $blockScrolling: true }} value={explanation1} onChange={newValue => setExplanation1(newValue)} minLines={20} maxLines={40} />
                                    </div>
                                    <div id={"other_info_1"} className={"tab-pane fade show"} role={"tabpanel"}>
                                        <textarea rows={8} className={"form-control"} placeholder={""} value={otherInfo1} onChange={e => setOtherInfo1(e.target.value)}/>
                                    </div>
                                    <div id={"guidance1"} className={"tab-pane fade show"} role={"tabpanel"}>
                                        {savedGuidance1 !== null && (
                                            <Editor className={"form-control"} placeholder="Guidance goes here" defaultValue={savedGuidance1} onChange={value => setGuidance1(value())}
                                                    uploadImage={uploadFile} />
                                        )}
                                    </div>
                                    <div id={"hints1"} className={"tab-pane fade show"} role={"tabpanel"}>
                                        {savedHints1 !== null && (
                                            <Editor className={"form-control"} placeholder="Hints go here" defaultValue={savedHints1} onChange={value => setHints1(value())}
                                                    uploadImage={uploadFile} />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="accordion-item">
                        <h2 className="accordion-header" id="heading2">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapse2" aria-controls="collapse2">
                                Passage 2
                            </button>
                        </h2>

                        <div id="collapse2" className="accordion-collapse collapse"
                             aria-labelledby="heading2" data-bs-parent="#accordion-passage-wrapper">
                            <div className="accordion-body">
                                <ul className="nav nav-tabs mb-3" role={"tablist"}>
                                    <li className="nav-item" role={"presentation"}>
                                        <a href={"#"} className="nav-link active" aria-current="page" data-bs-toggle={"tab"} data-bs-target="#content_2" type={"link"}>Content</a>
                                    </li>
                                    <li className="nav-item" role={"presentation"}>
                                        <a href={"#"} className="nav-link" data-bs-toggle={"tab"} data-bs-target="#questions_2" type={"link"}>Questions</a>
                                    </li>
                                    <li className="nav-item" role={"presentation"}>
                                        <a href={"#"} className="nav-link" data-bs-toggle={"tab"} data-bs-target="#answers_2" type={"link"} onClick={showAnswers2}>Answers</a>
                                    </li>
                                    <li className="nav-item" role={"presentation"}>
                                        <a href={"#"} className="nav-link" data-bs-toggle={"tab"} data-bs-target="#explanation_2" type={"link"}>Explanation</a>
                                    </li>
                                    <li className="nav-item" role={"presentation"}>
                                        <a href={"#"} className="nav-link" data-bs-toggle={"tab"} data-bs-target="#other_info_2" type={"link"}>Script</a>
                                    </li>
                                    <li className="nav-item" role={"presentation"}>
                                        <a href={"#"} className="nav-link" data-bs-toggle={"tab"} data-bs-target="#guidance2" type={"link"}>Guidance</a>
                                    </li>
                                    <li className="nav-item" role={"presentation"}>
                                        <a href={"#"} className="nav-link" data-bs-toggle={"tab"} data-bs-target="#hints2" type={"link"}>Hints</a>
                                    </li>
                                </ul>

                                <div className={"tab-content"}>
                                    <div id={"content_2"} className={"tab-pane fade show active"} role={"tabpanel"}>
                                        {savedContent2 !== null && (
                                            <Editor className={"form-control"} placeholder="Content goes here" defaultValue={savedContent2} onChange={value => setContent2(value())}
                                                    uploadImage={uploadFile}/>
                                        )}
                                    </div>
                                    <div id={"questions_2"} className={"tab-pane fade show"} role={"tabpanel"}>
                                        {savedQuestions2 !== null && (
                                            <Editor className={"form-control"} placeholder="Questions goes here" defaultValue={savedQuestions2} onChange={value => setQuestions2(value())}
                                                    uploadImage={uploadFile}/>
                                        )}
                                    </div>
                                    <div id={"answers_2"} className={"tab-pane fade show"} role={"tabpanel"} dangerouslySetInnerHTML={{__html: answers2}} />
                                    <div id={"explanation_2"} className={"tab-pane fade show"} role={"tabpanel"}>
                                        <AceEditor mode={"json"} theme={"github"} name={"explanation2"} setOptions={{useWorker: false}} editorProps={{ $blockScrolling: true }} value={explanation2} onChange={newValue => setExplanation2(newValue)} minLines={20} maxLines={40} />
                                    </div>
                                    <div id={"other_info_2"} className={"tab-pane fade show"} role={"tabpanel"}>
                                        <textarea rows={8} className={"form-control"} placeholder={""} value={otherInfo2} onChange={e => setOtherInfo2(e.target.value)}/>
                                    </div>
                                    <div id={"guidance2"} className={"tab-pane fade show"} role={"tabpanel"}>
                                        {savedGuidance2 !== null && (
                                            <Editor className={"form-control"} placeholder="Guidance goes here" defaultValue={savedGuidance2} onChange={value => setGuidance2(value())}
                                                    uploadImage={uploadFile} />
                                        )}
                                    </div>
                                    <div id={"hints2"} className={"tab-pane fade show"} role={"tabpanel"}>
                                        {savedHints2 !== null && (
                                            <Editor className={"form-control"} placeholder="Hints go here" defaultValue={savedHints2} onChange={value => setHints2(value())}
                                                    uploadImage={uploadFile} />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="accordion-item">
                        <h2 className="accordion-header" id="heading3">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapse3" aria-controls="collapse3">
                                Passage 3
                            </button>
                        </h2>

                        <div id="collapse3" className="accordion-collapse collapse"
                             aria-labelledby="heading3" data-bs-parent="#accordion-passage-wrapper">
                            <div className="accordion-body">
                                <ul className="nav nav-tabs mb-3" role={"tablist"}>
                                    <li className="nav-item" role={"presentation"}>
                                        <a href={"#"} className="nav-link active" aria-current="page" data-bs-toggle={"tab"} data-bs-target="#content_3" type={"link"}>Content</a>
                                    </li>
                                    <li className="nav-item" role={"presentation"}>
                                        <a href={"#"} className="nav-link" data-bs-toggle={"tab"} data-bs-target="#questions_3" type={"link"}>Questions</a>
                                    </li>
                                    <li className="nav-item" role={"presentation"}>
                                        <a href={"#"} className="nav-link" data-bs-toggle={"tab"} data-bs-target="#answers_3" type={"link"} onClick={showAnswers3}>Answers</a>
                                    </li>
                                    <li className="nav-item" role={"presentation"}>
                                        <a href={"#"} className="nav-link" data-bs-toggle={"tab"} data-bs-target="#explanation_3" type={"link"}>Explanation</a>
                                    </li>
                                    <li className="nav-item" role={"presentation"}>
                                        <a href={"#"} className="nav-link" data-bs-toggle={"tab"} data-bs-target="#other_info_3" type={"link"}>Script</a>
                                    </li>
                                    <li className="nav-item" role={"presentation"}>
                                        <a href={"#"} className="nav-link" data-bs-toggle={"tab"} data-bs-target="#guidance3" type={"link"}>Guidance</a>
                                    </li>
                                    <li className="nav-item" role={"presentation"}>
                                        <a href={"#"} className="nav-link" data-bs-toggle={"tab"} data-bs-target="#hints3" type={"link"}>Hints</a>
                                    </li>
                                </ul>

                                <div className={"tab-content"}>
                                    <div id={"content_3"} className={"tab-pane fade show active"} role={"tabpanel"}>
                                        {savedContent3 !== null && (
                                            <Editor className={"form-control"} placeholder="Content goes here" defaultValue={savedContent3} onChange={value => setContent3(value())}
                                                    uploadImage={uploadFile}/>
                                        )}
                                    </div>
                                    <div id={"questions_3"} className={"tab-pane fade show"} role={"tabpanel"}>
                                        {savedQuestions3 !== null && (
                                            <Editor className={"form-control"} placeholder="Questions goes here" defaultValue={savedQuestions3} onChange={value => setQuestions3(value())}
                                                    uploadImage={uploadFile}/>
                                        )}
                                    </div>
                                    <div id={"answers_3"} className={"tab-pane fade show"} role={"tabpanel"} dangerouslySetInnerHTML={{__html: answers3}} />
                                    <div id={"explanation_3"} className={"tab-pane fade show"} role={"tabpanel"}>
                                        <AceEditor mode={"json"} theme={"github"} name={"explanation3"} setOptions={{useWorker: false}} editorProps={{ $blockScrolling: true }} value={explanation3} onChange={newValue => setExplanation3(newValue)} minLines={20} maxLines={40} />
                                    </div>
                                    <div id={"other_info_3"} className={"tab-pane fade show"} role={"tabpanel"}>
                                        <textarea rows={8} className={"form-control"} placeholder={""} value={otherInfo3} onChange={e => setOtherInfo3(e.target.value)}/>
                                    </div>
                                    <div id={"guidance3"} className={"tab-pane fade show"} role={"tabpanel"}>
                                        {savedGuidance3 !== null && (
                                            <Editor className={"form-control"} placeholder="Guidance goes here" defaultValue={savedGuidance3} onChange={value => setGuidance3(value())}
                                                    uploadImage={uploadFile} />
                                        )}
                                    </div>
                                    <div id={"hints3"} className={"tab-pane fade show"} role={"tabpanel"}>
                                        {savedHints3 !== null && (
                                            <Editor className={"form-control"} placeholder="Hints go here" defaultValue={savedHints3} onChange={value => setHints3(value())}
                                                    uploadImage={uploadFile} />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="accordion-item">
                        <h2 className="accordion-header" id="heading4">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapse4" aria-controls="collapse3">
                                Passage 4
                            </button>
                        </h2>

                        <div id="collapse4" className="accordion-collapse collapse"
                             aria-labelledby="heading4" data-bs-parent="#accordion-passage-wrapper">
                            <div className="accordion-body">
                                <ul className="nav nav-tabs mb-3" role={"tablist"}>
                                    <li className="nav-item" role={"presentation"}>
                                        <a href={"#"} className="nav-link active" aria-current="page" data-bs-toggle={"tab"} data-bs-target="#content_4" type={"link"}>Content</a>
                                    </li>
                                    <li className="nav-item" role={"presentation"}>
                                        <a href={"#"} className="nav-link" data-bs-toggle={"tab"} data-bs-target="#questions_4" type={"link"}>Questions</a>
                                    </li>
                                    <li className="nav-item" role={"presentation"}>
                                        <a href={"#"} className="nav-link" data-bs-toggle={"tab"} data-bs-target="#answers_4" type={"link"} onClick={showAnswers4}>Answers</a>
                                    </li>
                                    <li className="nav-item" role={"presentation"}>
                                        <a href={"#"} className="nav-link" data-bs-toggle={"tab"} data-bs-target="#explanation_4" type={"link"}>Explanation</a>
                                    </li>
                                    <li className="nav-item" role={"presentation"}>
                                        <a href={"#"} className="nav-link" data-bs-toggle={"tab"} data-bs-target="#other_info_4" type={"link"}>Script</a>
                                    </li>
                                    <li className="nav-item" role={"presentation"}>
                                        <a href={"#"} className="nav-link" data-bs-toggle={"tab"} data-bs-target="#guidance4" type={"link"}>Guidance</a>
                                    </li>
                                    <li className="nav-item" role={"presentation"}>
                                        <a href={"#"} className="nav-link" data-bs-toggle={"tab"} data-bs-target="#hints4" type={"link"}>Hints</a>
                                    </li>
                                </ul>

                                <div className={"tab-content"}>
                                    <div id={"content_4"} className={"tab-pane fade show active"} role={"tabpanel"}>
                                        {savedContent4 !== null && (
                                            <Editor className={"form-control"} placeholder="Content goes here" defaultValue={savedContent4} onChange={value => setContent4(value())}
                                                    uploadImage={uploadFile}/>
                                        )}
                                    </div>
                                    <div id={"questions_4"} className={"tab-pane fade show"} role={"tabpanel"}>
                                        {savedQuestions4 !== null && (
                                            <Editor className={"form-control"} placeholder="Questions goes here" defaultValue={savedQuestions4} onChange={value => setQuestions4(value())}
                                                    uploadImage={uploadFile}/>
                                        )}
                                    </div>
                                    <div id={"answers_4"} className={"tab-pane fade show"} role={"tabpanel"} dangerouslySetInnerHTML={{__html: answers4}} />
                                    <div id={"explanation_4"} className={"tab-pane fade show"} role={"tabpanel"}>
                                        <AceEditor mode={"json"} theme={"github"} name={"explanation4"} setOptions={{useWorker: false}} editorProps={{ $blockScrolling: true }} value={explanation4} onChange={newValue => setExplanation4(newValue)} minLines={20} maxLines={40} />
                                    </div>
                                    <div id={"other_info_4"} className={"tab-pane fade show"} role={"tabpanel"}>
                                        <textarea rows={8} className={"form-control"} placeholder={""} value={otherInfo4} onChange={e => setOtherInfo4(e.target.value)}/>
                                    </div>
                                    <div id={"guidance4"} className={"tab-pane fade show"} role={"tabpanel"}>
                                        {savedGuidance4 !== null && (
                                            <Editor className={"form-control"} placeholder="Guidance goes here" defaultValue={savedGuidance4} onChange={value => setGuidance4(value())}
                                                    uploadImage={uploadFile} />
                                        )}
                                    </div>
                                    <div id={"hints4"} className={"tab-pane fade show"} role={"tabpanel"}>
                                        {savedHints4 !== null && (
                                            <Editor className={"form-control"} placeholder="Hints go here" defaultValue={savedHints4} onChange={value => setHints4(value())}
                                                    uploadImage={uploadFile} />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"col-md-6 mb-3"}>
                    <div className="mb-3">
                        {!file1Name && (
                            <span>File 1 <input type="file" name="file1" ref={file1} onChange={uploadFile1}/></span>
                        )}
                        {file1Name && (
                            <span><a href={file1Name} target="_blank">File 1</a></span>
                        )}
                        {isFile1Uploading && (
                            <strong>Uploading...</strong>
                        )}
                    </div>
                    <div className="mb-3">
                        {!file2Name && (
                            <span>File 2 <input type="file" name="file2" ref={file2} onChange={uploadFile2}/></span>
                        )}
                        {file2Name && (
                            <span><a href={file2Name} target="_blank">File 2</a></span>
                        )}
                        {isFile2Uploading && (
                            <strong>Uploading...</strong>
                        )}
                    </div>
                    <div className="mb-3">
                        {!file3Name && (
                            <span>File 3 <input type="file" name="file3" ref={file3} onChange={uploadFile3}/></span>
                        )}
                        {file3Name && (
                            <span><a href={file3Name} target="_blank">File 3</a></span>
                        )}
                        {isFile3Uploading && (
                            <strong>Uploading...</strong>
                        )}
                    </div>
                    <div className="mb-3">
                        {!file4Name && (
                            <span>File 4 <input type="file" name="file4" ref={file4} onChange={uploadFile4}/></span>
                        )}
                        {file4Name && (
                            <span><a href={file4Name} target="_blank">File 4</a></span>
                        )}
                        {isFile4Uploading && (
                            <strong>Uploading...</strong>
                        )}
                    </div>
                    {taskType === 'speaking-test' && (
                        <div>
                            <div className="mb-3">
                                {!file5Name && (
                                    <span>File 5 <input type="file" name="file5" ref={file5} onChange={uploadFile5}/></span>
                                )}
                                {file5Name && (
                                    <span><a href={file5Name} target="_blank">File 5</a></span>
                                )}
                                {isFile5Uploading && (
                                    <strong>Uploading...</strong>
                                )}
                            </div>
                            <div className="mb-3">
                                {!file6Name && (
                                    <span>File 6 <input type="file" name="file6" ref={file6} onChange={uploadFile6}/></span>
                                )}
                                {file6Name && (
                                    <span><a href={file6Name} target="_blank">File 6</a></span>
                                )}
                                {isFile6Uploading && (
                                    <strong>Uploading...</strong>
                                )}
                            </div>
                            <div className="mb-3">
                                {!file7Name && (
                                    <span>File 7 <input type="file" name="file7" ref={file7} onChange={uploadFile7}/></span>
                                )}
                                {file7Name && (
                                    <span><a href={file7Name} target="_blank">File 7</a></span>
                                )}
                                {isFile7Uploading && (
                                    <strong>Uploading...</strong>
                                )}
                            </div>
                            <div className="mb-3">
                                {!file8Name && (
                                    <span>File 8 <input type="file" name="file8" ref={file8} onChange={uploadFile8}/></span>
                                )}
                                {file8Name && (
                                    <span><a href={file8Name} target="_blank">File 8</a></span>
                                )}
                                {isFile8Uploading && (
                                    <strong>Uploading...</strong>
                                )}
                            </div>
                            <div className="mb-3">
                                {!file9Name && (
                                    <span>File 9 <input type="file" name="file9" ref={file9} onChange={uploadFile9}/></span>
                                )}
                                {file9Name && (
                                    <span><a href={file9Name} target="_blank">File 9</a></span>
                                )}
                                {isFile9Uploading && (
                                    <strong>Uploading...</strong>
                                )}
                            </div>
                            <div className="mb-3">
                                {!file10Name && (
                                    <span>File 10 <input type="file" name="file10" ref={file10} onChange={uploadFile10}/></span>
                                )}
                                {file10Name && (
                                    <span><a href={file10Name} target="_blank">File 10</a></span>
                                )}
                                {isFile10Uploading && (
                                    <strong>Uploading...</strong>
                                )}
                            </div>
                            <div className="mb-3">
                                {!file11Name && (
                                    <span>File 11 <input type="file" name="file11" ref={file11} onChange={uploadFile11}/></span>
                                )}
                                {file11Name && (
                                    <span><a href={file11Name} target="_blank">File 11</a></span>
                                )}
                                {isFile11Uploading && (
                                    <strong>Uploading...</strong>
                                )}
                            </div>
                        </div>
                    )}
                </div>

                <div className={"col-md-6 mb-3"}>
                    {taskType === 'speaking-test' && (
                        <div>
                            <div className="mb-3">
                                {!file12Name && (
                                    <span>File 12 <input type="file" name="file12" ref={file12} onChange={uploadFile12}/></span>
                                )}
                                {file12Name && (
                                    <span><a href={file12Name} target="_blank">File 12</a></span>
                                )}
                                {isFile12Uploading && (
                                    <strong>Uploading...</strong>
                                )}
                            </div>
                            <div className="mb-3">
                                {!file13Name && (
                                    <span>File 13 <input type="file" name="file13" ref={file13} onChange={uploadFile13}/></span>
                                )}
                                {file13Name && (
                                    <span><a href={file13Name} target="_blank">File 13</a></span>
                                )}
                                {isFile13Uploading && (
                                    <strong>Uploading...</strong>
                                )}
                            </div>
                            <div className="mb-3">
                                {!file14Name && (
                                    <span>File 14 <input type="file" name="file14" ref={file14} onChange={uploadFile14}/></span>
                                )}
                                {file14Name && (
                                    <span><a href={file14Name} target="_blank">File 14</a></span>
                                )}
                                {isFile14Uploading && (
                                    <strong>Uploading...</strong>
                                )}
                            </div>
                            <div className="mb-3">
                                {!file15Name && (
                                    <span>File 15 <input type="file" name="file15" ref={file15} onChange={uploadFile15}/></span>
                                )}
                                {file15Name && (
                                    <span><a href={file15Name} target="_blank">File 15</a></span>
                                )}
                                {isFile15Uploading && (
                                    <strong>Uploading...</strong>
                                )}
                            </div>
                            <div className="mb-3">
                                {!file16Name && (
                                    <span>File 16 <input type="file" name="file16" ref={file16} onChange={uploadFile16}/></span>
                                )}
                                {file16Name && (
                                    <span><a href={file16Name} target="_blank">File 16</a></span>
                                )}
                                {isFile16Uploading && (
                                    <strong>Uploading...</strong>
                                )}
                            </div>
                            <div className="mb-3">
                                {!file17Name && (
                                    <span>File 17 <input type="file" name="file17" ref={file17} onChange={uploadFile17}/></span>
                                )}
                                {file17Name && (
                                    <span><a href={file17Name} target="_blank">File 17</a></span>
                                )}
                                {isFile17Uploading && (
                                    <strong>Uploading...</strong>
                                )}
                            </div>
                            <div className="mb-3">
                                {!file18Name && (
                                    <span>File 18 <input type="file" name="file18" ref={file18} onChange={uploadFile18}/></span>
                                )}
                                {file18Name && (
                                    <span><a href={file18Name} target="_blank">File 18</a></span>
                                )}
                                {isFile18Uploading && (
                                    <strong>Uploading...</strong>
                                )}
                            </div>
                            <div className="mb-3">
                                {!file19Name && (
                                    <span>File 19 <input type="file" name="file19" ref={file19} onChange={uploadFile19}/></span>
                                )}
                                {file19Name && (
                                    <span><a href={file19Name} target="_blank">File 19</a></span>
                                )}
                                {isFile19Uploading && (
                                    <strong>Uploading...</strong>
                                )}
                            </div>
                            <div className="mb-3">
                                {!file20Name && (
                                    <span>File 20 <input type="file" name="file20" ref={file20} onChange={uploadFile20}/></span>
                                )}
                                {file20Name && (
                                    <span><a href={file20Name} target="_blank">File 20</a></span>
                                )}
                                {isFile20Uploading && (
                                    <strong>Uploading...</strong>
                                )}
                            </div>
                            <div className="mb-3">
                                {!file21Name && (
                                    <span>File 21 <input type="file" name="file21" ref={file21} onChange={uploadFile21}/></span>
                                )}
                                {file21Name && (
                                    <span><a href={file21Name} target="_blank">File 21</a></span>
                                )}
                                {isFile21Uploading && (
                                    <strong>Uploading...</strong>
                                )}
                            </div>
                        </div>
                    )}
                </div>
                {taskType === 'speaking-test' && (
                    <div className={"col-md-12 mb-3"}>
                        <label className={"form-label"}>Explanation audio</label>
                        <div className="mb-3">
                            {!explanationAudioFile1Name && (
                                <span>File 1 <input type="file" name="explanationAudioFile1" ref={explanationAudioFile1} onChange={uploadExplanationAudioFile1}/></span>
                            )}
                            {explanationAudioFile1Name && (
                                <span><a href={explanationAudioFile1Name} target="_blank">File 1</a></span>
                            )}
                            {isExplanationAudioFile1Uploading && (
                                <strong>Uploading...</strong>
                            )}
                        </div>
                        <div className="mb-3">
                            {!explanationAudioFile2Name && (
                                <span>File 2 <input type="file" name="explanationAudioFile2" ref={explanationAudioFile2} onChange={uploadExplanationAudioFile2}/></span>
                            )}
                            {explanationAudioFile2Name && (
                                <span><a href={explanationAudioFile2Name} target="_blank">File 2</a></span>
                            )}
                            {isExplanationAudioFile2Uploading && (
                                <strong>Uploading...</strong>
                            )}
                        </div>
                        <div className="mb-3">
                            {!explanationAudioFile3Name && (
                                <span>File 3 <input type="file" name="explanationAudioFile3" ref={explanationAudioFile3} onChange={uploadExplanationAudioFile3}/></span>
                            )}
                            {explanationAudioFile3Name && (
                                <span><a href={explanationAudioFile3Name} target="_blank">File 3</a></span>
                            )}
                            {isExplanationAudioFile3Uploading && (
                                <strong>Uploading...</strong>
                            )}
                        </div>
                    </div>
                )}

                {savedNote !== null && (
                    <div className={"col-md-12 mb-3"}>
                        <label htmlFor={"note"} className={"form-label"}>Note</label>
                        <Editor className={"form-control"} placeholder="Note" defaultValue={savedNote} onChange={value => setNote(value())} />
                    </div>
                )}
                <div className={"col-md-6 mb-3"}>
                    <label htmlFor={"following-task-id"} className={"form-label"}>Following task's IDs (separated by commas)</label>
                    <input type={"text"} className={"form-control"} value={followingTaskIds} onChange={e => setFollowingTaskIds(e.target.value)}/>
                </div>
                <div className={"col-md-6 mb-3"}>
                    <label htmlFor={"estimated-time"} className={"form-label"}>Estimated time (in minutes)</label>
                    <input type={"number"} className={"form-control"} value={estimatedTime} onChange={e => setEstimatedTime(e.target.value)}/>
                </div>
                <div className={"buttons"}>
                    <input type={"button"} className={"btn btn-secondary"} onClick={() => {history.goBack()}} value={"Cancel"}/>
                    <input type={"submit"} className={"btn btn-primary"} value={"Save"}/>
                </div>
            </form>
        </div>
    );
};
