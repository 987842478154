import {useState, useEffect} from "react";

export const TaskTypeSelector = (props) => {
    const [type, setType] = useState('WRITING_TASK_2');
    const [category, setCategory] = useState('AGREE_DISAGREE');
    const taskTypes = [
        {
            id: "WRITING_TASK_2",
            name: "Writing task 2"
        },
        {
            id: 'READING_TEST',
            name: 'Reading Test'
        },
        {
            id: 'LISTENING_TEST',
            name: 'Listening test'
        },
        {
            id: 'SPEAKING_TEST',
            name: 'Speaking test'
        },
        {
            id: 'GUIDED_WRITING',
            name: 'Guided writing'
        },
        {
            id: 'SKILL_BOOST',
            name: 'Skill boost'
        },
        {
            id: 'LISTENING_PRACTICE',
            name: 'Listening practice'
        },
        {
            id: 'READING_PRACTICE',
            name: 'Reading practice'
        }
    ];
    const categories = [
        {
            id: '',
            name: ''
        },
        {
            id: "OPINION",
            name: "Opinion"
        },
        {
            id: "DISCUSSION",
            name: "Discussion"
        },
        {
            id: "PROBLEM_SOLUTION",
            name: "Problem - Solution"
        },
        {
            id: "CAUSE_EFFECT",
            name: "Cause - Effect"
        },
        {
            id: "ADVANTAGE_DISADVANTAGE",
            name: "Advantage - Disadvantage"
        },
        {
            id: "OTHERS",
            name: "Others"
        }
    ];

    const onTypeChanged = (newType) => {
        setType(newType);
        props.onTypeChanged(newType);
    };

    const onCategoryChanged = (newCategory) => {
        setCategory(newCategory);
        props.onCategoryChanged(newCategory);
    };

    useEffect(() => {
        onTypeChanged(type);
    }, []);

    return (
        <>
            <div className={"col-md-6 mb-3"}>
                <label htmlFor={"task-type"} className={"form-label"}>Task type</label>
                <select id={"task-type"} className={"form-select"} value={type} onChange={e => onTypeChanged(e.target.value)}>
                    {taskTypes.map(task => (
                        <option key={task.id} value={task.id}>{task.name}</option>
                    ))}
                </select>
            </div>
            <div className={"col-md-6 mb-3"}>
                {type === 'WRITING_TASK_2' && (
                    <>
                        <label htmlFor={"task-type"} className={"form-label"}>Category</label>
                        <select id={"category"} className={"form-select"} value={category} onChange={e => onCategoryChanged(e.target.value)}>
                            {categories.map(cat => (
                                <option key={cat.id} value={cat.id}>{cat.name}</option>
                            ))}
                        </select>
                    </>
                )}
            </div>
        </>
    );
};
