import {Remarkable} from "remarkable";
import {Storage} from "aws-amplify";
import config from "../config";
import {useEffect, useRef} from "react";

const markdownToHtml = text => {
    const markdown = new Remarkable('default', {html: true});
    let html = markdown.render(text);
    html = html.replaceAll("\\", '');

    return html;
};

const uploadFileToS3 = async (file, path) => {
    let fileName = `${Date.now()}-${file.name}`;
    const result = await Storage.put(
        `${path}/${fileName}`,
        file,
        {
            level: 'public',
            contentType: file.type,
            acl: 'public-read'
        }
    );

    return `${config.s3.PUBLIC_URL}/${result.key}`;
};

// Source: https://overreacted.io/making-setinterval-declarative-with-react-hooks/
const useInterval = (callback, delay) => {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

const getTaskTypeText = (taskType) => {
    const taskTypeTextMap = {
        'reading-test': 'reading test',
        'listening-test': 'listening test',
        'speaking-test': 'speaking test',
        'guided-writing': 'guided writing task',
        'skill-boost': 'skill boost task',
        'listening-practice': 'listening practice',
        'reading-practice': 'reading practice',
    }

    if (taskTypeTextMap[taskType]) {
        return taskTypeTextMap[taskType];
    }

    return '';
}

const getTaskTypeForUrl = taskType => {
    const map = {
        READING_TEST: 'reading-test',
        LISTENING_TEST: 'listening-test',
        SPEAKING_TEST: 'speaking-test',
        GUIDED_WRITING: 'guided-writing',
        SKILL_BOOST: 'skill-boost',
        LISTENING_PRACTICE: 'listening-practice',
        READING_PRACTICE: 'reading-practice'
    }

    return map[taskType] ? map[taskType] : ''
}

export {
    markdownToHtml,
    uploadFileToS3,
    useInterval,
    getTaskTypeText,
    getTaskTypeForUrl
};
