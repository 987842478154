import React from "react";
import ReactDOMServer from "react-dom/server";

const separator = '#';
const pattern = /{(\d+\s*#[^}]*)}/gm;

const transformClozeTextQuestion = (questionNumber) => {
    return `
        <span class="question-${questionNumber} cloze-text">
            <strong>${questionNumber}. </strong>
            <input name="${questionNumber}" id="answer-${questionNumber}" type="text" />
        </span>
    `;
};

const transformSingleChoiceListQuestion = (questionNumber, choices) => {
    let options = ``;

    choices.forEach((item, i) => {
        if (i === 0) {
            options += `<option value="" />`;
        } else {
            options += `<option value="${item}">${item}</option>`;
        }
    });

    return `<span><strong>${questionNumber}. </strong> ${choices[0]} <select name="${questionNumber}">${options}</select></span>`;
};

const transformSingleChoiceQuestion = (questionNumber, choices) => {
    let options = [];

    choices.forEach((item, i) => {
        const optionId = `question-option-${questionNumber}-${i}`;

        if (i !== 0) {
            options.push(`
                <div>
                    ${String.fromCharCode(64 + i)} <input type="radio" name="${questionNumber}" value="${i}" id="${optionId}" /> 
                    <label for="${optionId}">${item}</label>
                </div>
            `);
        }
    });

    return `
        <div><strong>${questionNumber}. </strong> ${choices[0]}
            ${options.join('')}  
        </div>
    `;
};

const splitQuestion = (question) => {
    const parts = question.split(separator).map(part => part.trim());
    const questionNumber = parts[0];
    let questionType = parts[1];
    const choices = parts.slice(2);
    let relatedQuestions = []
    if (questionType.indexOf('USCL') !== -1) { // Unordered single choice list
        let [, questionGroup] = questionType.split('-')
        relatedQuestions = questionGroup.split(',')
        questionType = 'USCL'
    }

    return {
        questionNumber,
        questionType,
        choices,
        relatedQuestions
    };
};

const parseQuestion = (questionNumber, questionType, choices) => {
    if (questionType === 'CT') {
        return transformClozeTextQuestion(questionNumber);
    }

    if (questionType === 'SCL' || questionType === 'USCL') {
        return transformSingleChoiceListQuestion(questionNumber, choices)
    }

    if (questionType === 'SC') {
        return transformSingleChoiceQuestion(questionNumber, choices);
    }
};

const parseQuestions = (questions) => {
    let parsedQuestions = [];
    let questionList = getListOfQuestions(questions)

    Object.keys(questionList).forEach(key => {
        parsedQuestions.push(`<div class="mb-3">${parseQuestion(questionList[key].questionNumber, questionList[key].questionType, questionList[key].choices)}</div>`)
    })

    return parsedQuestions.join('');
};

const getListOfQuestions = questions => {
    const matches = Array.from(questions.matchAll(pattern), m => m[1])
    let questionList = {}

    matches.reduce((questionList, match) => {
        let {questionNumber, questionType, choices, relatedQuestions} = splitQuestion(match)
        questionList[questionNumber] = {
            questionNumber,
            questionType,
            choices,
            relatedQuestions
        }
        return questionList
    }, questionList)

    return questionList
}

const parseQuestionsInText = (text) => {
    const matches = Array.from(text.matchAll(pattern), m => m[1]);
    let newText = text;

    matches.forEach(match => {
        const {questionNumber, questionType, choices} = splitQuestion(match);
        const parsedQuestion = parseQuestion(questionNumber, questionType, choices);
        newText = newText.replace(`{${match}}`, parsedQuestion);
    });

    return newText;
};

const isCorrectAnswer = (questionNumber, answer, test, currentAnswers) => {
    const answers = {...test.answers1, ...test.answers2, ...test.answers3, ...test.answers4};
    const question = test.questionList ? test.questionList[questionNumber] : null
    answer = answer ? answer.toLowerCase() : '';

    if (question) {
        if (question.questionType === 'USCL') {
            const relatedQuestions = question.relatedQuestions
            const indexOfCurrentQuestionInRelatedQuestionList = relatedQuestions.indexOf(questionNumber)
            let previousQuestions = relatedQuestions.slice(0, indexOfCurrentQuestionInRelatedQuestionList)
            let correctAnswers = []
            relatedQuestions.forEach(relatedQuestion => {
                correctAnswers.push(answers[relatedQuestion].trim().toLowerCase())
            })

            for (let i=0; i<indexOfCurrentQuestionInRelatedQuestionList; i++) {
                if (currentAnswers[previousQuestions[i]].toLowerCase() === answer) {
                    return false
                }
            }

            return correctAnswers.indexOf(answer) !== -1
        }
    }

    const correctAnswer = String(answers[questionNumber]).toLowerCase();

    return correctAnswer.split('|').map(str => str.trim().toLowerCase()).indexOf(answer) !== -1
}

export {
    splitQuestion,
    parseQuestions,
    parseQuestionsInText,
    getListOfQuestions,
    isCorrectAnswer
}