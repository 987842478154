import RichMarkdownEditor from "rich-markdown-editor";
import React, {useState, useRef, useEffect} from "react";
import {Question} from "./question.component";
import {markdownToHtml} from "../../services/utils";
import ReactPlayer from "react-player";
import GreenAudioPlayer from "green-audio-player/src/js/main";
import Markdown from "react-remarkable"

export const ReadingTest = (props) => {
    const taskType = props.taskType;
    const showAudioPlayer = props.showAudioPlayer;
    const showResults = props.showResults;
    const taskResponse = props.taskResponse ? props.taskResponse : {};
    const results = taskResponse.content ? (JSON.parse(taskResponse.content))[0] : {};
    const editable = props.editable;
    const player = useRef(null);
    const [currentPlayingFileIndex, setCurrentPlayingIndex] = useState(0);
    const [fileUrl, setFileUrl] = useState(null);
    const [showSpeakingPart2, setShowSpeakingPart2] = useState(false)
    const [manualPlaying, setManualPlaying] = useState(false)
    let playing = props.playing;
    let onEndedCallback = props.onEnded;
    const showKeyAndExplanation = props.showKeyAndExplanation

    const parseLineThatHasQuestion = questions => {
        const length = questions.length;
        let parts = [];
        let currentPart = '';
        for (let i=0; i<length; i++) {
            if (questions[i] === '{') {
                parts.push({
                    type: 'string',
                    value: currentPart
                });
                currentPart = '';
            } else if (questions[i] === '}') {
                currentPart += questions[i];
                parts.push({
                    type: 'component',
                    value: currentPart
                });
                currentPart = '';
            } else if (i === questions.length-1) {
                currentPart += questions[i];
                parts.push({
                    type: 'string',
                    value: currentPart
                });
            } else {
                currentPart += questions[i];
            }
        }
        return parts;
    };

    const onAnswerChange = (questionNumber, value) => {
        if (props.onAnswerChange) {
            props.onAnswerChange(questionNumber, value);
        }
    };

    const parseQuestions = (questions, partIndex) => {
        let lines = questions.split("\n");
        let tableRows = []
        let components = []
        let outputItems = []

        for (let i = 0; i < lines.length; i++) {
            let line = lines[i]
            if (line.startsWith('|')) { // Table
                if (!line.includes('----')) { // ignore markdown header
                    let parts = line.split('|')
                    parts.shift() // First item is empty
                    parts.pop() // Last item is empty
                    tableRows.push(parts)
                }
            } else {
                if (tableRows.length) {
                    components.push({
                        type: 'table',
                        value: tableRows
                    })
                    tableRows = []
                }

                components.push({
                    type: line.includes('{') ? 'question' : 'text',
                    value: line
                })
            }

            if (i === lines.length-1 && tableRows.length) {
                components.push({
                    type: 'table',
                    value: tableRows
                })
            }
        }

        let textItems = []
        components.forEach((component, index) => {
            if (component.type !== 'text' && textItems.length) {
                // Flush text items in 1 markdown editor
                outputItems.push(<RichMarkdownEditor defaultValue={textItems.join('\n')} readOnly={true}/>)
                textItems = []
            }

            if (component.type === 'text') {
                textItems.push(component.value)
            } else if (component.type === 'question') {
                outputItems.push(renderLineWithQuestion(component.value))
            } else if (component.type === 'table') {
                let tableRows = component.value
                outputItems.push(
                    <table>
                        <tbody>
                        {tableRows.map((tr, trIndex) => {
                            return (<tr key={`table-${partIndex}-${index}-${trIndex}`}>
                                {tr.map((td, tdIndex) => {
                                    return (<td key={`table-${partIndex}-${index}-${trIndex}-${tdIndex}`}>{renderLineWithQuestion(td)}</td>)
                                })}
                            </tr>)
                        })}
                        </tbody>
                    </table>
                )
            }

            if (index === components.length-1 && textItems.length) {
                // Flush text items in 1 markdown editor
                outputItems.push(<RichMarkdownEditor defaultValue={textItems.join('\n')} readOnly={true}/>)
            }
        })

        return outputItems
    }

    const renderLineWithQuestion = line => {
        let singleParts = parseLineThatHasQuestion(line);

        return (
            <div className={"line-with-question"}>
                {singleParts.map((singlePart, key) => {
                    if (singlePart.type === 'string') {
                        return <span className={"markdown"}><Markdown source={singlePart.value} container={"span"} /></span>;
                    }

                    return <Question key={key} question={singlePart} onAnswerChange={onAnswerChange} defaultAnswers={props.defaultAnswers} test={props.test}
                                     results={results} showResults={showResults} editable={editable} showKeyAndExplanation={showKeyAndExplanation} />
                })}
            </div>
        );
    }

    const onEnded = async () => {
        if (taskType === 'listening-test' || taskType === 'listening-practice') {
            return switchFile();
        }

        if (taskType === 'speaking-test') {
            switchFileForSpeakingTest();
        }
    }

    const switchFileForSpeakingTest = () => {
        const isPart1 = () => currentPlayingFileIndex <= 11
        const isPart2 = () => currentPlayingFileIndex === 12
        const isPart3 = () => currentPlayingFileIndex >= 13
        const part1Timeout = 25000
        const part2Timeout = 120000
        const part3Timeout = 40000

        if (isPart1()) {
            window.setTimeout(switchFile, part1Timeout)
        }
        if (isPart2()) {
            window.setTimeout(switchFile, part2Timeout)
        }
        if (isPart3()) {
            window.setTimeout(switchFile, part3Timeout)
        }
    }

    const switchFile = () => {
        const newIndex = findNextFileIndexToPlay();

        if (newIndex) {
            setCurrentPlayingIndex(newIndex);
            setFileUrl(props.test.files[newIndex]);

            if (newIndex === 12) {
                window.setTimeout(() => {setShowSpeakingPart2(true)}, 18000)
            }
            if (newIndex === 13) {
                setShowSpeakingPart2(false)
            }
        } else if (onEndedCallback) {
            onEndedCallback()
        }
    }

    const findNextFileIndexToPlay = () => {
        for (let i = currentPlayingFileIndex + 1; i < props.test.files.length; i++) {
            if (props.test.files[i]) {
                return i
            }
        }

        return null
    }

    useEffect(() => {
        if (props.test.files) {
            setFileUrl(props.test.files[currentPlayingFileIndex]);

            GreenAudioPlayer.init({
                selector: '.player',
                stopOthersOnPlay: true
            });
        }
    }, []);

    const manuallyPlayAudio = (e) => {
        e.preventDefault()
        setManualPlaying(true)
    }

    return (
        <div className={"container-fluid full-height reader-test-wrapper"}>
            <form autoComplete={"off"} className={`full-height ${showAudioPlayer ? 'with-audio-player' : ''}`}>
                <div id={`passage-1`} className={`row full-height ${props.currentPassage !== 1 ? "d-none": ""}`}>
                    <div className={`${props.test.content1 ? "col-md-6 side full-height" : "d-none"}`} dangerouslySetInnerHTML={{__html: markdownToHtml(props.test.content1)}}>
                    </div>
                    <div className={`${props.test.content1 ? `col-md-6 side full-height ${taskType === 'speaking-test' && showSpeakingPart2 ? '' : (taskType === 'speaking-test' ? 'd-none' : '')}` : `col-md-12 side full-height ${taskType === 'speaking-test' && showSpeakingPart2 ? '' : (taskType === 'speaking-test' ? 'd-none' : '')}`}`}>
                        {parseQuestions(props.test.questions1, 1).map((item, index) => {
                            return (<div key={`passage1-${index}`}>
                                {item}
                            </div>)
                        })}
                        {props.test.file1 && (
                            <div className={!showAudioPlayer ? 'd-none' : 'listening-audio-player'}>
                                <div className="player"><audio>
                                    <source src={props.test.file1} type="audio/mpeg"/>
                                </audio></div>
                            </div>
                        )}
                        {taskType === 'listening-practice' && props.test.file1 && editable && (
                            <div style={{marginTop: '10px'}}>
                                <button className={`btn btn-primary ${manualPlaying || showAudioPlayer ? 'd-none' : ''}`} type={"button"} onClick={manuallyPlayAudio}>Play audio</button>
                            </div>
                        )}
                    </div>
                </div>

                <div id={`passage-2`} className={`row full-height ${props.currentPassage !== 2 ? "d-none": ""}`}>
                    <div className={`${props.test.content2 ? "col-md-6 side full-height" : "d-none"}`} dangerouslySetInnerHTML={{__html: markdownToHtml(props.test.content2)}}>
                    </div>
                    <div className={`${props.test.content2 ? "col-md-6 side full-height" : "col-md-12 side full-height"}`}>
                        {parseQuestions(props.test.questions2, 2).map((item, index) => {
                            return (<div key={`passage2-${index}`}>
                                {item}
                            </div>)
                        })}
                        {props.test.file2 && (
                            <div className={!showAudioPlayer ? 'd-none' : 'listening-audio-player'}>
                                <div className="player"><audio>
                                    <source src={props.test.file2} type="audio/mpeg"/>
                                </audio></div>
                            </div>
                        )}
                    </div>
                </div>

                <div id={`passage-3`} className={`row full-height ${props.currentPassage !== 3 ? "d-none": ""}`}>
                    <div className={`${props.test.content3 ? "col-md-6 side full-height" : "d-none"}`} dangerouslySetInnerHTML={{__html: markdownToHtml(props.test.content3)}}>
                    </div>
                    <div className={`${props.test.content3 ? "col-md-6 side full-height" : "col-md-12 side full-height"}`}>
                        {parseQuestions(props.test.questions3, 3).map((item, index) => {
                            return (<div key={`passage3-${index}`}>
                                {item}
                            </div>)
                        })}
                        {props.test.file3 && (
                            <div className={!showAudioPlayer ? 'd-none' : 'listening-audio-player'}>
                                <div className="player"><audio>
                                    <source src={props.test.file3} type="audio/mpeg"/>
                                </audio></div>
                            </div>
                        )}
                    </div>
                </div>

                <div id={`passage-4`} className={`row full-height ${props.currentPassage !== 4 ? "d-none": ""}`}>
                    <div className={`${props.test.content4 ? "col-md-6 side full-height" : "d-none"}`} dangerouslySetInnerHTML={{__html: markdownToHtml(props.test.content4)}}>
                    </div>
                    <div className={`${props.test.content4 ? "col-md-6 side full-height" : "col-md-12 side full-height"}`}>
                        {parseQuestions(props.test.questions4, 4).map((item, index) => {
                            return (<div key={`passage4-${index}`}>
                                {item}
                            </div>)
                        })}
                        {props.test.file4 && (
                            <div className={!showAudioPlayer ? 'd-none' : 'listening-audio-player'}>
                                <div className="player"><audio>
                                    <source src={props.test.file4} type="audio/mpeg"/>
                                </audio></div>
                            </div>
                        )}
                    </div>
                </div>

                {props.test.files && (
                    <div className="d-none">
                        <ReactPlayer onEnded={onEnded} ref={player} url={fileUrl} playing={playing || manualPlaying} />
                    </div>
                )}
            </form>
        </div>
    );
};
