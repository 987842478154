import {API} from "@aws-amplify/api";
import {createTaskFromResponse} from "../dtos/dto_factory";
import {Task} from "../dtos/task"

const findTask = async (taskId: string): Promise<Task> => {
    const response = await API.get("app", `/tasks/${taskId}`, {});

    return createTaskFromResponse(response)
}

const getTaskTitle = async (taskId: string): Promise<string> => {
    const task = await findTask(taskId)

    return task.title
}

export {
    findTask,
    getTaskTitle
}