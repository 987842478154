import {Assessment} from "./assessment";
import {TaskResponse} from "./task_response";
import {Assignment} from "./assignment";
import {Task} from "./task"

interface ITaskResponse {
    assignment_id: string;
    content: string;
    meta_data: string;
    created_at: string | null;
}

interface ITaskAssessment {
    assignment_id: string;
    content: string;
    comments: Array<any>;
    overall_comment: string | null;
    score: number | string | null;
}

interface IAssignmentResponse {
    assignment_id: string;
    task_id: string;
    student_id: string;
    status: string;
    readable_status: string;
    task_type: string;
    title: string;
    content: string;
    meta_data: string;
    result?: { attempts: number, correct_answers: Array<number | string> };
    task_response: ITaskResponse | null,
    task_responses: Array<ITaskResponse> | null
    task_assessment: ITaskAssessment | null,
    prerequisite_assignment?: {title: string} | null
}

interface ITaskResponse {
    task_id: string,
    title: string,
    task_type: string,
    readable_task_type: string,
    content: string,
    meta_data: string,
    category: string,
    readable_category: string,
    book: string,
    year: string,
    students: string[],
    following_task_ids: string,
    parent_task_id: string
}

const createAssignmentFromResponse = (response: IAssignmentResponse) => {
    let assignment = new Assignment(
        response.assignment_id,
        response.status,
        response.title,
        response.content,
        response.meta_data,
        null,
        null,
        [],
        response.result,
        response.prerequisite_assignment ? response.prerequisite_assignment : null
    );

    if (response.task_assessment) {
        assignment.taskAssessment = new Assessment(
            response.task_assessment.content,
            response.task_assessment.comments,
            response.task_assessment.overall_comment,
            response.task_assessment.score
        )
    }

    if (response.task_response) {
        assignment.firstResponse = new TaskResponse(
            response.task_response.assignment_id,
            response.task_response.content,
            response.task_response.meta_data,
            response.task_response.created_at
        );
    }

    if (response.task_responses) {
        assignment.responses = response.task_responses.reduce((allResponses: TaskResponse[], item: ITaskResponse) => {
            allResponses.push(new TaskResponse(
                item.assignment_id,
                item.content,
                item.meta_data,
                item.created_at
            ));

            return allResponses;
        }, []);

        return assignment;
    }
};

const createTaskFromResponse = (response: ITaskResponse): Task => {
    let task = new Task(
        response.task_id,
        response.title,
        response.task_type,
        response.readable_task_type,
        response.content,
        response.meta_data,
        response.category,
        response.readable_category,
        response.book,
        response.year,
        response.students,
        response.following_task_ids,
        response.parent_task_id
    )

    return task
}

export {
    createAssignmentFromResponse,
    createTaskFromResponse
}
