import React, {useState, useEffect} from "react"
import {useParams, useHistory} from "react-router-dom"
import {API} from "aws-amplify"
import {Instruction} from "../../components/instruction/instruction.component";
import Markdown from "react-remarkable";
import {toast} from "react-toastify";
import {Comments} from "../../components/comments/comments.component";
import {parseReadingTestFromRawData} from "../../services/reading_test_service";
import {SpeakerTestResponsePlayer} from "../../components/speaker_test_response_player";
import config from "../../config";

export const AssessmentPage = () => {
    let {id, responseIndex} = useParams();
    const [title, setTitle] = useState('');
    const [status, setStatus] = useState('');
    const [content, setContent] = useState('');
    const [instruction, setInstruction] = useState('');
    const [ideas, setIdeas] = useState('');
    const [sample, setSample] = useState('');
    const [vocabulary, setVocabulary] = useState('');
    const [taskResponse, setTaskResponse] = useState('');
    const history = useHistory();
    const [test, setTest] = useState(null)
    const [taskType, setTaskType] = useState(null)

    responseIndex = responseIndex ? responseIndex : 0;

    useEffect(async () => {
        API.get("app", `/assignments/${id}`, {}).then(result => {
            const metaData = result.meta_data ? JSON.parse(result.meta_data) : {};

            setStatus(result.status);
            setTitle(result.title);
            setContent(result.content);
            setInstruction(metaData.instruction ? metaData.instruction : '');
            setIdeas(metaData.ideas ? metaData.ideas : '');
            setVocabulary(metaData.vocabulary ? metaData.vocabulary : '');
            setSample(metaData.sample ? metaData.sample : '');
            setIdeas(metaData.vocabulary ? metaData.vocabulary : '');
            setTaskType(result.task_type)

            if (result.task_responses) {
                setTaskResponse(result.task_responses[responseIndex].content);
            }

            loadTest(result.task_id)
        });
    }, [id]);

    const afterAssessment = () => {
        toast('Assessment saved successfully');
        history.push(`/assignments/${id}`);
    };

    const loadTest = async taskId => {
        const serverResponse = await API.get("app", `/tasks/${taskId}`, {});
        const parsedTest = await parseReadingTestFromRawData(serverResponse);
        setTest(parsedTest)
    }

    return (
        <div className={'container-fluid'}>
            <h1 className={"color"}>{title}</h1>
            {taskType && taskType !== 'SPEAKING_TEST' && (
                <div className={"row"}>
                    <Markdown>{content}</Markdown>
                </div>
            )}

            {taskType === 'SPEAKING_TEST' && test && (
                <SpeakerTestResponsePlayer files={test.files} responseFile={`${config.s3.PUBLIC_URL}/${taskResponse}`}/>
            )}

            <Comments response={taskResponse} assignmentId={id} afterAssessment={afterAssessment}/>

            <Instruction instruction={instruction} ideas={ideas} vocabulary={vocabulary} sample={sample}/>
        </div>
    );
};
