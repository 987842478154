import {useState, useEffect} from "react"
import {API} from "@aws-amplify/api";
import {createAssignmentFromResponse} from "../dtos/dto_factory";

const useAssignment = id => {
    const [assignment, setAssignment] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const response = await API.get("app", `/assignments/${id}`, {});
            setAssignment(createAssignmentFromResponse(response));
        };

        fetchData();
    }, [id]);

    return assignment;
};

const getStatuses = () => {
    return [
        {
            value: 'Pending',
            label: 'Pending'
        },
        {
            value: 'InProgress',
            label: 'In Progress'
        },
        {
            value: 'NeedsReview',
            label: 'Needs Review'
        },
        {
            value: 'Reviewed',
            label: 'Reviewed'
        },
        {
            value: 'Finished',
            label: 'Finished'
        }
    ]
}

const getAssignments = async (studentEmail, status, taskType) => {
    let parameters = {}

    if (studentEmail) {
        parameters.email = studentEmail
    }

    if (status) {
        parameters.status = status
    }

    if (taskType) {
        parameters.task_type = taskType
    }

    const response =  await API.get("app", "/assignments", {
        queryStringParameters: parameters
    })

    return response
}

export {
    useAssignment,
    getStatuses,
    getAssignments
}
