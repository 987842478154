import {useParams, useHistory, Link, Prompt} from "react-router-dom"
import React, {useEffect, useState, useRef} from "react"
import {API, Storage} from "aws-amplify"
import {useAssignment} from "../../services/assignment_service";
import {toast} from "react-toastify";
import {useFetchRawReadingTest} from "../../services/reading_test_service";
import {ReadingTest} from "../../components/tasks/reading_test.component";
import "./assignment_reading.scss";
import {ReactComponent as ViewIcon} from "../../assets/eye-small.svg";
import LoadingBar from "react-top-loading-bar";
import MicRecorder from 'mic-recorder-to-mp3'
import config from "../../config";
import {Timer} from "../../components/timer/timer.component";
import {getTaskTypeText, markdownToHtml} from "../../services/utils";
import {isCorrectAnswer} from "../../services/question_service";
import {useBeforeunload} from "react-beforeunload";

export const AssignmentReadingPage = () => {
    const loadingBarRef = useRef(null);
    const {taskType, id, taskId, responseIndex, action} = useParams();
    const isRedoing = action === 'redo';
    const showResults = typeof responseIndex !== 'undefined';
    const history = useHistory();
    const [assignmentStarted, setAssignmentStarted] = useState(false);
    const [currentPassage, setCurrentPassage] = useState(1);
    const [speakingFileName, setSpeakingFileName] = useState(null);
    const [maxPassage, setMaxPassage] = useState(taskType === 'reading-test' ? 3 : 4)
    const [taskDuration, setTaskDuration] = useState(null)
    let assignment = useAssignment(id);
    let test = useFetchRawReadingTest(taskId);
    const key = `assignment:${id}:pendingAnswers`;
    const useAnswersWithLocalStorage = (defaultValue) => {
        let savedAnswers = localStorage.getItem(key);
        savedAnswers = savedAnswers ? JSON.parse(savedAnswers) : defaultValue;
        const [answers, setAnswers] = useState(savedAnswers);

        useEffect(() => {
            localStorage.setItem(key, JSON.stringify(answers));
        }, [answers]);

        return [answers, setAnswers];
    }

    const [unSubmittedAnswers, setUnsubmittedAnswers] = useAnswersWithLocalStorage({});
    const [recorder, setRecorder] = useState(null);
    const [canDownloadRecord, setCandownloadRecord] = useState(false)
    const [recordFileUrl, setRecordFileUrl] = useState(null)
    const [timerMinutes, setTimerMinutes] = useState(null)
    const [timerSeconds, setTimerSeconds] = useState(null)
    const [timerStarted, setTimerStarted] = useState(false)
    const [totalCorrectAnswers, setTotalCorrectAnswers] = useState(0)
    const showKeyAndExplanation = ((responseIndex && assignment && assignment.result.attempts >= 2 && !isRedoing) || (['guided-writing', 'skill-boost'].indexOf(taskType) !== -1 && assignment && assignment.result.attempts > 0))
    const [responseSubmitted, setResponseSubmitted] = useState(false)

    useEffect(() => {
        if (test) {
            setMaxPassage(test.numberOfPassages)
        }
    }, [test])

    const start = async e => {
        e.preventDefault();

        if (window.confirm("Are you sure you want to start working on this task?")) {
            if (taskType === 'speaking-test') {
                alert("You will be recorded until the end of the test.\nPlease grant permission to access your microphone.");
                try {
                    await window.navigator.mediaDevices.getUserMedia({audio: true})
                    await startAssignment()
                    await startRecording()
                } catch (e) {
                    alert("Please grant permission to access your microphone to start the test")
                }

                return
            }

            await startAssignment()
        }
    };

    const startAssignment = async () => {
        loadingBarRef.current.continuousStart();
        await API.post("app", `/assignments/${id}`, {}).then(() => {
            setAssignmentStarted(true);
            loadingBarRef.current.complete();

            startTimerIfNecessary()
        }).catch(e => {
            toast.error(e.toString());
            loadingBarRef.current.complete();
        })
    }

    const startRecording = async () => {
        let micRecorder = new MicRecorder({bitRate: 128})
        micRecorder.start().then(() => {}).catch(e => {
            toast.error(e.toString())
        })
        setRecorder(micRecorder)
    }

    const next = e => {
        e.preventDefault();

        if (currentPassage < maxPassage) {
            startReadingPracticeIfNecessary(currentPassage + 1)
            setCurrentPassage(currentPassage + 1);
        }
    };

    const startReadingPracticeIfNecessary = (passage) => {
        if (taskType === 'reading-practice' && passage === 2 && (assignment.status === 'InProgress' || assignmentStarted) && !timerStarted) {
            setTaskDuration(20)
            setTimerStarted(true)
        }
    }

    const startTimerForReadingTest = () => {
        setTaskDuration(60)
        setTimerStarted(true)
    }

    const startTimerIfNecessary = () => {
        if (taskType === 'reading-test') {
            startTimerForReadingTest()
        } else if (taskType === 'skill-boost' && test.timer) {
            setTaskDuration(test.timer)
            setTimerStarted(true)
        }
    }

    const previous = e => {
        e.preventDefault();

        if (currentPassage > 1) {
            setCurrentPassage(currentPassage - 1);
        }
    };

    const onAnswerChange = (questionNumber, value) => {
        let clonedAnswers = {...unSubmittedAnswers};
        clonedAnswers[questionNumber] = value;

        setUnsubmittedAnswers(clonedAnswers);
    }

    const calculateNumberOfCorrectAnswers = () => {
        return Object.keys(unSubmittedAnswers).reduce((totalCorrectAnswers, questionNumber) => {
            let unsubmittedAnswer = String(unSubmittedAnswers[questionNumber]).toLowerCase().trim();

            return totalCorrectAnswers + isCorrectAnswer(questionNumber, unsubmittedAnswer, test, unSubmittedAnswers)
        }, 0);
    }

    const onAudioEnded = async () => {
        if ((taskType === 'listening-test' || taskType === 'listening-practice') && (assignment.status === 'InProgress' || assignmentStarted)) {
            toast('This is the end of the listening test. Your answers will be automatically submitted in 5 seconds.');
            window.setTimeout(() => {
                submit();
            }, 5000);
        }

        if (taskType === 'speaking-test' && (assignment.status === 'InProgress' || assignmentStarted)) {
            toast('This is the end of the speaking test. Your answers will be automatically submitted in 5 seconds.');
            window.setTimeout(() => {
                submitSpeakingTest()
            }, 5000);
        }
    }

    const submit = async () => {
        loadingBarRef.current.continuousStart();

        const totalCorrectAnswers = calculateNumberOfCorrectAnswers();
        try {
            await API.post(
                'app',
                `/assignments/reading-test/${id}/respond`,
                {
                    body: {
                        number_of_correct_answers: totalCorrectAnswers,
                        answers: unSubmittedAnswers
                    }
                }
            );
            setResponseSubmitted(true)
            loadingBarRef.current.complete();

            localStorage.removeItem(key);
            toast("Your response has been submitted successfully");
            setTimeout(() => {
                window.location.href = `/assignments/${taskType}/${id}/${taskId}/${assignment.result.attempts}`;
            }, 1000);
        } catch (e) {
            loadingBarRef.current.complete();
            toast.error('Error occurred: ' + e.toString());
        }
    }

    const submitSpeakingTest = async () => {
        loadingBarRef.current.continuousStart();
        recorder.stop().getMp3().then(async ([buffer, blob]) => {
            const file = new File(buffer, 'record.mp3', {
                type: 'audio/mpeg',
                lastModified: Date.now()
            });
            setCandownloadRecord(true)
            setRecordFileUrl(URL.createObjectURL(blob))
            try {
                const fileName = `${Date.now()}.mp3`;
                const filePath = `${config.s3.RESPONSE_FILES_PATH}/${fileName}`
                setSpeakingFileName(filePath)
                await Storage.put(
                    filePath,
                    file,
                    {
                        level: 'public',
                        contentType: file.type,
                        acl: 'public-read'
                    }
                )
                await API.put("app", `/assignments/${id}/respond`, {
                    body: {
                        content: filePath,
                        meta_data: ''
                    }
                })
                setResponseSubmitted(true)
                toast('Your response has been saved successfully');
                loadingBarRef.current.complete()
                history.push(`/assignments/${id}`)
            } catch (e) {
                toast.error('Fails to upload your recording. Please download the recording manually and contact us.')
                loadingBarRef.current.complete()
            }
        })
    }

    const submitResponse = async () => {
        if (window.confirm('Are you sure you want to submit your response?')) {
            await submit();
        }
    }

    const confirmRedo = async () => {
        if (window.confirm('Are you sure you want to redo this test?')) {
            let currentAnswers = (JSON.parse(assignment.responses[responseIndex]['content']))[0];
            setUnsubmittedAnswers(currentAnswers);
            history.push(`/assignments/${taskType}/${id}/${taskId}/${responseIndex}/redo`);
        }
    }

    const shouldSetPlaying = () => (assignment.status === 'InProgress' || assignmentStarted) && taskType !== 'listening-practice'

    const timerCallback = (minutes, seconds) => {
        if (minutes === 0 && seconds === 0) {
            if (taskType === 'reading-practice' || taskType === 'reading-test' || taskType === 'skill-boost') {
                toast(`This is the end of the ${getTaskTypeText(taskType)}. Your answers will be automatically submitted in 5 seconds.`);
                window.setTimeout(() => {
                    submit()
                }, 5000);
            }
        }
    }

    useBeforeunload((event) => {
        if (assignmentStarted && !responseSubmitted) {
            event.preventDefault()
        }
    })

    return assignment && test && (
        <>
            <LoadingBar color={"#0d6efd"} ref={loadingBarRef}/>
            <Prompt message={"Are you sure you want to leave this page without submitting your response?"} when={assignmentStarted && !responseSubmitted} />
            {assignment.status === 'Pending' && assignment.prerequisiteAssignment && (
                <div className="alert alert-warning" role="alert">
                    <strong>Note:</strong> This assignment cannot be started until the assignment <strong>{assignment.prerequisiteAssignment.title}</strong> is finished
                </div>
            )}
            {["Pending", "Finished"].indexOf(assignment.status) !== -1 && !responseIndex && !assignmentStarted && (
                <>
                    <h1 className={"color"}>{assignment.title}</h1>
                    {test.estimatedTime && (
                        <p>Estimated time: {test.estimatedTime} minutes</p>
                    )}

                    {/*<div className={"row mb-3"}>*/}
                    {/*    <label className={"col-sm-2"}><strong>{taskTypeMap[assignment['task_type']]} test</strong></label>*/}
                    {/*</div>*/}
                    {/*<div className={"row mb-3"}>*/}
                    {/*    <label className={"col-sm-2"}>Duration: 60 minutes</label>*/}
                    {/*</div>*/}
                </>
            )}

            {(assignment.status === "Pending" && !assignmentStarted) && (
                <div className={"buttons"}>
                    <input type={"button"} className={"btn btn-secondary"} onClick={() => {history.push('/assignments')}} value={"Back"}/>
                    <input type={"button"} className={`btn btn-primary ${assignment.prerequisiteAssignment ? 'disabled' : ''}`} value={"Start"} onClick={start}/>
                </div>
            )}

            {(assignment.status === 'InProgress' || assignmentStarted || responseIndex) && test && (
                <div className="reader-assignment-wrapper">
                    <ReadingTest test={test} currentPassage={currentPassage} onAnswerChange={onAnswerChange} defaultAnswers={unSubmittedAnswers} showResults={showResults} taskResponse={assignment.responses[parseInt(responseIndex)]}
                        editable={isRedoing || assignment.status === 'InProgress' || assignmentStarted}
                        playing={shouldSetPlaying()}
                        onEnded={onAudioEnded} showAudioPlayer={isRedoing} taskType={taskType}
                                 showKeyAndExplanation={showKeyAndExplanation}/>
                    <div className={`fixed-bottom bottom-nav ${taskType === 'speaking-test' ? 'd-none' : ''}`}>
                        <button className={`btn btn-secondary ${currentPassage === 1 ? 'd-none' : ''}`} onClick={previous}>Previous</button>
                        <button className={`btn btn-secondary ${currentPassage < maxPassage ? '' : 'd-none'}`} onClick={next}>Next</button>

                        {(!responseIndex || isRedoing) && (
                            <>
                                <button className={"btn btn-primary"} onClick={submitResponse}>Submit</button>
                                {timerStarted && (
                                    <Timer durationInMinutes={taskDuration} durationInSeconds={0} started={true} callback={timerCallback}></Timer>
                                )}
                            </>
                        )}

                        {responseIndex && (
                            <span className={"score float-end"}>Correct answers: {assignment.result.correct_answers[responseIndex]}/{test.numberOfQuestions}</span>
                        )}

                        {responseIndex && assignment.result.attempts < 50 && !isRedoing && ['guided-writing', 'skill-boost'].indexOf(taskType) === -1 && (
                            <button className={"btn btn-primary"} onClick={confirmRedo}>Redo</button>
                        )}

                        {taskType === 'listening-practice' && (
                            <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#explanationPopup">Show explanation</button>
                        )}

                        {test[`guidance${currentPassage}`] && (
                            <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#guidancePopup">Guidance</button>
                        )}

                        {test[`hints${currentPassage}`] && assignment.result.attempts >= 3 && assignment.result.correct_answers[assignment.result.attempts-1] < test.numberOfQuestions && (
                            <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#hintsPopup">Hints</button>
                        )}

                        {responseIndex && (assignment.result.correct_answers[responseIndex] >= 38 || (test.numberOfQuestions > 0 && assignment.result.correct_answers[responseIndex] === test.numberOfQuestions) || (['guided-writing', 'skill-boost'].indexOf(taskType) !== -1 && assignment.result.attempts > 0)) && (
                            <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#otherInfoPopup">{['listening-test', 'listening-practice'].indexOf(taskType) !== -1 ? 'Show tape script' : 'Show info'}</button>
                        )}
                    </div>

                    <div className={`fixed-bottom bottom-nav ${taskType === 'speaking-test' ? '' : 'd-none'}`}>
                        {canDownloadRecord && (
                            <a href={recordFileUrl} target={"_blank"} className={"btn btn-primary"}>Download recording</a>
                        )}
                    </div>
                </div>
            )}

            {["Finished"].indexOf(assignment.status) !== -1 && assignment && !responseIndex && (
                <div>
                    <h2 className={"color"}>Your attempts</h2>

                    <table className={"table"}>
                        <thead>
                        <tr>
                            <th scope={"col"}>Submitted date</th>
                            <th scope={"col"} className={"text-center"}>Number of correct answers</th>
                            <th scope={"col"}></th>
                        </tr>
                        </thead>
                        <tbody>
                        {assignment.responses.map((response, index) => {
                            return (
                                <tr key={index}>
                                    <td>{response.createdAt ? (new Date(Date.parse(response.createdAt + 'Z'))).toLocaleString() : ''}</td>
                                    <td className={"text-center"}>{assignment.result.correct_answers[index]}/{test.numberOfQuestions}</td>
                                    <td><Link title={"View your response"} to={`/assignments/${taskType}/${id}/${taskId}/${index}`}><ViewIcon/></Link></td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </div>
            )}

            {test && (
                <div className="modal fade" id="explanationPopup" tabIndex="-1" aria-labelledby="explanationPopup" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Explanation for Passage {currentPassage}</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal"
                                        aria-label="Close"></button>
                            </div>
                            <div className="modal-body"
                                dangerouslySetInnerHTML={{__html: String(test[`explanation${currentPassage}`]).replace(/(?:\r\n|\r|\n)/g, '<br>')}}>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {test && (
                <div className="modal fade" id="guidancePopup" tabIndex="-1" aria-labelledby="guidancePopup" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="">Guidance</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal"
                                        aria-label="Close"></button>
                            </div>
                            <div className="modal-body"
                                 dangerouslySetInnerHTML={{__html: markdownToHtml(test[`guidance${currentPassage}`])}}>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {test && (
                <div className="modal fade" id="hintsPopup" tabIndex="-1" aria-labelledby="hintsPopup" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="">Hints</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal"
                                        aria-label="Close"></button>
                            </div>
                            <div className="modal-body"
                                 dangerouslySetInnerHTML={{__html: markdownToHtml(test[`hints${currentPassage}`])}}>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {test && (
                <div className="modal fade" id="otherInfoPopup" tabIndex="-1" aria-labelledby="otherInfoPopup" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">{['listening-test', 'listening-practice'].indexOf(taskType) !== -1 ? `Tape script for Passage ${currentPassage}` : 'Info'}</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal"
                                        aria-label="Close"></button>
                            </div>
                            <div className="modal-body"
                                 dangerouslySetInnerHTML={{__html: String(test[`otherInfo${currentPassage}`]).replace(/(?:\r\n|\r|\n)/g, '<br>')}}>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
