import React, {useState, useEffect, useRef} from "react";
import {toast} from "react-toastify";
import {API} from "aws-amplify";
import {useHistory, useParams} from "react-router-dom";
import LoadingBar from "react-top-loading-bar";

export const NewStudentPage = () => {
    const loadingBarRef = useRef(null);
    const history = useHistory();
    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [targetBand, setTargetBand] = useState("");
    const {id} = useParams();

    useEffect(async () => {
        if (id) {
            try {
                loadingBarRef.current.continuousStart();
                await API.get("app", `/students/${id}`, {}).then(result => {
                    setEmail(result.email);
                    setFirstName(result.first_name);
                    setLastName(result.last_name);
                    setTargetBand(result.target_band);
                });
                loadingBarRef.current.complete();
            } catch (e) {
                toast(e.toString);
                loadingBarRef.current.complete();
            }
        }
    }, [id]);

    const save = async (e) => {
        e.preventDefault();

        try {
            await API.put("app", "/students", {
                body: {
                    email: email,
                    first_name: firstName,
                    last_name: lastName,
                    target_band: targetBand
                }
            });

            toast("Student was saved successfully");

            history.push(`/students/${email}`);
        } catch (e) {
            toast(e.toString);
        }
    };

    return (
        <div className={'container-fluid'}>
            <LoadingBar color={"#0d6efd"} ref={loadingBarRef}/>
            <h1 className={"color"}>Add a new student</h1>

            <form className={"row"} onSubmit={save}>
                <div className={"col-md-6 mb-3"}>
                    <label htmlFor={"email"} className={"form-label"}>Email</label>
                    <input id={"email"} type={"text"} className={"form-control"} value={email} onChange={e => setEmail(e.target.value)}/>
                </div>
                <div className={"col-md-6"}></div>

                <div className={"col-md-6 mb-3"}>
                    <label htmlFor={"first-name"} className={"form-label"}>First name</label>
                    <input id={"first-name"} type={"text"} className={"form-control"} value={firstName} onChange={e => setFirstName(e.target.value)}/>
                </div>
                <div className={"col-md-6"}></div>

                <div className={"col-md-6 mb-3"}>
                    <label htmlFor={"last-name"} className={"form-label"}>Last name</label>
                    <input id={"last-name"} type={"text"} className={"form-control"} value={lastName} onChange={e => setLastName(e.target.value)}/>
                </div>
                <div className={"col-md-6"}></div>

                <div className={"col-md-6 mb-3"}>
                    <label htmlFor={"band"} className={"form-label"}>Target band</label>
                    <input id={"band"} type={"text"} className={"form-control"} value={targetBand} onChange={e => setTargetBand(e.target.value)}/>
                </div>
                <div className={"col-md-6"}></div>

                <div className={"buttons"}>
                    <input type={"button"} className={"btn btn-secondary"} onClick={() => {history.push('/students')}} value={"Cancel"}/>
                    <input type={"submit"} className={"btn btn-primary"} value={"Save"}/>
                </div>
            </form>
        </div>
    );
};
