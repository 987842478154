import React, {useState, useEffect, useRef} from "react";
import {Link, useHistory} from "react-router-dom";
import {API} from "aws-amplify";
import {ReactComponent as ViewIcon} from "../assets/eye-small.svg";
import {ReactComponent as EditIcon} from "../assets/pencil-small.svg";
import LoadingBar from "react-top-loading-bar";

export const StudentsPage = () => {
    const loadingBarRef = useRef(null);
    const [students, setStudents] = useState([]);
    const history = useHistory();

    useEffect(() => {
        loadingBarRef.current.continuousStart();
        API.get("app", "/students", {}).then(response => {
            setStudents(response);
            loadingBarRef.current.complete();
        });
    }, []);

    return (
        <div className={"container-fluid"}>
            <LoadingBar color={"#0d6efd"} ref={loadingBarRef}/>
            <h1 className={"color"}>Students</h1>
            <table className={"table"}>
                <thead>
                <tr>
                    <th scope={"col"}>Name</th>
                    <th scope={"col"}>Email</th>
                    <th scope={"col"}>Target</th>
                    <th scope={"col"} width={"10%"}>Actions</th>
                </tr>
                </thead>
                <tbody>
                {students.map(student => {
                    return (
                        <tr key={student.email}>
                            <td>
                                <Link to={`/students/${student.email}`}>{`${student.first_name} ${student.last_name}`}</Link>
                            </td>
                            <td>{student.email}</td>
                            <td>{student.target_band}</td>
                            <td className={"action-buttons"}>
                                <span><Link to={`/students/${student.email}`}><ViewIcon/></Link></span>
                                <span><Link to={`/students/${student.email}/edit`}><EditIcon/></Link></span>
                            </td>
                        </tr>
                    );
                })}

                {students.length === 0 && (
                    <tr><td colSpan={4}>There is no students yet</td></tr>
                )}
                </tbody>
            </table>

            <div className={"buttons"}>
                <input type={"button"} className={"btn btn-primary"} value={"Add a new student"}
                    onClick={() => {history.push('/students/new')}}/>
            </div>
        </div>
    );
};
