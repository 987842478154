import React, {useState, useEffect} from 'react';
import {Auth} from "@aws-amplify/auth";
import {useHistory} from "react-router-dom";
import {useAppContext} from "../../libs/context";
import {toast} from "react-toastify";

export const Header = () => {
    const history = useHistory();
    const {userHasAuthenticated} = useAppContext();
    const [name, setName] = useState('');

    async function logout(event) {
        event.preventDefault();
        try {
            await Auth.signOut();
            userHasAuthenticated(false);
            history.push("/login");
        } catch (e) {
            toast(e.toString());
        }
    }

    useEffect(() => {
        Auth.currentUserInfo().then((result) => {
            let fullName = result.attributes.name;

            if (result.attributes.family_name) {
                fullName += ' ' + result.attributes.family_name;
            }
            setName(fullName);
        });
    }, []);

    const toggleSidebar = (e) => {
        e.preventDefault();

        let sidebar = document.getElementById('sidebar');
        let content = document.getElementById('content');
        sidebar.classList.toggle('active');
        setTimeout(function () {
            content.classList.toggle('content-no-sidebar');
        }, 200);
    };

    return (
        <nav className="navbar navbar-expand-lg fixed-top" aria-label="Main navigation" id="navbar">
            <div className={"nav-container"}>
                <div className="navbar-collapse offcanvas-collapse" id="">
                    <div className="me-auto p-2 mb-2 mb-lg-0">
                        <button className="btn" id="sidebar-toggle" onClick={toggleSidebar}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                                 className="bi bi-list" viewBox="0 0 16 16">
                                <path fillRule="evenodd"
                                      d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
                            </svg>
                        </button>
                        <span id={"logo"}><img alt={""} height={"30px"} src={"/aen.png"}/></span>
                    </div>
                    <div className="d-flex">
                        <ul className="navbar-nav me-auto mb-2 mb-md-0" style={{'float': 'right'}}>
                            <li className="nav-item" style={{'padding': '0 20px', 'marginTop': '5px', 'display': 'none'}}>
                                <a href="#">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                                         className="bi bi-bell-fill" viewBox="0 0 16 16">
                                        <path
                                            d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z"/>
                                    </svg>
                                </a>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown"
                                   aria-expanded="false">{name}</a>
                                <ul className="dropdown-menu dropdown-menu-end shadow-sm" aria-labelledby="dropdown01">
                                    <li style={{"display": "none"}}><a className="dropdown-item align-middle" href="#">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                                             className="bi bi-person" viewBox="0 0 16 16">
                                            <path
                                                d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/>
                                        </svg>
                                        <span>Profile</span></a></li>
                                    <li className="align-middle">
                                        <a className="dropdown-item" href="#" onClick={logout}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                 fill="currentColor" className="bi bi-arrow-right-square"
                                                 viewBox="0 0 16 16">
                                                <path fillRule="evenodd"
                                                      d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm4.5 5.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
                                            </svg>
                                            <span>Logout</span></a></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    );
};
