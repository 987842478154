import {API} from "aws-amplify";
import {useEffect, useState} from "react";

const useFetchStudents = () => {
    const [students, setStudents] = useState([])

    useEffect(() => {
        async function fetchStudents() {
            const response = await API.get('app', '/students', {})

            setStudents(response)
        }
        fetchStudents()
    }, [])

    return students
}

export {
    useFetchStudents
}
