import "./comments.scss";
import {useEffect} from "react";

export const ViewComments = (props) => {
    useEffect(() => {
        let contentContainer = document.getElementById('response-content');

        contentContainer.addEventListener('mouseover', mouseOverText);

        contentContainer.addEventListener('mouseout', mouseOutText);

        return () => {
            contentContainer.removeEventListener('mouseover', mouseOverText);
            contentContainer.removeEventListener('mouseout', mouseOutText);
        }
    }, []);

    const mouseOverFeedback = (e) => {
        let key = e.target.dataset.key;
        document.getElementById(`comment-${key}`).classList.add('hover');
    };

    const mouseOutFeedback = (e) => {
        let key = e.target.dataset.key;
        document.getElementById(`comment-${key}`).classList.remove('hover');
    };

    const mouseOverText = (e) => {
        if (e.target.classList.contains('has-comment')) {
            let key = e.target.dataset.key;
            document.getElementById(`feedback-view-mode-${key}`).classList.add('hover');
        }
    };

    const mouseOutText = (e) => {
        if (e.target.classList.contains('has-comment')) {
            let key = e.target.dataset.key;
            document.getElementById(`feedback-view-mode-${key}`).classList.remove('hover');
        }
    };

    return (
        <div className={"row mb-3"}>
            <div className={"col-sm-7 response"} style={{"whiteSpace": "pre-line"}}>
                <div className={"response-content"} id={"response-content"} dangerouslySetInnerHTML={{__html: props.response}} />
            </div>
            <div className={"col-sm-5 feedback-container"}>
                {Object.keys(props.comments).map((key) => {
                    const comment = props.comments[key];
                    const top = document.getElementById(`comment-${key}`).offsetTop;

                    return (
                        <div className={"feedback-view-mode"} id={`feedback-view-mode-${key}`} key={key} style={{'top': `${top}px`}} data-key={key} onMouseOver={mouseOverFeedback}
                             onMouseOut={mouseOutFeedback}>
                            {comment.content}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
