import React, {useState, useRef} from 'react';
import {Auth} from 'aws-amplify';
import {useAppContext} from "../../libs/context";
import {useHistory} from 'react-router-dom';
import {toast} from "react-toastify";
import LoadingBar from "react-top-loading-bar";

export const LoginPage = () => {
    const loadingBarRef = useRef(null);
    const {userHasAuthenticated} = useAppContext();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const history = useHistory();
    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            loadingBarRef.current.continuousStart();
            await Auth.signIn(email, password);
            userHasAuthenticated(true);
            window.location.href='/assignments';
            // history.push('/assignments'); // @todo Specify why logout and log in with another user cause issue with assignments page
        } catch (e) {
            if (e.message) {
                toast.error(e.message);
            } else {
                toast.error('Cannot login. An error occurred');
            }
            loadingBarRef.current.complete();
        }
    };

    return (
        <form onSubmit={handleSubmit} id="login-form">
            <LoadingBar color={"#0d6efd"} ref={loadingBarRef}/>
            <div className="ps-lg-4 border" style={{"padding": "20px"}}>
                <div className="mb-4 text-center">
                    <img src="/aen-round.png" height={60}/>
                </div>
                <div className="mb-4">
                    <label htmlFor="email" className="form-label">Email address</label>
                    <input type="email" className="form-control" id="email"
                           placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)}/>
                </div>
                <div className="mb-4">
                    <label htmlFor="password" className="form-label">Password</label>
                    <input type="password" className="form-control" id="password"
                           placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)}/>
                </div>
                <button type="submit" className="btn btn-primary">Log in</button>
            </div>
        </form>
    );
};
