import {useState, useEffect} from "react";
import Editor from "rich-markdown-editor";
import {Storage} from "aws-amplify";
import {toast} from "react-toastify";
import {useHistory, useParams} from "react-router-dom";
import config from "../../config";
import {uploadFileToS3} from "../../services/utils";

export const NewResourcePage = () => {
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [source, setSource] = useState('');
    const [sourceUrl, setSourceUrl] = useState('');
    const [savedContent, setSavedContent] = useState(null);
    const history = useHistory();
    const {id} = useParams();

    useEffect(() => {
        if (id) {
            Storage.get(`${config.s3.RESOURCE_PATH}/${id}.json`, {
                level: 'public',
                download: true,
                cacheControl: 'no-cache',
                contentType: 'application/json'
            }).then((response) => {
                response.Body.text().then(body => {
                    const json = JSON.parse(body);

                    setTitle(json.title);
                    setSource(json.source);
                    setSourceUrl(json.sourceUrl);
                    setContent(json.content);
                    setSavedContent(json.content);
                });
            });
        } else {
            setSavedContent('');
        }
    }, [id]);

    const save = async (e) => {
        e.preventDefault();

        let data = {title, source, sourceUrl, content};
        let fileId = id;

        if (!fileId) {
            fileId = Date.now();
        }

        await Storage.put(
            `${config.s3.RESOURCE_PATH}/${fileId}.json`,
            JSON.stringify(data),
            {
                level: 'public',
                contentType: 'application/json'
            }
        );

        toast('Resource saved successfully');

        history.push(`/resources/${fileId}`);
    };

    const uploadFile = async (file) => {
        const path = config.s3.RESOURCE_IMAGES_PATH

        return uploadFileToS3(file, path);
    };

    return (
        <div className={"container-fluid"}>
            <h1 className={"color"}>Add a reading resource</h1>

            <form className={"row"} onSubmit={save}>
                <div className={"col-md-12 mb-3"}>
                    <label htmlFor={"title"} className={"form-label"}>Title</label>
                    <input id={"title"} type={"text"} className={"form-control"} value={title} onChange={e => setTitle(e.target.value)}/>
                </div>

                <div className={"col-md-6 mb-3"}>
                    <label htmlFor={"title"} className={"form-label"}>Source</label>
                    <input id={"source"} type={"text"} className={"form-control"} value={source} onChange={e => setSource(e.target.value)}/>
                </div>

                <div className={"col-md-6 mb-3"}>
                    <label htmlFor={"title"} className={"form-label"}>Source Url</label>
                    <input id={"source-url"} type={"text"} className={"form-control"} value={sourceUrl} onChange={e => setSourceUrl(e.target.value)}/>
                </div>

                <div className={"mb-3"}>
                    <label>Content</label>
                    {savedContent !== null && (
                        <Editor className={"form-control"} placeholder="" id={"content"} defaultValue={savedContent} onChange={value => setContent(value())}
                                uploadImage={uploadFile}/>
                    )}
                </div>

                <div className={"buttons"}>
                    <input type={"submit"} className={"btn btn-primary"} value={"Save"}/>
                </div>
            </form>
        </div>
    );
};
