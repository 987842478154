import React, {useEffect, useState} from "react";
import {getTaskTitle} from "../../../repositories/task_repository";

export const FollowingTasks = props => {
    const taskIds = props.taskIds
    const [titleMap, setTitleMap] = useState({})

    useEffect(() => {
        if (taskIds) {
            taskIds.forEach(async taskId => {
                taskId = taskId.trim()
                const taskTitle = await getTaskTitle(taskId)
                let currentTitleMap = {...titleMap}
                currentTitleMap[taskId] = taskTitle

                setTitleMap(currentTitleMap)
            })
        }
    }, [taskIds])

    return taskIds && (
        <>
            {taskIds.map(taskId => {
                return (
                    <div key={`following-task-${taskId}`} className={"mb-2"}>
                        <a href={`/redirect-task/${taskId.trim()}`} target={"_blank"} rel={"noreferrer"}>{titleMap[taskId]}</a>
                    </div>
                )
            })}
        </>
    )
}