import "./instruction.scss";
import RichMarkdownEditor from "rich-markdown-editor";

export const Instruction = (props) => {
    let showSample = props.showSample;

    return (
        <>
            <div id={"instruction-toggler"}>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
                     className="bi bi-question-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                    <path
                        d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                </svg>
                <span data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions">Instructions</span>
            </div>

            <div className="offcanvas offcanvas-end" data-bs-scroll="true" tabIndex="-1" id="offcanvasWithBothOptions"
                 aria-labelledby="offcanvasWithBothOptionsLabel">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvasWithBothOptionsLabel">Instructions</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                            aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                    {!props.hideTabs && (
                        <ul className="nav nav-tabs mb-3" role={"tablist"}>
                            <li className="nav-item" role={"presentation"}>
                                <a href={"#"} className="nav-link active" aria-current="page" data-bs-toggle={"tab"} data-bs-target="#overview" type={"link"}>Overview</a>
                            </li>
                            <li className="nav-item" role={"presentation"}>
                                <a href={"#"} className="nav-link" aria-current="page" data-bs-toggle={"tab"} data-bs-target="#ideas" type={"link"}>Ideas</a>
                            </li>
                            <li className="nav-item" role={"presentation"}>
                                <a href={"#"} className="nav-link" aria-current="page" data-bs-toggle={"tab"} data-bs-target="#vocabulary" type={"link"}>Vocabulary</a>
                            </li>
                            {showSample && (
                                <li className="nav-item" role={"presentation"}>
                                    <a href={"#"} className="nav-link" aria-current="page" data-bs-toggle={"tab"} data-bs-target="#sample" type={"link"}>Sample</a>
                                </li>
                            )}
                        </ul>
                    )}

                    <div className={"tab-content"}>
                        <div id={"overview"} className={"tab-pane fade show active"} role={"tabpanel"}>
                            {!props.isHtml && (
                                <RichMarkdownEditor readOnly={true} defaultValue={props.instruction} value={props.instruction}/>
                            )}
                            {props.isHtml && (
                                <div dangerouslySetInnerHTML={{__html: props.instruction}}></div>
                            )}
                        </div>
                        <div id={"ideas"} className={"tab-pane fade"} role={"tabpanel"}>
                            <RichMarkdownEditor readOnly={true} value={props.ideas} defaultValue={props.ideas}/>
                        </div>
                        <div id={"vocabulary"} className={"tab-pane fade"} role={"tabpanel"}>
                            <RichMarkdownEditor readOnly={true} value={props.vocabulary} defaultValue={props.vocabulary}/>
                        </div>
                        <div id={"sample"} className={"tab-pane fade"} role={"tabpanel"}>
                            <RichMarkdownEditor readOnly={true} value={props.sample} defaultValue={props.sample}/>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
};
