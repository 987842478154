import {Assessment} from "./assessment";
import {TaskResponse} from "./task_response";

class Assignment {
    constructor(
        public id: string,
        public status: string,
        public title: string,
        public content: string,
        public metaData: string,
        public taskAssessment: Assessment | null,
        public firstResponse: TaskResponse | null,
        public responses: TaskResponse[],
        public result?: {},
        public prerequisiteAssignment?: {title: string} | null) {
    }
}

export {
    Assignment
}
