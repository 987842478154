import {isCorrectAnswer, splitQuestion} from "../../services/question_service"
import React from "react"
import "./question.scss"
import {OverlayTrigger, Popover} from "react-bootstrap";
import {UnlockFill} from "react-bootstrap-icons";

export const Question = props => {
    const test = props.test
    let question = String(props.question.value).replaceAll(/[{}]/g, '');
    let parsedQuestion = splitQuestion(question);
    const results = props.results;
    const editable = props.editable;
    const showResults = props.showResults;
    let explanations = test.explanations || {}
    const showKeyAndExplanation = props.showKeyAndExplanation

    const getExplanationForQuestion = (questionNumber) => {
        return explanations[questionNumber] ? explanations[questionNumber] : null
    }

    const getDefaultAnswer = questionNumber => {
        if (props.defaultAnswers && props.defaultAnswers[questionNumber]) {
            return props.defaultAnswers[questionNumber];
        }

        return results[questionNumber] ? results[questionNumber] : null;
    };

    const checkCorrectAnswer = (questionNumber, answer) => {
        answer = answer ? answer.trim() : answer
        return isCorrectAnswer(questionNumber, answer, test, results)
    }

    const onAnswerChange = (questionNumber, value) => {
        if (props.onAnswerChange) {
            props.onAnswerChange(questionNumber, value);
        }
    };

    const getExplanationPopoverForQuestion = (questionNumber) => {
        const popover = <Popover id={`explanation-popover-${questionNumber}`}>
            <Popover.Header as={"h3"}>Question {questionNumber}</Popover.Header>
            <Popover.Body>
                <div className={"explanation-popover"}>
                    <div><strong>Correct answer:</strong> {test.answers[questionNumber]}</div>
                    <div><strong>Explanation:</strong> {getExplanationForQuestion(questionNumber)}</div>
                </div>
            </Popover.Body>
        </Popover>

        return showKeyAndExplanation && (<OverlayTrigger trigger={"click"} placement={"top"} overlay={popover}>
            <span style={{cursor: "pointer"}} title={"Show key and explanation"}><UnlockFill></UnlockFill></span>
        </OverlayTrigger>)
    }

    const clozeTextQuestion = parsedQuestion => {
        const defaultAnswer = getDefaultAnswer(parsedQuestion.questionNumber);

        return (
            <span className={`question-${parsedQuestion.questionNumber} cloze-text ${!showResults ? '' : (results && checkCorrectAnswer(parsedQuestion.questionNumber, results[parsedQuestion.questionNumber]) ? 'text-success' : 'text-danger')}`}>
                <strong> {parsedQuestion.questionNumber}. </strong>
                <input defaultValue={defaultAnswer ? defaultAnswer : ''} name={parsedQuestion.questionNumber} id={`answer-${parsedQuestion.questionNumber}`} type="text" onChange={e => onAnswerChange(parsedQuestion.questionNumber, e.target.value)}
                    disabled={!editable}/>
                {getExplanationPopoverForQuestion(parsedQuestion.questionNumber)}
            </span>
        );
    };

    const singleChoiceListQuestion = parsedQuestion => {
        const questionNumber = parsedQuestion.questionNumber;
        const choices = parsedQuestion.choices;
        const question = choices[0];
        let newChoices = choices.slice(1);
        newChoices.unshift('');

        return (
            <span className={`${!showResults ? '' : (results && checkCorrectAnswer(parsedQuestion.questionNumber, results[parsedQuestion.questionNumber]) ? 'text-success' : 'text-danger')}`}>
                <strong>{questionNumber}. </strong>
                {question}
                <select name={questionNumber} onChange={e => onAnswerChange(questionNumber, e.target.value)} defaultValue={getDefaultAnswer(questionNumber)} disabled={!editable}>
                    {newChoices.map(choice => {
                        return <option key={`${questionNumber}-${choice}`} value={choice}>{choice}</option>
                    })}
                </select>
                {getExplanationPopoverForQuestion(parsedQuestion.questionNumber)}
            </span>
        );
    };

    const singleChoiceQuestion = parsedQuestion => {
        const questionNumber = parsedQuestion.questionNumber;
        const choices = parsedQuestion.choices;
        let options = [];

        choices.forEach((item, i) => {
            const optionId = `question-option-${questionNumber}-${i}`;

            if (i !== 0) {
                options.push(
                    <div key={i}>
                        ${String.fromCharCode(64 + i)} <input type="radio" name={questionNumber} value={i} id={optionId} />
                        <label htmlFor={optionId}>${item}</label>
                    </div>
                );
            }
        });

        return (
            <div onChange={onAnswerChange} className={`${!showResults ? '' : (results && checkCorrectAnswer(parsedQuestion.questionNumber, results[parsedQuestion.questionNumber]) ? 'text-success' : 'text-danger')}`}>
                {options}
                {getExplanationPopoverForQuestion(parsedQuestion.questionNumber)}
            </div>
        );
    };

    if (parsedQuestion.questionType === 'CT') {
        return clozeTextQuestion(parsedQuestion);
    }

    if (parsedQuestion.questionType === 'SCL' || parsedQuestion.questionType === 'USCL') {
        return singleChoiceListQuestion(parsedQuestion);
    }

    return singleChoiceQuestion(parsedQuestion);
};
