import React, {useState, useEffect} from "react"
import {API} from "aws-amplify"
import {useParams, useHistory} from "react-router-dom"
import RichMarkdownEditor from "rich-markdown-editor";
import {Instruction} from "../../components/instruction/instruction.component";
import {AssignmentStudentSelector} from "../../components/assign_student_selector";

export const ViewTaskPage = () => {
    const [title, setTitle] = useState('');
    const [type, setType] = useState('');
    const [category, setCategory] = useState('');
    const [content, setContent] = useState('');
    const [book, setBook] = useState('');
    const [year, setYear] = useState('');
    const [metaData, setMetaData] = useState('');
    const [instruction, setInstruction] = useState('');
    const [vocabulary, setVocabulary] = useState('');
    const [ideas, setIdeas] = useState('');
    const [sample, setSample] = useState('');
    const [students, setStudents] = useState([]);
    const [assignedStudents, setAssignedStudents] = useState([]);
    const {taskId} = useParams();
    const history = useHistory();
    const [task, setTask] = useState(null)
    const [followingTaskIds, setFollowingTaskIds] = useState('')
    const [parentTaskId, setParentTaskId] = useState('')

    useEffect(() => {
        if (taskId) {
            API.get("app", "/tasks/" + taskId, {}).then((result => {
                let metaData = JSON.parse(result.meta_data);
                setTitle(result.title);
                setType(result.task_type);
                setCategory(result.readable_category);
                setContent(result.content);
                setBook(result.book);
                setYear(result.year);
                setMetaData(result.meta_data);

                if (metaData.instruction) {
                    setInstruction(metaData.instruction);
                }
                if (metaData.vocabulary) {
                    setVocabulary(metaData.vocabulary);
                }
                if (metaData.ideas) {
                    setIdeas(metaData.ideas);
                }
                if (metaData.sample) {
                    setSample(metaData.sample);
                }
                setFollowingTaskIds(result.following_task_ids ? result.following_task_ids : '')
                setParentTaskId(result.parent_task_id ? result.parent_task_id : '')

                setTask(result)

                API.get("app", "/students", {}).then(studentResults => {
                    setStudents(studentResults.filter(item => result.students.indexOf(item.email) === -1));
                });
            }));
        }
    }, [taskId]);

    const edit = () => {
        history.push(`/tasks/${taskId}/edit`);
    };

    const removeStudentFromStudentList = (email) => {
        const index = students.findIndex(student => student.email === email)

        if (index !== -1) {
            let newStudents = [...students]
            newStudents.splice(index, 1)
            setStudents(newStudents)
        }
    };

    return (
        <div className={'container-fluid'}>
            <h1 className={"color"}>{title}</h1>
            <div className={"row mb-3"}>
                <label className={"col-sm-2"}><strong>Task type</strong></label>
                <div className={"col-sm-4"}>{type}</div>
                <label className={"col-sm-2"}><strong>Category</strong></label>
                <div className={"col-sm-4"}>{category}</div>
            </div>
            <div className={"row mb-4"}>
                <label className={"col-sm-2"}><strong>Book</strong></label>
                <div className={"col-sm-4"}>{book}</div>
                <label className={"col-sm-2"}><strong>Year</strong></label>
                <div className={"col-sm-4"}>{year}</div>
            </div>
            <div className={"row mb-3"}>
                <RichMarkdownEditor readOnly={true} value={content}/>
            </div>

            <Instruction instruction={instruction} ideas={ideas} vocabulary={vocabulary} sample={sample}/>

            <div className={"buttons"}>
                <input type={"button"} className={"btn btn-secondary"} onClick={() => {history.goBack()}} value={"Back"}/>
                <input onClick={edit} type={"submit"} className={"btn btn-primary"} value={"Edit"}/>
                {followingTaskIds && (
                    <button className={"btn btn-warning"} data-bs-toggle="modal" data-bs-target="#viewFollowingTasks">View following tasks</button>
                )}
                {parentTaskId && (
                    <a className={"btn btn-warning"} href={`/redirect-task/${parentTaskId}`} target={"_blank"}>View parent task</a>
                )}
                <div className={"col-sm-8 float-end"}>
                    {assignedStudents !== null && (
                        <AssignmentStudentSelector students={students} task={task} callback={removeStudentFromStudentList}/>
                    )}
                </div>
            </div>

            <div className="modal fade" id="viewFollowingTasks" tabIndex="-1" aria-labelledby="viewFollowingTasks" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Following tasks</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {followingTaskIds.split(',').map((taskId, index) => {
                                return (
                                    <div key={`following-task-${taskId}`} className={"mb-2"}>
                                        <a href={`/redirect-task/${taskId.trim()}`} target={"_blank"}>Task {index + 1}</a>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
