import React, {useState, useRef} from 'react';
import {TaskTypeSelector} from "../../components/task_type_selector.component";
import {API} from "aws-amplify";
import {ReactComponent as ViewIcon} from "../../assets/eye-small.svg";
import {ReactComponent as EditIcon} from "../../assets/pencil-small.svg";
import "./tasks.scss";
import {Link} from "react-router-dom";
import LoadingBar from "react-top-loading-bar";

export const TasksPage = () => {
    const loadingBarRef = useRef(null);
    const [type, setType] = useState(null);
    const [category, setCategory] = useState(null);
    const [tasks, setTasks] = useState([]);

    const submit = (e) => {
        e.preventDefault();
        loadingBarRef.current.continuousStart();

        let params = {type: type};
        if (category) {
            params.category = category;
        }
        API.get("app", "/tasks", {
            queryStringParameters: params
        }).then(response => {
            loadingBarRef.current.complete();
            setTasks(response);
        });
    };

    const createViewLink = task => {
        if (task.task_type === 'READING_TEST') {
            return `tasks/reading-test/${task.task_id}`;
        }

        if (task.task_type === 'LISTENING_TEST') {
            return `tasks/listening-test/${task.task_id}`;
        }

        if (task.task_type === 'SPEAKING_TEST') {
            return `tasks/speaking-test/${task.task_id}`;
        }

        if (task.task_type === 'GUIDED_WRITING') {
            return `tasks/guided-writing/${task.task_id}`;
        }

        if (task.task_type === 'SKILL_BOOST') {
            return `tasks/skill-boost/${task.task_id}`;
        }

        if (task.task_type === 'LISTENING_PRACTICE') {
            return `tasks/listening-practice/${task.task_id}`;
        }

        if (task.task_type === 'READING_PRACTICE') {
            return `tasks/reading-practice/${task.task_id}`
        }

        return `tasks/${task.task_id}`;
    };

    const createEditLink = task => {
        if (task.task_type === 'READING_TEST') {
            return `tasks/reading-test/${task.task_id}/edit`;
        }

        if (task.task_type === 'LISTENING_TEST') {
            return `tasks/listening-test/${task.task_id}/edit`;
        }

        if (task.task_type === 'SPEAKING_TEST') {
            return `tasks/speaking-test/${task.task_id}/edit`;
        }

        if (task.task_type === 'GUIDED_WRITING') {
            return `tasks/guided-writing/${task.task_id}/edit`;
        }

        if (task.task_type === 'SKILL_BOOST') {
            return `tasks/skill-boost/${task.task_id}/edit`;
        }

        if (task.task_type === 'LISTENING_PRACTICE') {
            return `tasks/listening-practice/${task.task_id}/edit`;
        }

        if (task.task_type === 'READING_PRACTICE') {
            return `tasks/reading-practice/${task.task_id}/edit`
        }

        return `tasks/${task.task_id}/edit`;
    };

    return (
        <div className={"container-fluid"}>
            <LoadingBar color={"#0d6efd"} ref={loadingBarRef}/>
            <h1 className={"color"}>Tasks</h1>

            <form className={"row mb-3"} onSubmit={submit}>
                <TaskTypeSelector onTypeChanged={newType => setType(newType)}
                                  onCategoryChanged={newCategory => setCategory(newCategory)}/>

                <div className={"buttons"}>
                    <input type={"submit"} className={"btn btn-primary"} value={"Search"}/>
                </div>
            </form>

            {tasks.length > 0 && (
                <table className={"table"}>
                    <thead>
                    <tr>
                        <th scope={"col"}>Title</th>
                        <th scope={"col"} width={"10%"}>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {tasks.map(task => {
                        return (
                            <tr key={task.task_id}>
                                <td>
                                    <Link to={createViewLink(task)}>{task.title}</Link>
                                </td>
                                <td className={"action-buttons"}>
                                    <span><Link to={createViewLink(task)}><ViewIcon/></Link></span>
                                    <span><Link to={createEditLink(task)}><EditIcon/></Link></span>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            )}
        </div>
    );
};
